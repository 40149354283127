import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

export default function GuestGuard({ children }) {
    const { authenticated } = useAuthContext();
    const history = useHistory();

    const check = useCallback(() => {
        if (authenticated) {
            console.log(authenticated, 'authenticated')
            history.push('/dashboard');
        }
    }, [authenticated, history]);

    useEffect(() => {
        check();
    }, [check]);

    return <>{children}</>;
}
