import moment from 'moment';

const dateFormater = (cell) => {
    return moment(cell).format('MM/DD/yyyy hh:mm:ss')
}

export const columns = [
    {
        accessor: "id",
        title: "Id",
    },
    {
        accessor: "request",
        title: "Email",
        sort: true,
        cellFormatter: (cell) => JSON.parse(cell).email,
    },
    {
        accessor: "method",
        title: "Method",
        sort: true,
    },
    {
        accessor: "ipAddress",
        title: "Ip Address",
        sort: true,
    },
    {
        accessor: "userAgent",
        title: "User Agent",
        sort: true,
    },
    {
        accessor: "dataChanged",
        title: "Data Changed",
        sort: true,
    },
    {
        accessor: "createdAt",
        title: "Created Date",
        sort: true,
        cellFormatter: dateFormater,
    }
];