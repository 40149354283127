import React from 'react';
import Select from 'react-select';

const HkTags = ({ options, value, defaultValue, callBack }) => {
    return (
        <Select
            isMulti
            isClearable={false}
            options={options}
            value={value}
            defaultValue={defaultValue}
            onChange={callBack}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? '#007D88' : '#007D88',
                }),
                multiValue: (styles) => {
                    return {
                        ...styles,
                        backgroundColor: "whitesmoke",
                    };
                },
                multiValueRemove: (styles) => ({
                    ...styles,
                    ':hover': {
                        backgroundColor: "whitesmoke",
                        color: 'black',
                    },
                }),
            }}
        />
    )
}

export default HkTags
