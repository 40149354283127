//Images
import avatar1 from '../../assets/dist/img/logo-avatar-1.png'
import avatar2 from '../../assets/dist/img/logo-avatar-2.png'
import avatar3 from '../../assets/dist/img/logo-avatar-3.png'
import avatar4 from '../../assets/dist/img/logo-avatar-4.png'
import avatar5 from '../../assets/dist/img/logo-avatar-5.png'
import avatar6 from '../../assets/dist/img/logo-avatar-6.png'

import moment from 'moment';


const dateFormater = (cell) => {
    return moment(cell).format('MM/DD/yyyy hh:mm:ss')
}

export const columns = [
    {
        accessor: "title",
        title: "Title",
        sort: true,
    },
    {
        accessor: "message",
        title: "Message",
        sort: true,
    },
    {
        accessor: "label",
        title: "Label",
        sort: true,
    },
    {
        accessor: "level",
        title: "Level",
        sort: true,
    },
    {
        accessor: "createdAt",
        title: "Created Date",
        sort: true,
        cellFormatter: dateFormater,
    },
    {
        accessor: "updatedAt",
        title: "Last Edited",
        sort: true,
        cellFormatter: dateFormater,
    },
];

export const data = [
    {
        name: [{ Img: avatar1, productName: "Phone Pay", productLink: "phonepay.in", }],
        usage: [{ value: 90, variant: "blue-dark-3" }],
        lastUpdate: "10 June, 2022",
        tags: ["admin", "Finance"],
        actions: [{ editLink: "#", deletLink: "#" }]
    },

    {
        name: [{ Img: avatar2, productName: "Swiggy", productLink: "swiggy.com", }],
        usage: [{ value: 75, variant: "blue" }],
        lastUpdate: "09 July, 2022",
        tags: ["customer data", "admin", "+4"],
        actions: [{ editLink: "#", deletLink: "#" }]
    },

    {
        id: 3,
        name: [{ Img: avatar3, productName: "Coursera", productLink: "coursera.com", }],
        usage: [{ value: 50, variant: "primary" }],
        lastUpdate: "24 Aug, 2022",
        tags: ["education", "admin", "+3"],
        actions: [{ editLink: "#", deletLink: "#" }]
    },

    {
        name: [{ Img: avatar4, productName: "Tinder", productLink: "tinder.com", }],
        usage: [{ value: 60, variant: "primary" }],
        lastUpdate: "17 May, 2022",
        tags: ["Social",],
        actions: [{ editLink: "#", deletLink: "#" }]
    },

    {
        name: [{ Img: avatar5, productName: "PCD", productLink: "pcdeals.com", }],
        usage: [{ value: 30, variant: "grey" }],
        lastUpdate: "13 July, 2022",
        tags: ["Portal", "admin", "+3"],
        actions: [{ editLink: "#", deletLink: "#" }]
    },
    {
        name: [{ Img: avatar6, productName: "Icons 8", productLink: "icons8.com", }],
        usage: [{ value: 45, variant: "green-dark-1" }],
        lastUpdate: "14 July, 2022",
        tags: ["Library", "Asset",],
        actions: [{ editLink: "#", deletLink: "#" }]
    },
];