import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { Nav, Container } from 'react-bootstrap';
import { getNotifications } from '../../../utils/apis';
import HkBadge from '../../../components/@hk-badge/@hk-badge';
import { dateFormat } from '../../../utils/utils';
import { useAuthContext } from '../../../auth/hooks';

const Body = () => {
  const { user } = useAuthContext();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getNotificationsAction = async (query) => {
    try {
      setIsLoading(true);
      const notifications = await getNotifications(query);
      setData(notifications);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  }

  const loadData = () => {
    getNotificationsAction(`where={"type": {"contains":"notification"}}&sort=createdAt%20DESC`);
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <div className="contact-body">
        <Nav variant="tabs" className="nav-light nav-line page-tab-header">
          <Nav.Item>
            <Nav.Link eventKey="overview" className='active' >
              <span className="nav-link-text">All Notifications
              </span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className='page-tab-body mt-1'>
          <SimpleBar className="nicescroll-bar">

            <Container>
              {
                (() => {
                  if (isLoading) {
                    return (<p className='text-center my-3'>Loading...</p>)
                  } else {
                    if (data.length === 0) {
                      return (<p className='text-center my-3'>No Data</p>)
                    } else {
                      return (
                        <ul className="advance-list">
                          {
                            data.map((one) => (
                              <li
                                className={classNames("advance-list-item single-task-list active-todo min-height-0", { "selected": one.checked })}
                                key={one.id}
                              >
                                <div>
                                  <div className="d-flex flex-md-row flex-column align-items-md-center align-items-start justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <HkBadge
                                        indicator
                                        bg={one.level}
                                        className="badge-indicator-xl me-2"
                                      />
                                      <span className="todo-text text-dark text-truncate" >
                                        <b>{one.title}</b>
                                      </span>
                                    </div>
                                    <div className="d-flex flex-shrink-0 align-items-center align-self-end">
                                      <span className="todo-time d-lg-inline-block text-start me-3">
                                        {dateFormat(one.createdAt)}
                                      </span>

                                      <HkBadge bg={one.level} className="ms-3 d-md-inline-block " >{one.label}</HkBadge>
                                    </div>
                                  </div>
                                  <div className='mt-2'>
                                    <span className="todo-text text-dark text-truncate white-space-wrap" >
                                      {one.message}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            ))
                          }
                        </ul>
                      )
                    }
                  }
                })()
              }
            </Container>
          </SimpleBar>
        </div>
      </div>
    </>
  )
}

export default Body
