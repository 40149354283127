import { useState, useEffect } from "react";

const calculateRange = (totoalCount, rowsPerPage) => {
    const range = [];
    const num = Math.ceil(totoalCount / rowsPerPage);
    for (let i = 1; i <= num; i++) {
        range.push(i);
    }
    return range;
};

const useTablePageSize = (totoalCount, page, rowsPerPage) => {
    const [tableRange, setTableRange] = useState([]);

    useEffect(() => {
        if (rowsPerPage) {
            const range = calculateRange(totoalCount, rowsPerPage);
            setTableRange([...range]);
        }

    }, [totoalCount, setTableRange, rowsPerPage]);

    return { range: tableRange };
};

export default useTablePageSize;