import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getReports } from '../../../utils/apis';
import { groupBy } from '../../../utils/utils';
import { toast } from 'react-toastify';
import moment from 'moment';


const AudienceReviewChart = ({ selectStatistic, acitveIndex, setAcitveIndex, setAllData, parentRef }) => {

    const [loading, setLoading] = useState(false);
    const [monthData, setMonthData] = useState({});
    const [series, setSeries] = useState([]);
    const [dataByProvider, setDataByProvider] = useState([]);
    const [categories, setCategories] = useState([]);

    const optionsTemplate = {

        chart: {
            type: 'bar',
            height: 250,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            foreColor: "#646A71",
            fontFamily: 'DM Sans'
        },

        grid: {
            borderColor: '#F4F5F6',
        },

        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '35%',
                borderRadius: 5,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "last"
            },
        },
        xaxis: {
            type: 'string',
            // type: 'datetime',
            categories: [],

            labels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                },
            },
            axisBorder: {
                show: false,
            },
            title: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                },
            },
            title: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                }
            },
        },
        legend: {
            show: true,
            position: 'top',
            fontSize: '15px',
            labels: {
                colors: '#6f6f6f',
            },
            markers: {
                width: 8,
                height: 8,
                radius: 15

            },
            itemMargin: {
                vertical: 5
            },
        },

        colors: ['#015259', '#007D88', '#00aebd', '#015741', '#03795b', '#25cba1', '#015734', '#058350', '#14d185'],
        fill: {
            opacity: 1
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function (value) {
                    return `€${value}`
                },
                title: {
                    formatter: (seriesName) => seriesName,
                },
            },
        }
    };

    useEffect(() => {
        let _series = [];
        let _categories = [];
        let _preDates = [];
        let _dataByProvider = [];
        if (Object.keys(monthData).length) {
            for (const providerId in monthData) {

                const providerData = monthData[providerId].sort((a, b) => new Date(`${a.year}-${a.month}-${a.day}`) - new Date(`${b.year}-${b.month}-${b.day}`));
                const latestObjects = {};

                providerData.forEach(obj => {
                    const dateKey = `${obj.month}/${obj.year}`;

                    if (!latestObjects[dateKey] ||
                        (
                            new Date(`${obj.month}/${obj.day}/${obj.year}`) >
                            new Date(`${latestObjects[dateKey].month}/${latestObjects[dateKey].day}/${latestObjects[dateKey].year}`))
                    ) {
                        latestObjects[dateKey] = obj;
                    }
                });
                const uniqueDatesArray = Object.values(latestObjects)

                _series.push({
                    name: uniqueDatesArray[0].providerName,
                    data: uniqueDatesArray.map((e) => ({ ggr: e.totalGGR.toFixed(2), date: `${e.year}-${e.month}` }))
                });
                _categories = uniqueDatesArray.map((e) => `${e.year}-${e.month}`);
                _preDates = [..._preDates, ..._categories];
            }
            _categories = [...new Set(_preDates)];
            _categories = _categories.sort((a, b) => new Date(`${a}-01`) - new Date(`${b}-01`));

            for (const idx in _series) {
                const _data = _categories.map((categoryDate) => {
                    const index = _series[idx].data.findIndex((e) => e.date === categoryDate);
                    return index > -1 ? _series[idx].data[index].ggr : 0;
                })
                _series[idx].data = _data;
            }

            for (const providerId in monthData) {

                const providerData = monthData[providerId].sort((a, b) => new Date(`${a.year}-${a.month}-${a.day}`) - new Date(`${b.year}-${b.month}-${b.day}`));
                const latestObjects = {};

                providerData.forEach(obj => {
                    const dateKey = `${obj.month}/${obj.year}`;

                    if (!latestObjects[dateKey] ||
                        (
                            new Date(`${obj.month}/${obj.day}/${obj.year}`) >
                            new Date(`${latestObjects[dateKey].month}/${latestObjects[dateKey].day}/${latestObjects[dateKey].year}`))
                    ) {
                        latestObjects[dateKey] = obj;
                    }
                });
                const uniqueDatesArray = Object.values(latestObjects)

                const _providerData = _categories.map((categoryDate) => {
                    const index = uniqueDatesArray.findIndex((e) => `${e.year}-${e.month}` === categoryDate);
                    return index > -1 ? uniqueDatesArray[index] :
                        {
                            "providerName": "Pragmatic Slots",
                            "totalRounds": 0,
                            "totalTurnover": 0,
                            "uniquePlayers": 0,
                            "totalWin": 0,
                            "totalGGR": 0,
                        }
                })

                _dataByProvider.push(_providerData);
            }

            // _categories = _categories.map((e) => moment(new Date(`${e}-01`)).format('MM/DD/yy'))
            _categories = _categories.map((e) => moment(e).format(`${e.split('-')[1] === '1' ? 'MMM yy' : 'MMM'}`))
            setSeries(_series);
            setCategories(_categories);
            setDataByProvider(_dataByProvider);
        }
    }, [monthData]);

    const options = useMemo(() => {
        if (categories.length) {
            optionsTemplate.xaxis.categories = categories;
            optionsTemplate.chart.events = {
                dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex }) => {
                    selectStatistic(dataByProvider[seriesIndex][dataPointIndex]);
                    setAcitveIndex(`${seriesIndex}-${dataPointIndex}`)
                },
                mouseLeave: function (event, chartContext, { seriesIndex, dataPointIndex }) {
                    setAcitveIndex('');
                }
            };
        }
        return optionsTemplate;
        // eslint-disable-next-line
    }, [categories, dataByProvider]);

    const loadData = async () => {
        try {
            setLoading(true);
            let report = await getReports(`where={"date":{"contains":"${moment().add(-1, 'days').format('yyyy-MM-DD')}"}, "type": "monthly"}`);
            report = report.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            if (report.length) {
                const totalReport = {
                    providerName: 'Total',
                    totalGGR: 0,
                    totalRounds: 0,
                    totalTurnover: 0,
                    totalWin: 0,
                    uniquePlayers: 0
                }
                report[0].statistics.forEach(element => {
                    totalReport.totalGGR += element.totalGGR;
                    totalReport.totalRounds += element.totalRounds;
                    totalReport.totalTurnover += element.totalTurnover;
                    totalReport.totalWin += element.totalWin;
                    totalReport.uniquePlayers += element.uniquePlayers;
                });

                selectStatistic(totalReport);
                setAllData(totalReport);
                const statistic = groupBy(report[0].statistics, 'providerName')
                setMonthData(statistic)
            }
            setLoading(false);
        } catch (error) {
            toast.error(error.message)
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
        parentRef.current = loadData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading) {
        return <div style={{ height: 270 }} className='w-100 d-flex align-items-center justify-content-center'>Loading...</div>
    } else {
        if (!Object.keys(monthData).length) {
            return <div style={{ height: 270 }} className='w-100 d-flex align-items-center justify-content-center'>No Data</div>
        }
        return <ReactApexChart options={options} series={series} type="bar" height={270} />
    }
}

export default AudienceReviewChart
