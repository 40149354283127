import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Col, Container, Nav, Row, Tab, Button } from 'react-bootstrap';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { RefreshCw } from 'react-feather';
import ReportCard from './ReportCard';
import { toast } from 'react-toastify';
import { getFinanceDashboard } from '../../utils/apis';
import moment from 'moment';

import 'bootstrap-daterangepicker/daterangepicker.css';
import FinanceChart from './FinanceChart';

const Dashboard = ({ toggleCollapsedNav }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [index, setIndex] = useState(0);

    const refreshPage = () => {
        getReportsAction();
    }

    const getReportsAction = async () => {
        try {
            setIsLoading(true);
            const reports = await getFinanceDashboard(`sort=createdAt%20DESC&limit=5`);
            setData(reports);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    }

    useEffect(() => {
        toggleCollapsedNav(false);
        getReportsAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title> Finance Dashboard | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <Container>
                <Tab.Container activeKey="overview">
                    {/* Page Header */}
                    <div className="hk-pg-header pg-header-wth-tab pt-7">
                        <div className="d-flex">
                            <div className="d-flex flex-wrap justify-content-between flex-1">
                                <div className="mb-lg-0 mb-2 me-8">
                                    <h1 className="pg-title">Finance Dashboard</h1>
                                </div>
                            </div>
                        </div>
                        <Nav variant="tabs" className="nav-light nav-line">
                            <Nav.Item>
                                <Nav.Link eventKey="overview">
                                    <span className="nav-link-text">Overview</span>
                                </Nav.Link>
                            </Nav.Item>
                            <div className='d-flex align-items-center ms-auto mb-1'>
                                <select onChange={(e) => setIndex(e.target.value)} className='d-flex align-items-center w-130p form-select form-select-sm me-2'>
                                    {
                                        data.map((one, i) => (
                                            <option key={one.id} value={i}>{moment(one.createdAt).format('yyy-MM-D')}</option>
                                        ))
                                    }
                                </select>
                                <Button size='sm' onClick={refreshPage}>
                                    <RefreshCw height={16} className='me-1' />
                                    Refresh
                                </Button>
                            </div>
                        </Nav>
                    </div>
                    {/* /Page Header */}
                    {/* Page Body */}
                    <div className="hk-pg-body pb-10">
                        <Tab.Content>
                            <Tab.Pane eventKey="overview" >
                                <Row>
                                    <Col xxl={6} lg={6} md={6} className="mb-md-4 mb-3">
                                        <ReportCard title="Total 24 Hours Revenue" isLoading={isLoading} value={data[index]?.total24HoursRevenue} unit='$' />
                                    </Col>
                                    <Col xxl={6} lg={6} md={6} className="mb-md-4 mb-3">
                                        <ReportCard title="Total Bets" isLoading={isLoading} value={data[index]?.totalBets} unit='$' />
                                    </Col>
                                    <Col xxl={4} lg={4} md={4} className="mb-md-4 mb-3">
                                        <ReportCard title="Total Revenue" isLoading={isLoading} value={data[index]?.totalRevenue} unit='$' />
                                    </Col>
                                    <Col xxl={4} lg={4} md={4} className="mb-md-4 mb-3">
                                        <ReportCard title="Total Rounds" isLoading={isLoading} value={data[index]?.totalRounds} unit='' />
                                    </Col>
                                    <Col xxl={4} lg={4} md={4} className="mb-md-4 mb-3">
                                        <ReportCard title="Total Service Revenue" isLoading={isLoading} value={data[index]?.totalServiceRevenue} unit='$' />
                                    </Col>
                                    <Col xxl={6} lg={6} md={6} className="mb-md-4 mb-3">
                                        <ReportCard title="Total Users" isLoading={isLoading} value={data[index]?.totalUsers} unit='' />
                                    </Col>
                                    <Col xxl={6} lg={6} md={6} className="mb-md-4 mb-3">
                                        <ReportCard title="Total Wins" isLoading={isLoading} value={data[index]?.totalWins} unit='$' />
                                    </Col>
                                    <Col xxl={12} lg={12} md={12} className="mb-md-4 mb-3">
                                        <FinanceChart isLoading={isLoading} data={data[index]?.providerReports} />
                                    </Col>
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                    {/* /Page Body */}
                </Tab.Container>
            </Container>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(Dashboard);