import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import NotificationsBody from "./list/NotificationsBody";
import NotificationsManagement from "./list/NotificationsManagement";
import NotificationsHeader from "./list/NotificationsHeader";
import { getPartPermission } from '../../utils/utils';
import { useAuthContext } from '../../auth/hooks';

const Notifications = (params) => {
    const { user } = useAuthContext();

    const [partPermission, setPartPermission] = useState({});

    useEffect(() => {
        const permission = getPartPermission(user.permissions, 'notification');
        setPartPermission(permission)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <>
            <Helmet>
                <title> Notifications | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <NotificationsHeader />
                        {
                            (partPermission.POST || partPermission.PUT || partPermission.DELETE) ?
                                <NotificationsManagement partPermission={partPermission} /> :
                                <NotificationsBody />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notifications;