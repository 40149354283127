import React, { useEffect, useState, useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

const GroupsModal = ({ data, setData, show, onClose }) => {
    const [listData, setListData] = useState(data);
    const [search, setSearch] = useState('');

    const chooseGroup = (id, check) => {
        setListData((pre) => pre.map((e => {
            if (e.id === id) return { ...e, check };
            return e;
        })))
    }

    useEffect(() => {
        setListData(data);
    }, [data]);

    const filteredData = useMemo(() => {
        if (search) {
            return listData.filter((item) =>
                item.groupName.toString().toLowerCase().includes(search.toLowerCase())
            );
        }
        return listData;
    }, [listData, search]);

    const update = () => {
        setData({ memberOf: listData.filter((e) => e.check).map((e) => e.id) });
        onClose();
    }

    return (
        <Modal show={show} onHide={onClose} centered dialogClassName="mw-400p" >
            <Modal.Header className="header-wth-bg-inv">
                <Modal.Title as="h5">Choose Groups</Modal.Title>
                <Button bsPrefix="btn-close" className="text-white" onClick={onClose} >
                    <span aria-hidden="true">×</span>
                </Button>
            </Modal.Header>
            <Modal.Body className="p-0">
                <Form className="m-3" role="search">
                    <Form.Control type="text" className="rounded-input user-search" placeholder="Search Groups" value={search} onChange={(e) => setSearch(e.target.value)} />
                </Form>
                <div className="h-350p">
                    <SimpleBar style={{ height: "100%" }} className="nicescroll-bar">
                        <ul className="invite-user-list">
                            {
                                filteredData.map((group) => (
                                    <li key={group.id}>
                                        <div className="media d-flex align-items-center">
                                            <div className="media-body">
                                                <div className="user-name">{group.groupName}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <Form.Check type="checkbox" id="customCheck2" checked={!!group.check} onChange={(e) => chooseGroup(group.id, e.target.checked)} />
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </SimpleBar>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button variant='light' className="flex-fill flex-1" onClick={onClose} >Close</Button>
                <Button variant="primary" className="flex-fill flex-1" onClick={update}>Done</Button>
            </Modal.Footer>
        </Modal>

    )
}

export default GroupsModal;
