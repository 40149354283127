import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Form, Nav, Row, Container, Card, Spinner } from 'react-bootstrap';

import SimpleBar from 'simplebar-react';
import HkTags from '../../../components/@hk-tags/@hk-tags';
import { alpha2, CURRENCIES, LANGUAGES } from '../../../configs/constants/static';
import { createGame, getGameCategories, getProviders, getGame, updateGame } from '../../../utils/apis';

const countries = alpha2.map((e) => ({ value: e, label: e }));
const languages = LANGUAGES.map((e) => ({ value: e, label: e }));
const currencies = CURRENCIES.map((e) => ({ value: e, label: e }));
const platforms = [
    { value: 'Desktop', label: 'Desktop' },
    { value: 'Mobile', label: 'Mobile' }]

const makeSelectData = (data, setData) => {
    if (typeof data === 'string') {
        setData(JSON.parse(data).map((e) => ({ value: e, label: e })))
    } else {
        setData(data.map((e) => ({ value: e, label: e })))
    }
}

const EditUserBody = ({ id }) => {

    const history = useHistory();

    const [game, setGame] = useState({
        gid: '',
        name: '',
        displayName: '',
        provider: '',
        type: '',
        image: '',
        typeRating: 0,
        popularity: 0,
        rtp: 0,
        minBet: 0,
        maxBet: 0,
        maxExposure: 0,
        restrictedCountries: [],
        languages: [],
        platforms: [],
        bonusBuy: 0,
        jeckpot: 0,
        demoPlay: false,
        demoLink: '',
        contentDomain: '',
        currencies: [],
        status: false,
        categories: '',
    });
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedCurrencies, setSelectedCurrencies] = useState([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [providers, setProviders] = useState([]);
    const [categories, setCategories] = useState([]);

    const updateGameData = (data) => {
        setGame((pre) => ({ ...pre, ...data }));
    }

    const handleCountry = (e) => {
        setSelectedCountries([...e])
    }

    const handleLanguage = (e) => {
        setSelectedLanguages([...e])
    }

    const handleCurrency = (e) => {
        setSelectedCurrencies([...e])
    }

    const handlePlatform = (e) => {
        setSelectedPlatforms([...e])
    }

    const initialGameData = () => {
        setGame({
            gid: '',
            name: '',
            displayName: '',
            provider: '',
            type: '',
            image: '',
            typeRating: 0,
            popularity: 0,
            rtp: 0,
            minBet: 0,
            maxBet: 0,
            maxExposure: 0,
            restrictedCountries: [],
            languages: [],
            platforms: [],
            bonusBuy: 0,
            jeckpot: 0,
            demoPlay: false,
            demoLink: '',
            contentDomain: '',
            currencies: [],
            status: false,
            categories: '',
        });
        setValidated(false);
    };

    const getGameActioin = async (id) => {
        try {
            const gameData = await getGame(id);
            setGame({ ...gameData, provider: gameData.provider.id, categories: gameData.categories.id });
            makeSelectData(gameData.restrictedCountries, setSelectedCountries);
            makeSelectData(gameData.languages, setSelectedLanguages);
            makeSelectData(gameData.currencies, setSelectedCurrencies);
            makeSelectData(gameData.platforms, setSelectedPlatforms);
        } catch (error) {
            toast.error(error.message);
        }
    }

    const onDataSubmit = async (e) => {
        try {
            e.preventDefault();

            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            }

            setValidated(true);
            game.currencies = selectedCurrencies.map((e) => e.value);
            game.restrictedCountries = selectedCountries.map((e) => e.value);
            game.languages = selectedLanguages.map((e) => e.value);
            game.platforms = selectedPlatforms.map((e) => e.value);

            if (game.gid === '' ||
                game.name === '' ||
                game.displayName === '' ||
                game.provider === '' ||
                game.type === '' ||
                game.image === '' ||
                game.categories === ''
            ) {
                return;
            }


            setLoading(true);
            if (id) {
                delete game.password;
                await updateGame(game, id);
            } else {
                await createGame(game);
                initialGameData();
            }
            toast.success('Success');
            setLoading(false);
            history.push('/game/list');
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    const getProvidersAction = async () => {
        try {
            const providerData = await getProviders();
            setProviders(providerData);
            updateGameData({ provider: providerData[0].id })
        } catch (error) {
            console.log(error.message)
        }
    }

    const getCategoryAction = async () => {
        try {
            const categoryData = await getGameCategories();
            setCategories(categoryData);
            updateGameData({ categories: categoryData[0].id })
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        getProvidersAction();
        getCategoryAction();
        if (id) {
            getGameActioin(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            <div className="contact-body">
                <Nav variant="tabs" className="nav-light nav-line page-tab-header">
                    <Nav.Item>
                        <Nav.Link eventKey="-" className='active'>
                            <span className="nav-link-text">{`${id ? 'Edit' : 'Create'} A Game`}</span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <div className='page-tab-body mt-1'>
                    <SimpleBar className="nicescroll-bar">
                        <div className="contact-list-view">
                            <Container>
                                <Card>
                                    <Card.Body>
                                        <Form noValidate validated={validated} onSubmit={onDataSubmit}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridGid">
                                                    <Form.Label>GID</Form.Label>
                                                    <Form.Control type="text" placeholder="GID" required value={game.gid} onChange={(e) => updateGameData({ gid: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        GID is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridName">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Name" required value={game.name} onChange={(e) => updateGameData({ name: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Name is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridDisplayname">
                                                    <Form.Label>Display Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Display Name" required value={game.displayName} onChange={(e) => updateGameData({ displayName: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Display Name is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridProvider">
                                                    <Form.Label>Provider</Form.Label>
                                                    <Form.Select value={game.provider} onChange={(e) => updateGameData({ provider: e.target.value })}>
                                                        {
                                                            providers.map((provider) => (
                                                                <option key={provider.id} value={provider.id}>{provider.name}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Provider is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridType">
                                                    <Form.Label>Type</Form.Label>
                                                    <Form.Control type="text" placeholder="Type" required value={game.type} onChange={(e) => updateGameData({ type: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Type is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridImage">
                                                    <Form.Label>Image</Form.Label>
                                                    <Form.Control type="text" placeholder="Image" required value={game.image} onChange={(e) => updateGameData({ image: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Image is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridypeRating">
                                                    <Form.Label>Type Rating</Form.Label>
                                                    <Form.Control type="number" placeholder="Type Rating" required value={game.typeRating} onChange={(e) => updateGameData({ typeRating: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Type Rating is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridPopularity">
                                                    <Form.Label>Popularity</Form.Label>
                                                    <Form.Control type="number" placeholder="Popularity" required value={game.popularity} onChange={(e) => updateGameData({ popularity: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Popularity is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridypeRtp">
                                                    <Form.Label>RTP</Form.Label>
                                                    <Form.Control type="number" placeholder="RTP" required value={game.rtp} onChange={(e) => updateGameData({ rtp: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        RTP is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridJeckpot">
                                                    <Form.Label>Jeckpot</Form.Label>
                                                    <Form.Control type="text" placeholder="Jeckpot" required value={game.jeckpot} onChange={(e) => updateGameData({ jeckpot: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Jeckpot is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridMaxExposure">
                                                    <Form.Label>Max Exposure</Form.Label>
                                                    <Form.Control type="text" placeholder="Max Exposure" required value={game.maxExposure} onChange={(e) => updateGameData({ maxExposure: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Max Exposure is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridypeminBet">
                                                    <Form.Label>Min Bet</Form.Label>
                                                    <Form.Control type="text" placeholder="Min Bet" required value={game.minBet} onChange={(e) => updateGameData({ minBet: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Min Bet is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridmaxBet">
                                                    <Form.Label>Max Bet</Form.Label>
                                                    <Form.Control type="text" placeholder="Max Bet" required value={game.maxBet} onChange={(e) => updateGameData({ maxBet: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Max Bet is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridbonusBuy">
                                                    <Form.Label>BonusBuy</Form.Label>
                                                    <Form.Control type="text" placeholder="BonusBuy" required value={game.bonusBuy} onChange={(e) => updateGameData({ bonusBuy: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        BonusBuy is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridDemoLink">
                                                    <Form.Label>Demo Link</Form.Label>
                                                    <Form.Control type="text" placeholder="Demo Link" value={game.demoLink} onChange={(e) => updateGameData({ demoLink: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Demo Link is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridContentDomain">
                                                    <Form.Label>Content Domain</Form.Label>
                                                    <Form.Control type="text" placeholder="Content Domain" value={game.contentDomain} onChange={(e) => updateGameData({ contentDomain: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Content Domain is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">

                                                <Form.Group as={Col} controlId="formGridCategories">
                                                    <Form.Label>Categories</Form.Label>
                                                    <Form.Select value={game.categories} onChange={(e) => updateGameData({ categories: e.target.value })}>
                                                        {
                                                            categories.map((category) => (
                                                                <option key={category.id} value={category.id}>{category.name}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Categories are required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridPlatforms">
                                                    <Form.Label>Platforms</Form.Label>
                                                    <HkTags
                                                        options={platforms}
                                                        value={selectedPlatforms}
                                                        callBack={handlePlatform}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Platforms is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridStatus">
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="switch-status"
                                                        label={game.status ? 'Active' : 'Disabled'}
                                                        className="form-switch-lg"
                                                        checked={game.status}
                                                        onChange={(e) => updateGameData({ status: e.target.checked })}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridDemoPlay">
                                                    <Form.Label>Demo Play</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="switch-lg"
                                                        className="form-switch-lg mb-1"
                                                        checked={game.demoPlay}
                                                        onChange={(e) => updateGameData({ demoPlay: e.target.checked })}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Demo Play is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridCurrencies">
                                                    <Form.Label>Currencies</Form.Label>
                                                    <HkTags
                                                        options={currencies}
                                                        value={selectedCurrencies}
                                                        callBack={handleCurrency}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Currencies is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridRestrictedCountries">
                                                    <Form.Label>Restricted Countries</Form.Label>
                                                    <HkTags
                                                        options={countries}
                                                        value={selectedCountries}
                                                        callBack={handleCountry}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Restricted Countries is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridlanguages">
                                                    <Form.Label>Languages</Form.Label>
                                                    <HkTags
                                                        options={languages}
                                                        value={selectedLanguages}
                                                        callBack={handleLanguage}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Languages is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            {
                                                loading ?
                                                    <Button variant="primary" className='btn btn-primary' disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        Loading...
                                                    </Button>
                                                    : <Button type='submit' submit="true">{id ? 'Update' : 'Create'}</Button>
                                            }
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Container>
                        </div>
                    </SimpleBar>
                </div>
            </div >
        </>
    )
}

export default EditUserBody;