import { Card } from 'react-bootstrap';

const ReportCard = ({ isLoading, title, value, unit }) => {
    return (
        <Card className="card-border mb-0 h-100">
            <Card.Header className="card-header-action">
                <h6>{`${title} Overview`}</h6>
            </Card.Header>
            <Card.Body>
                <div style={{ height: 100 }} className='w-100 d-flex align-items-center justify-content-center flex-column'>
                    {
                        isLoading ?
                            <h3>--</h3> :
                            <h3>{`${unit} ${unit ? value.toFixed(2) : value}`}</h3>
                    }
                    <h6>{title}</h6>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ReportCard;