import moment from 'moment';

const dateFormater = (cell) => {
    return moment(cell).format('MM/DD/yyyy hh:mm:ss')
}

export const columns = [
    {
        accessor: "id",
        title: "Id",
    },
    {
        accessor: "permissionModel",
        title: "Permission Model",
        sort: true,
    },
    {
        accessor: "permissionName",
        title: "Permission Name",
        sort: true,
    },
    {
        accessor: "permissionType",
        title: "Permission Type",
        sort: true,
    },
    {
        accessor: "createdAt",
        title: "Created Date",
        sort: true,
        cellFormatter: dateFormater,
    }
];