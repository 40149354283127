import React from 'react';
import { Helmet } from 'react-helmet-async';

import GamesHeader from './list/GamesHeader';
import GamesBody from './list/GamesBody';

const Game = () => {
    return (
        <>
            <Helmet>
                <title> Game List | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <GamesHeader />
                        <GamesBody/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Game;
