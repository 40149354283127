import React from 'react';
import { Helmet } from 'react-helmet-async';

import ProvidersHeader from './list/ProvidersHeader';
import ProvidersBody from './list/ProvidersBody';

const Providers = () => {
    return (
        <>
            <Helmet>
                <title> Provider List | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <ProvidersHeader />
                        <ProvidersBody/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Providers;
