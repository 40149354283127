export const columns = [
    {
        accessor: "id",
        title: "Id"
    },
    {
        accessor: "userName",
        title: "Username",
    },
    {
        accessor: "provider",
        title: "Provider Id",
    },
    {
        accessor: "providerName",
        title: "Provider Name",
    },
    {
        accessor: "totalBets",
        title: "Total Bets",
        cellFormatter: (cell) => `€ ${cell.toLocaleString()}`,
    },
    {
        accessor: "totalWins",
        title: "Total Wins",
        cellFormatter: (cell) => `€ ${cell.toLocaleString()}`,
    },
    {
        accessor: "GGR",
        title: "GGR ",
        cellFormatter: (cell) => `€ ${cell.toLocaleString()}`,
    },
    {
        accessor: "markupPercent",
        title: "Markup Percent",
        cellFormatter: (cell) => `${cell.toLocaleString()}%`,
    },
    {
        accessor: "serviceFee",
        title: "Service Fee",
        cellFormatter: (cell) => `€ ${cell.toLocaleString()}`,
    }
];