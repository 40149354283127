// import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-feather';
import moment from 'moment';

//Custom Action Container
const actionFormater = (cell, permission) => {
    return (
        <div className="d-flex align-items-center" >
            <div className="d-flex">
                {/* {
                    permission.PUT &&
                    <Button variant="flush-dark" as={Link} to={`/session/${cell.id}/edit`} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                        <span className="icon">
                            <span className="feather-icon">
                                <Edit />
                            </span>
                        </span>
                    </Button>
                } */}
                {
                    permission.DELETE &&
                    <Button onClick={() => cell.handleDelete({ id: cell.id, name: cell.name })} variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete">
                        <span className="icon">
                            <span className="feather-icon">
                                <Trash />
                            </span>
                        </span>
                    </Button>
                }
            </div>
        </div>
    )
}

const dateFormater = (cell) => {
    return moment(cell).format('MM/DD/yyyy hh:mm:ss')
}

export const columns = (permission) => {
    const data = [
        {
            accessor: "id",
            title: "Id",
        },
        {
            accessor: "sessionId",
            title: "Session Id",
            sort: true,
        },
        {
            accessor: "gameId",
            title: "Game Id",
            sort: true,
        },
        {
            accessor: "language",
            title: "Language",
        },
        {
            accessor: "mode",
            title: "Mode",
        },
        {
            accessor: "playerId",
            title: "Player Id",
        },
        {
            accessor: "homeUrl",
            title: "Home Url",
        },
        {
            accessor: "historyUrl",
            title: "History Url",
        },
        {
            accessor: "exitUrl",
            title: "Exit Url",
        },
        {
            accessor: "country",
            title: "Country",
        },
        {
            accessor: "currency",
            title: "Currency",
        },
        {
            accessor: "isTestAccount",
            title: "isTestAccount",
        },
        {
            accessor: "platform",
            title: "Platform",
        },
        {
            accessor: "license",
            title: "License",
        },
        {
            accessor: "user",
            title: "User",
            cellFormatter: (cell) => cell ? cell.name : "",
        },
        {
            accessor: "createdAt",
            title: "Created Date",
            sort: true,
            cellFormatter: dateFormater,
        },
        {
            accessor: "updatedAt",
            title: "Last Edited",
            sort: true,
            cellFormatter: dateFormater,
        }
    ];

    if (permission.PUT || permission.DELETE) {
        data.push(
            {
                accessor: "actions",
                title: "Actions",
                cellFormatter: (cell) => actionFormater(cell, permission),
            },
        )
        // if (permission.PUT) {
        //     data[0].cellFormatter = (cell) => <Link to={`/session/${cell}/edit`}>{cell}</Link>
        // }
    }
    return data;
}