import React, { useState, useEffect } from 'react';

import SimpleBar from 'simplebar-react';
import { Calendar } from 'react-feather';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Button, Col, Form, Nav, Row, InputGroup } from 'react-bootstrap';
import { RefreshCw } from 'react-feather';

import { columns } from '../TableData';
import HkTooltip from '../../../components/@hk-tooltip/HkTooltip';
import HkBadge from '../../../components/@hk-badge/@hk-badge';
import DataTable from '../../../components/DataTable';

import { downloadCSV } from '../../../utils/utils';
import { getLogs, getLogsCount } from '../../../utils/apis';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFFile from '../../../components/PDFFile';

const rowsPerPage = 50;

const PermissionsBody = () => {
    const [downloadType, setDownloadType] = useState('1');
    const [searchTerm, setSearchTerm] = useState('');
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({});
    const [downloadData, setDownloadData] = useState([]);
    const [range, setRange] = useState({
        start: moment().startOf('hour').toDate(),
        end: moment().startOf('hour').add(32, 'hour').toDate()
    });
    const [isLoading, setIsLoading] = useState({
        getAction: true,
        deleteAction: false
    });

    const updateLoading = (data) => {
        setIsLoading((pre) => ({ ...pre, ...data }));
    }

    const requestSort = (sort, key) => {
        let direction = 'ASC';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ASC'
        ) {
            direction = 'DESC';
        };
        if (sort) {
            setSortConfig({ key, direction });
        }
    };

    const getLogsAction = async (query) => {
        try {
            updateLoading({ getAction: true });
            const logs = await getLogs(query);
            setData(logs);
            updateLoading({ getAction: false });
        } catch (error) {
            updateLoading({ getAction: false });
            toast.error(error.message);
        }
    }

    const getPermissinsCountAction = async (query) => {
        try {
            const logoslength = await getLogsCount(query);
            setCount(logoslength.count);
        } catch (error) {
            console.log(error);
        }
    }

    const loadData = (newPage) => {
        const where = {
            and: [
                {
                    and: [
                        {
                            createdAt: { '>': new Date(range.start).valueOf() },
                        },
                        {
                            createdAt: { '<': new Date(range.end).valueOf() }
                        }
                    ]
                },
                {
                    or: [
                        { id: { contains: searchTerm } },
                        { request: { contains: searchTerm } },
                        { method: { contains: searchTerm } },
                        { ipAddress: { contains: searchTerm } },
                        { userAgent: { contains: searchTerm } },
                        { dataChanged: { contains: searchTerm } },
                    ],
                }
            ]
        }

        let condition = `where=${JSON.stringify(where)}`;
        const skip = newPage ? newPage - 1 : page - 1;

        if (newPage > 1) {
            setPage(newPage);
        } else {
            getPermissinsCountAction(condition);
        }

        if (sortConfig.key) {
            condition += `&sort=${sortConfig.key}%20${sortConfig.direction}`;
        }
        getLogsAction(condition + `&skip=${rowsPerPage * skip}&limit=${rowsPerPage}`);
    }

    const loadExportData = async () => {
        const where = {
            and: [
                {
                    and: [
                        {
                            createdAt: { '>': new Date(range.start).valueOf() },
                        },
                        {
                            createdAt: { '<': new Date(range.end).valueOf() }
                        }
                    ]
                },
                {
                    or: [
                        { id: { contains: searchTerm } },
                        { request: { contains: searchTerm } },
                        { method: { contains: searchTerm } },
                        { ipAddress: { contains: searchTerm } },
                        { userAgent: { contains: searchTerm } },
                        { dataChanged: { contains: searchTerm } },
                    ],
                }
            ]
        }

        let condition = `where=${JSON.stringify(where)}`;
        if (sortConfig.key) {
            condition += `&sort=${sortConfig.key}%20${sortConfig.direction}`;
        }
        const exportData = await getLogs(condition);
        const data = exportData.map((e) => ({
            Id: e.id,
            'Email': JSON.parse(e.request).email,
            'Method': e.method,
            'Ip Address': e.ipAddress,
            'User Agent': e.userAgent,
            'Data Changed': e.dataChanged ?? "",
            'Created Date': moment(e.createdAt).format('MM/DD/yyyy hh:mm:ss'),
        }));

        setDownloadData(data);
        return data;
    }

    const switchDownload = async (value) => {
        setDownloadType(value);
        if (value === '2') {
            await loadExportData()
        }
    }

    const csvExport = async () => {
        const data = await loadExportData();
        downloadCSV(data, 'logs');
    }

    const changeDate = (start, end) => {
        setRange({ start: start.toDate(), end: end.toDate() });
    }

    useEffect(() => {
        loadData(1);
        // eslint-disable-next-line
    }, [sortConfig]);

    useEffect(() => {
        loadData(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="contact-body">
            <Nav variant="tabs" className="nav-light nav-line page-tab-header">
                <Nav.Item>
                    <Nav.Link eventKey="overview" className='active' >
                        <span className="nav-link-text">All Logs
                            {
                                !!count &&
                                <HkBadge bg="light" size="sm" text="dark" className="ms-1">{count}</HkBadge>
                            }
                        </span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <div className='page-tab-body mt-1'>
                <SimpleBar className="nicescroll-bar">
                    <div className="contact-list-view">
                        <Row className="mb-3" >
                            <Col xs={12} sm={4} mb={3}>
                                <div className="contact-toolbar-left">
                                    <Form.Group className="d-flex align-items-center mb-0">
                                        <Form.Select size="sm" className="d-flex align-items-center w-130p" onChange={(e) => switchDownload(e.target.value)}>
                                            <option value={1}>Export to CSV</option>
                                            <option value={2}>Export to PDF</option>
                                        </Form.Select>
                                        {
                                            downloadType === '1' &&
                                            <Button size="sm" variant="light" className="ms-2" onClick={csvExport}>Export</Button>
                                        }
                                        {
                                            !!downloadData.length && downloadType === '2' &&
                                            <PDFDownloadLink
                                                document={<PDFFile data={downloadData} />}
                                                fileName={'logs'}
                                                className='ms-2 btn btn-light btn-sm'
                                            >
                                                Export
                                            </PDFDownloadLink>
                                        }
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col xs={12} sm={8} mb={3}>
                                <div className="contact-toolbar-right">
                                    <div className="dataTables_filter d-flex align-items-center">
                                        <InputGroup className="w-270p me-2" >
                                            <span className="input-affix-wrapper">
                                                <span className="input-prefix">
                                                    <span className="feather-icon">
                                                        <Calendar />
                                                    </span>
                                                </span>
                                                <DateRangePicker
                                                    initialSettings={{
                                                        timePicker: true,
                                                        startDate: range.start,
                                                        endDate: range.end,
                                                        locale: {
                                                            format: 'M/DD hh:mm A',
                                                        },
                                                    }}
                                                    onCallback={changeDate}
                                                >
                                                    <Form.Control type="text" name="datetimes" size="sm" />
                                                </DateRangePicker>
                                            </span>
                                        </InputGroup>
                                        <Form.Group className='d-flex align-items-center'>
                                            <Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="search"
                                                    placeholder="Search"
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </Form.Label>
                                            <Button as="a" variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover no-caret d-sm-inline-block ms-1" href="#" onClick={() => loadData(1)}>
                                                <HkTooltip title="Refresh" placement="top" >
                                                    <span className="icon">
                                                        <span className="feather-icon">
                                                            <RefreshCw />
                                                        </span>
                                                    </span>
                                                </HkTooltip>
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <DataTable
                            column={columns}
                            rowData={data}
                            page={page}
                            isLoading={isLoading.getAction}
                            sortConfig={sortConfig}
                            setPage={loadData}
                            sortCb={requestSort}
                            rowsPerPage={rowsPerPage}
                            totoalCount={count}
                            classes="nowrap w-100 mb-5"
                            responsive
                        />
                    </div>
                </SimpleBar >
            </div>
        </div>
    )
}

export default PermissionsBody;
