import React from 'react';
import { Helmet } from 'react-helmet-async';

import SessionsHeader from './list/SessionsHeader';
import SessionsBody from './list/SessionsBody';

const Sessions = () => {
    return (
        <>
            <Helmet>
                <title> Session List | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <SessionsHeader />
                        <SessionsBody/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sessions;
