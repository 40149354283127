import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Nav, Row, Container, Card, Spinner } from 'react-bootstrap';

import { createCurrency, getCurrency, updateCurrency } from '../../../utils/apis';

const EditCurrencyBody = ({ id }) => {

    const history = useHistory();

    const [currency, setCurrency] = useState({
        name: '',
        symbol: '',
        decimalPlaces: 0,
        exchangeRate: 0,
        isDefault: false
    });
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const updateCurrencyData = (data) => {
        setCurrency((pre) => ({ ...pre, ...data }));
    }

    const initialCurrencyData = () => {
        setCurrency({
            name: '',
            symbol: '',
            decimalPlaces: 0,
            exchangeRate: 0,
            isDefault: false
        });
        setValidated(false);
    };

    const getCurrencyActioin = async (id) => {
        try {
            const currencyData = await getCurrency(id);
            setCurrency(currencyData);
        } catch (error) {
            toast.error(error.message);
        }
    }

    const onDataSubmit = async (e) => {
        try {
            e.preventDefault();

            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            }

            setValidated(true);

            if (currency.name === '' ||
                currency.symbol === ''
            ) {
                return;
            }

            setLoading(true);
            if (id) {
                await updateCurrency(currency, id);
            } else {
                await createCurrency(currency);
                initialCurrencyData();
            }
            toast.success('Success');
            setLoading(false);
            history.push('/currency/list');
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (id) {
            getCurrencyActioin(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div className="contact-body">
            <Nav variant="tabs" className="nav-light nav-line page-tab-header">
                <Nav.Item>
                    <Nav.Link eventKey="-" className='active' >
                        <span className="nav-link-text">{`${id ? 'Edit' : 'Create'} A Currency`}</span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <div className='page-tab-body mt-1'>
                <SimpleBar className="nicescroll-bar">
                    <div className="contact-list-view">
                        <Container>
                            <Card>
                                <Card.Body>
                                    <Form noValidate validated={validated} onSubmit={onDataSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridName">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" placeholder="Name" required value={currency.name} onChange={(e) => updateCurrencyData({ name: e.target.value })} />
                                                <Form.Control.Feedback type="invalid">
                                                    Name is required
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridSymbol">
                                                <Form.Label>Symbol</Form.Label>
                                                <Form.Control type="text" placeholder="Symbol" required value={currency.symbol} onChange={(e) => updateCurrencyData({ symbol: e.target.value })} />
                                                <Form.Control.Feedback type="invalid">
                                                    Symbol is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridDecimalPlaces">
                                                <Form.Label>Decimal Places</Form.Label>
                                                <Form.Control type="number" placeholder="Decimal Places" required value={currency.decimalPlaces} onChange={(e) => updateCurrencyData({ decimalPlaces: e.target.value })} />
                                                <Form.Control.Feedback type="invalid">
                                                    Decimal Places is required
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridExchangeRate">
                                                <Form.Label>ExchangeRate</Form.Label>
                                                <Form.Control type="number" placeholder="ExchangeRate" value={currency.exchangeRate} required onChange={(e) => updateCurrencyData({ exchangeRate: e.target.value })} />
                                                <Form.Control.Feedback type="invalid">
                                                    Exchange Rate is required
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} id="formGridCheckbox">
                                                <Form.Check type="checkbox" label="Is Default ?" checked={currency.isDefault} onChange={(e) => updateCurrencyData({ isDefault: e.target.checked })} />
                                            </Form.Group>
                                        </Row>
                                        {
                                            loading ?
                                                <Button variant="primary" className='btn btn-primary' disabled>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    Loading...
                                                </Button>
                                                : <Button type='submit' submit="true">{id ? 'Update' : 'Create'}</Button>
                                        }
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Container>
                    </div>
                </SimpleBar >
            </div>
        </div>
    )
}

export default EditCurrencyBody;