import moment from 'moment';
import { Button, Form, Modal, Table } from 'react-bootstrap';

const ErrorModal = ({ show, close, data }) => {
    return (
        <Modal centered show={show} onHide={close}>
            <div className='position-relative'>
                <Modal.Header>
                    <Modal.Title>Bad Transactioin</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ minHeight: 100 }}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Field</th>
                                <th>value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Timestamp</td>
                                <td>{moment(data.createdAt).format('yyyy-MM-DD hh:mm:ss')}</td>
                            </tr>
                            <tr>
                                <td>Transaction Id</td>
                                <td>{data.transactionId}</td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td>{data.type}</td>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <td>{data.amount}</td>
                            </tr>
                            <tr>
                                <td>HTTP status</td>
                                <td>{data.httpStatus}</td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td>{data.description}</td>
                            </tr>
                            <tr>
                                <td>Error</td>
                                <td>{data.error}</td>
                            </tr>
                            <tr>
                                <td>Response</td>
                                <td>
                                    <Form.Control as="textarea" rows={3} readOnly defaultValue={data.response} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={close}>
                        Close
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    )
}

export default ErrorModal;