import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import RoundsHeader from '../list/RoundsHeader';
import RoundsOtherBody from './RoundsOtherBody';

const Rounds = () => {
    const location = useLocation();
    const lastItem = location.pathname.split('/').pop();

    return (
        <>
            <Helmet>
                <title>{` ${lastItem.charAt(0).toUpperCase() + lastItem.slice(1)} Round | Slotserv - iGaming Content Provider `}</title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <RoundsHeader title={`${lastItem} Round`} />
                        <RoundsOtherBody filter={lastItem} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Rounds;
