import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";

export const getAvaiableRouter = (routerList, permissions) => {
    const list = [];
    for (const group of routerList) {
        const partPermissions = permissions.filter((permission) => permission.permissionModel === group.contents.id);

        if (partPermissions.length) {
            list.push(`${group.contents.path}/list`);

            if (partPermissions.findIndex((permission) => permission.permissionType === 'POST') !== -1) {
                list.push(`${group.contents.path}/new`);
            }
            if (partPermissions.findIndex((permission) => permission.permissionType === 'PUT') !== -1) {
                list.push(`${group.contents.path}/edit`);
            }
        }
    }
}

export const getPartPermission = (permissions, part) => {
    const permission = permissions.filter((e) => e.permissionModel === part);
    const data = {
        GET: false,
        POST: false,
        PUT: false,
        DELETE: false
    }
    for (const key in data) {
        const idx = permission.findIndex((e) => e.permissionType === key);
        if (idx !== -1) {
            data[key] = true;
        }
    }
    return data;
}

export const dateFormat = (date) => {
    const date1 = moment(date);
    const date2 = moment();

    const diffInDays = date2.diff(date1, 'days');
    switch (diffInDays) {
        case 0:
            return `Today, ${moment(date).format('hh:mm A')}`;
        case 1:
            return `Yesterday, ${moment(date).format('hh:mm A')}`;
        case 2:
            return `3 Days ago, ${moment(date).format('hh:mm A')}`;
        case 3:
            return `4 Days ago, ${moment(date).format('hh:mm A')}`;
        default:
            return `${moment(date).format('DD MMM, yyyy')}`
    }
}


export const groupBy = (arr, key) => {
    return arr.reduce((grouped, obj) => {
        const property = obj[key];
        if (!grouped[property]) {
            grouped[property] = [];
        }
        grouped[property].push(obj);
        return grouped;
    }, {});
};

export const roundGroupBy = (arr, key, key1 = '') => {
    return arr.reduce((grouped, obj) => {
        const property = obj[key][key1];
        if (!grouped[property]) {
            grouped[property] = [];
        }
        grouped[property].push(obj[key]);
        return grouped;
    }, {});
};

export const statisticGroupBy = (arr, key, key1 = '') => {
    return arr.reduce((grouped, obj) => {
        const property = obj[key][key1];
        if (!grouped[property]) {
            grouped[property] = [];
        }
        grouped[property].push(obj);
        return grouped;
    }, {});
};

// ** Converts table to CSV
function convertArrayOfObjectsToCSV(array) {
    let result

    const columnDelimiter = ','
    const lineDelimiter = '\n'
    const keys = Object.keys(array[0])

    result = ''
    result += keys.join(columnDelimiter)
    result += lineDelimiter

    array.forEach(item => {
        let ctr = 0
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter

            result += item[key]

            ctr++
        })
        result += lineDelimiter
    })

    return result
}

// ** Downloads CSV
export const downloadCSV = (array, name) => {
    const link = document.createElement('a')
    let csv = convertArrayOfObjectsToCSV(array)
    if (csv === null) return

    const filename = `${name}.csv`

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`
    }

    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', filename)
    link.click()
}

export const getDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const durationInMillis = end.getTime() - start.getTime();
    const seconds = Math.floor(durationInMillis / 1000);
    return seconds;
}

// export async function downloadFile(fileUrl, name) {
//     try {
//         const response = await fetch(fileUrl);
//         const blob = await response.blob();
//         const url = window.URL.createObjectURL(new Blob([blob]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', name);
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     } catch (error) {
//         console.log(error)
//     }
// }

export const downloadFile = async (fileUrl, fileName) => {
    axios.get(fileUrl, {
        responseType: 'blob' // important
    })
        .then(response => {
            saveAs(response.data, fileName);
        })
        .catch(error => {
            console.error('Error downloading file: ', error);
        });
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}
