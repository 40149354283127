import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
//Images
import notificationsImg from '../../assets/dist/img/notifications.svg';
import { AlignLeft, Bell, CreditCard, Inbox, Search, Tag } from 'react-feather';
import { Button, Container, Dropdown, Form, InputGroup, Nav, Navbar } from 'react-bootstrap';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import CustomInput from './CustomInput';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import HkBadge from '../../components/@hk-badge/@hk-badge';
import { useAuthContext } from '../../auth/hooks';
import { getGames, getNotifications } from '../../utils/apis';
import { dateFormat } from '../../utils/utils';


const TopNav = ({ navCollapsed, toggleCollapsedNav }) => {
    const { user, logout } = useAuthContext();

    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState("")
    const [notifications, setNotifications] = useState([]);
    const [games, setGames] = useState([]);

    const CloseSearchInput = () => {
        setSearchValue("");
        setShowDropdown(false);
    }

    const pageVariants = {
        initial: {
            opacity: 0,
            y: 10
        },
        open: {
            opacity: 1,
            y: 0
        },
        close: {
            opacity: 0,
            y: 10
        }
    };

    const getNotificationsAction = async () => {
        try {
            const data = await getNotifications(`where={"type": {"contains":"notification"}}&sort=createdAt%20DESC&limit=7`);
            setNotifications(data);
        } catch (error) {
            toast.error(error.message);
        }
    }

    const searchGames = async () => {
        try {
            if (!searchValue) {
                setGames([]);
                return;
            }
            const data = await getGames(`where={"name":{"contains":"${searchValue}"}}&limit=20`);
            setGames(data);
        } catch (error) {
            toast.error(error.message);
        }
    }

    useEffect(() => {
        searchGames();
        // eslint-disable-next-line
    }, [searchValue])

    useEffect(() => {
        getNotificationsAction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Navbar expand="xl" className="hk-navbar navbar-light fixed-top" >
            <Container fluid>
                {/* Start Nav */}
                <div className="nav-start-wrap">
                    <Button variant="flush-dark" onClick={() => toggleCollapsedNav(!navCollapsed)} className="btn-icon btn-rounded flush-soft-hover navbar-toggle d-xl-none me-2">
                        <span className="icon">
                            <span className="feather-icon"><AlignLeft /></span>
                        </span>
                    </Button>
                    {/* Search */}
                    <Dropdown as={Form} className="navbar-search" show={showDropdown} autoClose={() => setShowDropdown(!showDropdown)} >
                        <Dropdown.Toggle as="div" className="no-caret bg-transparent">
                            <Button variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover  d-xl-none" onClick={() => setShowDropdown(!showDropdown)} >
                                <span className="icon">
                                    <span className="feather-icon"><Search /></span>
                                </span>
                            </Button>
                            <InputGroup className="d-xl-flex d-none">
                                <span className="input-affix-wrapper input-search affix-border">
                                    <Form.Control type="text" className="bg-transparent" data-navbar-search-close="false" placeholder="Search..." aria-label="Search" onFocus={() => setShowDropdown(true)} onBlur={() => setShowDropdown(false)} value={searchValue} onChange={e => setSearchValue(e.target.value)} />
                                    <span className="input-suffix" onClick={() => setSearchValue("")} >
                                        <span>/</span>
                                        <span className="btn-input-clear">
                                            <i className="bi bi-x-circle-fill" />
                                        </span>
                                        <span className="spinner-border spinner-border-sm input-loader text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </span>
                                    </span>
                                </span>
                            </InputGroup>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={motion.div}
                            initial="initial"
                            animate={showDropdown ? "open" : "close"}
                            variants={pageVariants}
                            transition={{ duration: 0.3 }}
                            className={classNames("p-0")}
                        >
                            {/* Mobile Search */}
                            <Dropdown.Item className="d-xl-none bg-transparent">
                                <InputGroup className="mobile-search">
                                    <span className="input-affix-wrapper input-search">
                                        <Form.Control type="text" placeholder="Search..." aria-label="Search" value={searchValue} onChange={e => setSearchValue(e.target.value)} onFocus={() => setShowDropdown(true)} autoFocus />
                                        <span className="input-suffix" onClick={CloseSearchInput} >
                                            <span className="btn-input-clear">
                                                <i className="bi bi-x-circle-fill" />
                                            </span>
                                            <span className="spinner-border spinner-border-sm input-loader text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </span>
                                        </span>
                                    </span>
                                </InputGroup>
                            </Dropdown.Item>
                            {/*/ Mobile Search */}
                            <SimpleBar className="dropdown-body p-2">
                                <Dropdown.Divider as="div" />
                                <Dropdown.Header>Games</Dropdown.Header>
                                {
                                    games.map((game) => (
                                        <Dropdown.Item key={game.id}>
                                            <div className="media align-items-center">
                                                <div className="media-head me-2">
                                                    <div className="avatar avatar-xs avatar-rounded">
                                                        <img src={game.image} alt="user" className="avatar-img" />
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    {game.name}
                                                    <p style={{ opacity: 0.8, fontSize: 12 }}>{game.provider.name}</p>
                                                </div>
                                            </div>
                                        </Dropdown.Item>
                                    ))
                                }
                            </SimpleBar>
                            {
                                !games.length &&
                                <div className="dropdown-footer d-xl-flex d-none">
                                    No result
                                </div>
                            }
                            {/* <div className="dropdown-footer d-xl-flex d-none">
                                <Link to="#">
                                    <u>Search all</u>
                                </Link>
                            </div> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {/* /Start Nav */}
                {/* End Nav */}
                <div className="nav-end-wrap">
                    <Nav className="navbar-nav flex-row">
                        <Nav.Item>
                            <Button variant="flush-dark" as={Link} to="/ticket" className="btn-icon btn-rounded flush-soft-hover">
                                <span className="icon">
                                    <span className=" position-relative">
                                        <span className="feather-icon"><Inbox /></span>
                                        {/* <HkBadge bg="primary" soft pill size="sm" className="position-top-end-overflow-1" >4</HkBadge> */}
                                    </span>
                                </span>
                            </Button>
                        </Nav.Item>
                        <Nav.Item>
                            <Dropdown className="dropdown-notifications">
                                <Dropdown.Toggle variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover no-caret">
                                    <span className="icon">
                                        <span className="position-relative">
                                            <span className="feather-icon"><Bell /></span>
                                            <HkBadge bg="success" indicator className="position-top-end-overflow-1" />
                                        </span>
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end" className="p-0">
                                    <Dropdown.Header className="px-4 fs-6">
                                        Notifications
                                    </Dropdown.Header>
                                    {
                                        !notifications.length ?
                                            <div className='text-center p-5'>
                                                <img src={notificationsImg} alt='notification' style={{ height: 200 }} />
                                                <p>You don't have notifications currently</p>
                                            </div> :
                                            <SimpleBar className="dropdown-body  p-2">
                                                {
                                                    notifications.map((notification) => (
                                                        <Dropdown.Item key={notification.id}>
                                                            <div className="media">
                                                                <div className="media-head">
                                                                    <div className="avatar  avatar-icon avatar-sm avatar-pink avatar-rounded">
                                                                        <div className="avatar avatar-primary avatar-sm avatar-rounded">
                                                                            <span className="initial-wrap text-uppercase">{`${notification.user.firstName[0]}${notification.user.lastName[0]}`}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="media-body">
                                                                    <div>
                                                                        <div className="notifications-text">{notification.title}</div>
                                                                        <div className="notifications-info">
                                                                            <HkBadge bg={notification.level} soft >{notification.label}</HkBadge>
                                                                            <div className="notifications-time">{dateFormat(notification.createdAt)}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Dropdown.Item>
                                                    ))
                                                }
                                            </SimpleBar>
                                    }
                                    {
                                        !!notifications.length &&
                                        <div className="dropdown-footer">
                                            <Link to="/notification"><u>View all notifications</u>
                                            </Link>
                                        </div>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav.Item>
                        <Nav.Item>
                            {
                                user &&
                                <Dropdown className="ps-2">
                                    <Dropdown.Toggle as={Link} to="#" className="no-caret">
                                        <div className="avatar avatar-primary avatar-xs avatar-rounded">
                                            <span className="initial-wrap">{`${user?.firstName[0]}${user?.lastName[0]}`}</span>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="end">
                                        <div className="p-2">
                                            <div className="media">
                                                <div className="media-head me-2">
                                                    <div className="avatar avatar-primary avatar-sm avatar-rounded">
                                                        <span className="initial-wrap">{`${user?.firstName[0]}${user?.lastName[0]}`}</span>
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <div to="#" className="d-block fw-medium text-dark">{`${user?.firstName} ${user?.lastName}`}</div>
                                                    <div className="fs-7">{user?.email}</div>
                                                    <Link to="#" className="d-block fs-8 link-secondary" onClick={() => logout()}>
                                                        <u>Sign Out</u>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <Dropdown.Divider as="div" />
                                        <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/notification">
                                            <span className="me-2">Notificatons</span>
                                        </Dropdown.Item>
                                        <Dropdown.Divider as="div" />
                                        <h6 className="dropdown-header">Manage Account</h6>
                                        <Dropdown.Item>
                                            <span className="dropdown-icon feather-icon">
                                                <CreditCard />
                                            </span>
                                            <span>Invoices</span>
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item>
                                            <span className="dropdown-icon feather-icon">
                                                <Settings />
                                            </span>
                                            <span>Settings</span>
                                        </Dropdown.Item> */}
                                        {/* <Dropdown.Divider as="div" /> */}
                                        {
                                            user.isOperator &&
                                            <Dropdown.Item as={Link} to='/ticket'>
                                                <span className="dropdown-icon feather-icon">
                                                    <Tag />
                                                </span>
                                                <span>Open ticket</span>
                                            </Dropdown.Item>
                                        }
                                        <Dropdown.Divider as="div" />
                                        <Dropdown.Item as={Link} to="/terms-of-service">
                                            Terms Of Servics
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item>
                                            Help &amp; Support
                                        </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </Nav.Item>
                    </Nav>
                </div>
                {/* /End Nav */}
            </Container>
        </Navbar>
    )
}

const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(TopNav);