import { useEffect, useCallback, useState } from 'react';
import { useLocation } from "react-router-dom";

//
import { useAuthContext } from '../hooks';
import { SidebarMenu } from '../../layout/Sidebar/SidebarMenu';

// ----------------------------------------------------------------------

const loginPaths = {
    jwt: '/auth/login'
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
    const location = useLocation();

    const { authenticated, method, user } = useAuthContext();
    const [checked, setChecked] = useState(false);

    const permissionCheck = useCallback(() => {
        if (location.pathname === '/' || location.pathname === '/error-404' || location.pathname === '/auth/login') return true;
        let id = '';
        for (const group of SidebarMenu) {
            const idx = group.contents.findIndex((item) => item.path.search(location.pathname.split('/')[1]));
            if (idx !== -1) {
                if (group.contents[idx].id) {
                    id = group.contents[idx].id;
                    break;
                } else {
                    return true;
                }
            }
        };
        if (!id) {
            return false;
        } else {
            const idx = user.permissions.findIndex((permission) => permission.permissionModel === id);
            if (
                user.permissions[idx] &&
                (user.permissions[idx].permissionType === 'GET' ||
                    user.permissions[idx].permissionType === 'POST' ||
                    user.permissions[idx].permissionType === 'PUT' ||
                    user.permissions[idx].permissionType === 'DELETE')
            ) {
                return true;
            } else {
                return false;
            }
        }
    }, [user, location])


    const check = useCallback(async () => {
        if (!authenticated) {
            const loginPath = loginPaths[method];
            const href = loginPath;
            window.location.assign(href);
        } else {
            const permissionCheckResult = await permissionCheck();
            if (permissionCheckResult) {
                setChecked(true);
            } else {
                window.location.assign('/error-404');
            }
        }
    }, [authenticated, method, permissionCheck]);

    useEffect(() => {
        check();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    if (!checked) {
        return null;
    }

    return <>{children}</>;
}
