import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import React, { useEffect, useMemo, useState } from 'react';
import EmailBody from './EmailBody';
import EmailSidebar from './EmailSidebar';
import InboxList from './Inbox';

//Redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getTickets } from '../../utils/apis';
import { useAuthContext } from '../../auth/hooks';

const Email = ({ startChating }) => {
    const { user } = useAuthContext();

    const [showSidebar, setshowSidebar] = useState(true);
    const [data, setData] = useState([]);
    const [active, setActive] = useState('open');
    const [search, setSearch] = useState('');
    const [selectedId, setSelectedId] = useState('');

    const loadData = async () => {
        try {
            let where = {}
            if (user.isOperator) {
                where.user = user.id;
            }
            const res = await getTickets(`where=${JSON.stringify(where)}&sort=createdAt%20DESC`);
            setData(res);
        } catch (error) {
            toast.error(error.message);
        }
    }

    const filtered = useMemo(() => {
        if (active === 'open') {
            return data.filter((e) => e.status !== 'closed').filter((e) => e.title.toLowerCase().indexOf(search.toLowerCase()) !== -1);
        }
        return data.filter((e) => e.status === active).filter((e) => e.title.toLowerCase().indexOf(search.toLowerCase()) !== -1);;
    }, [active, data, search]);

    const openItem = useMemo(() => {
        const item = data.filter((e) => e.id === selectedId);
        if (item.length) {
            return item[0];
        }
        return {};
    }, [data, selectedId])

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title> Tickets | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className={classNames("emailapp-wrap", { "emailapp-sidebar-toggle": !showSidebar }, { "emailapp-slide": startChating })}>
                    <EmailSidebar active={active} setActive={setActive} />
                    <div className="emailapp-content">
                        <InboxList loadData={loadData} data={filtered} setData={setData} search={search} setSearch={setSearch} setSelectedId={setSelectedId} show={showSidebar} toggleSidebar={() => setshowSidebar(!showSidebar)} />
                        <div className="emailapp-single-email">
                            <EmailBody data={openItem} setData={setData} setSelectedId={setSelectedId} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ chatReducer }) => {
    const { startChating } = chatReducer;
    return { startChating }
};

export default connect(mapStateToProps)(Email);