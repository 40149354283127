import React, { useState, useEffect } from 'react';

import SimpleBar from 'simplebar-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Button, Col, Form, Nav, Row } from 'react-bootstrap';
import { RefreshCw } from 'react-feather';

import { columns } from '../TableData';
import HkTooltip from '../../../components/@hk-tooltip/HkTooltip';
import HkBadge from '../../../components/@hk-badge/@hk-badge';

import DataTable from '../../../components/DataTable';
import DeleteModal from './DeleteModal';

import { deleteProvider, getProvidersCount, getProviders } from '../../../utils/apis';
import { downloadCSV, getPartPermission } from '../../../utils/utils';
import { useAuthContext } from '../../../auth/hooks';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFFile from '../../../components/PDFFile';

const rowsPerPage = 50;

const ProvidersBody = () => {
    const { user } = useAuthContext();

    const [downloadType, setDownloadType] = useState('1');
    const [searchTerm, setSearchTerm] = useState('');
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteGroupData, setDeleteGroupData] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const [deletedCount, setDeletedCount] = useState(0);
    const [sortConfig, setSortConfig] = useState({});
    const [partPermission, setPartPermission] = useState({});
    const [downloadData, setDownloadData] = useState([]);
    const [isLoading, setIsLoading] = useState({
        getAction: true,
        deleteAction: false
    });

    const updateLoading = (data) => {
        setIsLoading((pre) => ({ ...pre, ...data }));
    }

    const closeDeleteModal = () => {
        if (!isLoading.deleteAction) {
            setShowDeleteModal(false);
        }
    }

    const handleDelete = (group) => {
        setDeleteGroupData({ ...group, isMultiple: false });
        setShowDeleteModal(true);
    }

    const handleDeleteGroups = () => {
        setDeleteGroupData({ isMultiple: true });
        setShowDeleteModal(true);
    }

    const requestSort = (sort, key) => {
        let direction = 'ASC';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ASC'
        ) {
            direction = 'DESC';
        };
        if (sort) {
            setSortConfig({ key, direction });
        }
    };

    const getProvidersAction = async (query) => {
        try {
            updateLoading({ getAction: true });
            const providers = await getProviders(query);
            setData(
                providers.map((provider) => ({
                    id: provider.id,
                    name: provider.name,
                    username: provider.username,
                    password: provider.password,
                    apiKey: provider.apiKey,
                    gameCategories: provider.gameCategories.map((e) => e.name),
                    percentCategories: provider.percentCategories.map((e) => `${e.name} | ${e.percent}`),
                    createdAt: provider.createdAt,
                    updatedAt: provider.updatedAt,
                    actions: {
                        id: provider.id,
                        name: provider.name,
                        handleDelete
                    }
                }))
            );
            updateLoading({ getAction: false });
        } catch (error) {
            updateLoading({ getAction: false });
            toast.error(error.message);
        }
    }

    const deleteGroupAction = async (id) => {
        try {
            await deleteProvider(id);
            setData((pre) => pre.filter((e) => e.id !== id));
            toast.success('Success');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const deleteAction = async (isMultple) => {
        try {
            updateLoading({ deleteAction: true });
            if (isMultple) {
                for (const id of selectedIds) {
                    await deleteGroupAction(id);
                    setDeletedCount((pre) => (pre + 1))
                }
                setSelectedIds([]);
            } else {
                await deleteGroupAction(deleteGroupData.id);
                setDeleteGroupData({});
            }
            setShowDeleteModal(false);
            updateLoading({ deleteAction: false });
        } catch (error) {
            updateLoading({ deleteAction: false });
            console.log(error);
        }
    }

    const getSelectedIds = (ids) => {
        setSelectedIds([...ids])
    }

    const getProvidersCountAction = async (query) => {
        try {
            const groupslength = await getProvidersCount(query);
            setCount(groupslength.count);
        } catch (error) {
            console.log(error);
        }
    }

    const loadData = (newPage) => {
        const where = {
            or: [
                { groupName: { contains: searchTerm } }
            ]
        }
        let condition = `where=${JSON.stringify(where)}`;
        const skip = newPage ? newPage - 1 : page - 1;

        if (newPage > 1) {
            setPage(newPage);
        } else {
            getProvidersCountAction(condition);
        }

        if (sortConfig.key) {
            condition += `&sort=${sortConfig.key}%20${sortConfig.direction}`;
        }
        getProvidersAction(condition + `&skip=${rowsPerPage * skip}&limit=${rowsPerPage}`);
    }

    const loadExportData = async () => {
        const where = {
            or: [
                { groupName: { contains: searchTerm } }
            ]
        }

        let condition = `where=${JSON.stringify(where)}`;
        if (sortConfig.key) {
            condition += `&sort=${sortConfig.key}%20${sortConfig.direction}`;
        }
        const exportData = await getProviders(condition);
        const data = exportData.map((e) => ({
            Id: e.id,
            Name: e.name,
            Username: e.username,
            Password: e.password,
            'API Key': e.apiKey,
            'Game Cagegories': e.gameCategories.map((e) => e.name).join(' '),
            'percent Cagegories': e.percentCategories.map((e) => `${e.name} | ${e.percent}`).join(' '),
            'Created Date': moment(e.createdAt).format('MM/DD/yyyy hh:mm:ss'),
            'Last Edited': moment(e.updatedAt).format('MM/DD/yyyy hh:mm:ss'),
        }));

        setDownloadData(data);
        return data;
    }

    const switchDownload = async (value) => {
        setDownloadType(value);
        if (value === '2') {
            await loadExportData()
        }
    }

    const csvExport = async () => {
        const data = await loadExportData();
        downloadCSV(data, 'providers');
    }

    useEffect(() => {
        loadData(1);
        // eslint-disable-next-line
    }, [sortConfig]);

    useEffect(() => {
        const permission = getPartPermission(user.permissions, 'provider');
        setPartPermission(permission);
        loadData(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="contact-body">
                <Nav variant="tabs" className="nav-light nav-line page-tab-header">
                    <Nav.Item>
                        <Nav.Link eventKey="-" className='active' >
                            <span className="nav-link-text">All Providers
                                {
                                    !!count &&
                                    <HkBadge bg="light" size="sm" text="dark" className="ms-1">{count}</HkBadge>
                                }
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <div className='page-tab-body mt-1'>
                    <SimpleBar className="nicescroll-bar">
                        <div className="contact-list-view">
                            <Row className="mb-3" >
                                <Col xs={12} sm={4} mb={3}>
                                    <div className="contact-toolbar-left">
                                        <Form.Group className="d-flex align-items-center mb-0">
                                            <Form.Select size="sm" className="d-flex align-items-center w-130p" onChange={(e) => switchDownload(e.target.value)}>
                                                <option value={1}>Export to CSV</option>
                                                <option value={2}>Export to PDF</option>
                                            </Form.Select>
                                            {
                                                downloadType === '1' &&
                                                <Button size="sm" variant="light" className="ms-2" onClick={csvExport}>Export</Button>
                                            }
                                            {
                                                !!downloadData.length && downloadType === '2' &&
                                                <PDFDownloadLink
                                                    document={<PDFFile data={downloadData} />}
                                                    fileName={'providers'}
                                                    className='ms-2 btn btn-light btn-sm'
                                                >
                                                    Export
                                                </PDFDownloadLink>
                                            }
                                        </Form.Group>
                                        {
                                            !!selectedIds.length &&
                                            <Button size='sm' variant='danger' onClick={handleDeleteGroups}>Delete Providers</Button>
                                        }
                                    </div>
                                </Col>
                                <Col xs={12} sm={8} mb={3}>
                                    <div className="contact-toolbar-right">
                                        <div className="dataTables_filter d-flex align-items-center">
                                            <Form.Group className='d-flex align-items-center'>
                                                <Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        type="search"
                                                        placeholder="Search"
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                    />
                                                </Form.Label>
                                                <Button as="a" variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover no-caret d-sm-inline-block ms-1" href="#" onClick={() => loadData(1)}>
                                                    <HkTooltip title="Refresh" placement="top" >
                                                        <span className="icon">
                                                            <span className="feather-icon">
                                                                <RefreshCw />
                                                            </span>
                                                        </span>
                                                    </HkTooltip>
                                                </Button>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <DataTable
                                column={columns(partPermission)}
                                rowData={data}
                                page={page}
                                isLoading={isLoading.getAction}
                                sortConfig={sortConfig}
                                setPage={loadData}
                                sortCb={requestSort}
                                rowsPerPage={rowsPerPage}
                                totoalCount={count}
                                rowSelection={true}
                                selectedCb={getSelectedIds}
                                classes="nowrap w-100 mb-5"
                                responsive
                            />
                        </div>
                    </SimpleBar >
                </div>
            </div>
            <DeleteModal
                show={showDeleteModal}
                close={closeDeleteModal}
                selectedIds={selectedIds}
                deletedCount={deletedCount}
                isLoading={isLoading.deleteAction}
                deleteGroupData={deleteGroupData}
                deleteAction={deleteAction} />
        </>
    )
}

export default ProvidersBody;
