import React from 'react';
import { Helmet } from 'react-helmet-async';

import RoundsHeader from './list/RoundsHeader';
import RoundsBody from './list/RoundsBody';

const Rounds = () => {
    return (
        <>
            <Helmet>
                <title> Round List | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <RoundsHeader />
                        <RoundsBody />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Rounds;
