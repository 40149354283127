import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import {
    Nav,
    Table,
    Button,
    Col,
    Form,
    Row,
    Card,
    InputGroup,
} from 'react-bootstrap';
import { Calendar } from 'react-feather';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import { ArrowsSort, SortAscending, SortDescending } from 'tabler-icons-react';
import { getFinanceReports, getProviders } from '../../../utils/apis';
import { toast } from 'react-toastify';
import { columns } from '../TableData';

import classNames from 'classnames';
import ErrorModal from './ErrorModal';
import moment from 'moment';

const FianceReportBody = () => {

    const [data, setData] = useState([]);
    const [providers, setProviders] = useState([]);
    const [modalData, setModalData] = useState({});
    const [sortConfig, setSortConfig] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [total, setTotal] = useState({
        totalWins: 0, totalBets: 0, GGR: 0, serviceFee: 0
    });
    const [dateRange, setRange] = useState({
        start: moment().startOf('hour').add(-24, 'hour').toDate(),
        end: moment().startOf('hour').toDate(),
    });
    const [loading, setLoading] = useState({
        getRound: true,
        getTransaction: false
    });
    const [filterData, setFilterData] = useState({
        username: '',
        provider: ''
    });

    const updateLoading = (data) => {
        setLoading((pre) => ({ ...pre, ...data }));
    }

    const updateFilter = (data) => {
        setFilterData((pre) => ({ ...pre, ...data }));
    }

    const requestSort = (sort, key) => {
        let direction = 'ASC';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ASC'
        ) {
            direction = 'DESC';
        };
        if (sort) {
            setSortConfig({ key, direction });
        }
    };

    const closeErrorModal = (data) => {
        setModalData({});
        setShowModal(false)
    }

    const getReportsAction = async () => {
        try {
            const where = {
                createdAt: { '>': new Date(dateRange.start).valueOf() },
                createdAt1: { '<': new Date(dateRange.end).valueOf() }
            };

            if (filterData.provider) {
                where.provider = filterData.provider;
            }

            if (filterData.username) {
                where.userName = { contains: filterData.username }
            }

            let strWhere = JSON.stringify(where);
            strWhere = strWhere.replaceAll('createdAt1', 'createdAt');

            let condition = `where=${strWhere}`;

            updateLoading({ getRound: true });
            const reports = await getFinanceReports(condition);
            setData(reports);
            let totalWins = 0, totalBets = 0, GGR = 0, serviceFee = 0;
            for (const one of reports) {
                totalWins += one.totalWins;
                totalBets += one.totalBets;
                GGR += one.GGR;
                serviceFee += one.serviceFee;
            }
            setTotal({
                totalWins,
                totalBets,
                GGR,
                serviceFee
            })
            updateLoading({ getRound: false });
        } catch (error) {
            updateLoading({ getRound: false });
            toast.error(error.message);
        }
    }

    const loadData = () => {
        getReportsAction();
    }

    const changeDate = (start, end) => {
        setRange({ start: start.toDate(), end: end.toDate() });
    }

    const loadProvider = async () => {
        try {
            const providerData = await getProviders();
            setProviders(providerData);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        loadProvider();
        loadData(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="contact-body">
                <Nav variant="tabs" className="nav-light nav-line">
                    <Nav.Item>
                        <Nav.Link eventKey="overview" className='active' >
                            <span className="nav-link-text text-capitalize">
                                Fiance Report
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <div className='page-tab-body mt-1'>
                    <SimpleBar className="nicescroll-bar">
                        <div className="contact-list-view">
                            <Card className='p-3'>
                                <Row >
                                    <Col xs={12}>
                                        <Row>
                                            <Col md={4} sm={6}>
                                                <Form.Label>Date</Form.Label>
                                                <InputGroup>
                                                    <span className="input-affix-wrapper">
                                                        <span className="input-prefix">
                                                            <span className="feather-icon">
                                                                <Calendar />
                                                            </span>
                                                        </span>
                                                        <DateRangePicker
                                                            initialSettings={{
                                                                timePicker: true,
                                                                startDate: dateRange.start,
                                                                endDate: dateRange.end,
                                                                locale: {
                                                                    format: 'M/DD hh:mm A',
                                                                },
                                                            }}
                                                            onCallback={changeDate}
                                                        >
                                                            <Form.Control type="text" name="datetimes" />
                                                        </DateRangePicker>
                                                    </span>
                                                </InputGroup>
                                            </Col>
                                            <Col md={2} sm={6}>
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control type="text" placeholder="Username" value={filterData.username} onChange={(e) => updateFilter({ username: e.target.value })} />
                                            </Col>
                                            <Form.Group as={Col} md={2} sm={6}>
                                                <Form.Label>Provider</Form.Label>
                                                <Form.Select value={filterData.provider} onChange={(e) => updateFilter({ provider: e.target.value })}>
                                                    <option value="" >All</option>
                                                    {
                                                        providers.map((provider) => (
                                                            <option value={provider.id} key={provider.id}>{provider.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                            <Col md={2} sm={6} className='d-flex align-items-end'>
                                                <Button disabled={loading.getRound} className='mt-2' onClick={() => loadData(1)}>Search</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>

                            <Table
                                className="hk-data-table"
                            >
                                <thead>
                                    <tr>
                                        {columns.map((cols, index) => (
                                            <th
                                                key={index}
                                                onClick={() => requestSort(cols.sort, cols.accessor)}
                                                className={classNames({ 'd-none': cols.hidden }, { "text-primary": sortConfig !== null && sortConfig.key === cols.accessor }, cols.className)}
                                                rowSpan={cols.rowSpan}
                                            >
                                                <span className="d-flex">
                                                    <span className="flex-grow-1">
                                                        {cols.title}
                                                    </span>
                                                    {
                                                        cols.sort &&
                                                        <span>
                                                            {(sortConfig !== null && cols.accessor === sortConfig.key)
                                                                ?
                                                                <>
                                                                    {
                                                                        (sortConfig.direction === 'ASC')
                                                                            ?
                                                                            <font color="#007D88">
                                                                                <SortAscending size={14} strokeWidth={2.5} />
                                                                            </font>
                                                                            :
                                                                            <font color="#007D88">
                                                                                <SortDescending size={14} strokeWidth={2.5} />
                                                                            </font>
                                                                    }
                                                                </>
                                                                :
                                                                <span><ArrowsSort size={14} strokeWidth={2.5} /> </span>}
                                                        </span>
                                                    }
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (() => {
                                            if (loading.getRound) {
                                                return (
                                                    <tr>
                                                        <td colSpan={columns.length}>
                                                            <p className='text-center'>Loading...</p>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                if (!data.length) {
                                                    return (
                                                        <tr>
                                                            <td colSpan={columns.length}>
                                                                <p className='text-center'>No data</p>
                                                            </td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return [...data.map((row, index) => (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                {columns.map((cols, index) => (
                                                                    <td
                                                                        key={index}
                                                                        className={classNames({ 'd-none': cols.hidden }, cols.tdClasses)}
                                                                    >
                                                                        {
                                                                            cols.cellFormatter
                                                                                ?
                                                                                cols.cellFormatter(row[cols.accessor])
                                                                                :
                                                                                row[cols.accessor]
                                                                        }
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        </React.Fragment>
                                                    )),
                                                    (
                                                        <tr key={data.length} style={{ backgroundColor: '#007D88', color: "#fff" }}>
                                                            <td>Total</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>€ {total.totalBets.toLocaleString()}</td>
                                                            <td>€ {total.totalWins.toLocaleString()}</td>
                                                            <td>€ {total.GGR.toLocaleString()}</td>
                                                            <td></td>
                                                            <td>€ {total.serviceFee.toLocaleString()}</td>
                                                        </tr>
                                                    )
                                                    ]
                                                }
                                            }
                                        })()
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </SimpleBar >
                </div>
            </div>
            <ErrorModal show={showModal} close={closeErrorModal} data={modalData} />
        </>
    )
}

export default FianceReportBody;
