import React from 'react';
import { Helmet } from 'react-helmet-async';

import PermissionsHeader from './list/PermissionsHeader';
import PermissionsBody from './list/PermissionsBody';

const Users = () => {
    return (
        <>
            <Helmet>
                <title> Permission List | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <PermissionsHeader />
                        <PermissionsBody/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users;
