import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Edit, Trash } from 'react-feather';
import HkBadge from '../../components/@hk-badge/@hk-badge';
import moment from 'moment';

//Custom Action Container
const actionFormater = (cell, permission) => {
    return (
        <div className="d-flex align-items-center" >
            <div className="d-flex">
                {
                    permission.PUT &&
                    <Button variant="flush-dark" as={Link} to={`/provider/${cell.id}/edit`} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                        <span className="icon">
                            <span className="feather-icon">
                                <Edit />
                            </span>
                        </span>
                    </Button>
                }
                {
                    permission.DELETE &&
                    <Button onClick={() => cell.handleDelete({ id: cell.id, name: cell.name })} variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete">
                        <span className="icon">
                            <span className="feather-icon">
                                <Trash />
                            </span>
                        </span>
                    </Button>
                }
            </div>
        </div>
    )
}

// Permissions in Group formater
const itemsFormater = (cell) => {
    if (!cell.length) return null;
    return (
        <>
            {
                cell.slice(0, 3).map((item, index) => (
                    <HkBadge bg="light" size="md" text="dark" className="ms-1" key={index}>{item}</HkBadge>
                ))
            }
            {
                (cell.length > 3) &&
                <HkBadge bg="light" size="md" text="dark" className="ms-1">+{cell.length - 3}</HkBadge>
            }
        </>
    )
}

const dateFormater = (cell) => {
    return moment(cell).format('MM/DD/yyyy hh:mm:ss')
}

export const columns = (permission) => {
    const data = [
        {
            accessor: "id",
            title: "Id",
        },
        {
            accessor: "name",
            title: "Name",
            sort: true,
        },
        {
            accessor: "username",
            title: "Username",
            sort: true,
        },
        {
            accessor: "password",
            title: "Password",
        },
        {
            accessor: "apiKey",
            title: "API Key",
        },
        {
            accessor: "gameCategories",
            title: "Game Categories",
            cellFormatter: itemsFormater,
        },
        {
            accessor: "percentCategories",
            title: "Percent Categories",
            cellFormatter: itemsFormater,
        },
        {
            accessor: "createdAt",
            title: "Created Date",
            sort: true,
            cellFormatter: dateFormater,
        },
        {
            accessor: "updatedAt",
            title: "Last Edited",
            sort: true,
            cellFormatter: dateFormater,
        }
    ];

    if (permission.PUT || permission.DELETE) {
        data.push(
            {
                accessor: "actions",
                title: "Actions",
                cellFormatter: (cell) => actionFormater(cell, permission),
            },
        )
        if (permission.PUT) {
            data[0].cellFormatter = (cell) => <Link to={`/provider/${cell}/edit`}>{cell}</Link>
        }
    }
    return data;
}