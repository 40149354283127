import React, { useState } from 'react';
import { Badge, Button, Form, ListGroup } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size';
import { Edit, RefreshCw } from 'react-feather';
import ComposeEmail from './ComposeEmail';

//Redux
import { connect } from 'react-redux';
import { StartConversation } from '../../redux/action/Chat';
import { toggleTopNav } from '../../redux/action/Theme';
import { Compose } from '../../redux/action/Email';

import moment from 'moment';

const priorityStatus = {
    high: 'danger',
    medium: 'orange',
    low: 'success'
}

const InboxList = ({
    data,
    setData,
    loadData,
    search,
    setSearch,
    setSelectedId,
    toggleSidebar,
    show,
    StartConversation,
    startChating,
    toggleTopNav,
    topNavCollapsed,
    Compose,
    composeEmail }) => {

    const [showComposePopup, setShowComposePopup] = useState(false);

    const width = useWindowWidth();
    const Conversation = (ticket) => {
        setSelectedId(ticket.id)
        if (width <= 991) {
            StartConversation(!startChating);
            toggleTopNav(!topNavCollapsed);
        }
    }

    return (
        <>
            <div className="emailapp-aside">
                <header className="aside-header">
                    <h4>Your Tickets</h4>
                    <div className="d-flex">
                        <Button onClick={loadData} as="a" href="#" className="btn-icon btn-flush-dark btn-rounded flush-soft-hover me-0">
                            <span className="icon">
                                <span className="feather-icon">
                                    <RefreshCw />
                                </span>
                            </span>
                        </Button>
                        <Button as="a" href="#" className="btn-icon btn-rounded show-compose-popup" onClick={() => Compose(!composeEmail)} >
                            <span className="icon">
                                <span className="feather-icon">
                                    <Edit />
                                </span>
                            </span>
                        </Button>
                    </div>
                    <div className={classNames("hk-sidebar-togglable", { "active": !show })} onClick={toggleSidebar} />
                </header>
                <SimpleBar className="aside-body">
                    <Form className="aside-search" role="search">
                        <Form.Control type="text" placeholder="Search tickets" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </Form>
                    <ListGroup as="ul" variant="flush" className="email-list">
                        {
                            data.map((item) => (
                                <ListGroup.Item as="li" onClick={() => Conversation(item)} key={item.id}>
                                    <div className="media">
                                        <div className="media-head">
                                            <div className="avatar avatar-primary avatar-sm avatar-rounded">
                                                {/* <img src={avatar2} alt="user" className="avatar-img" /> */}
                                                <span className="initial-wrap">{`${item.user.firstName[0]}${item.user.lastName[0]}`}</span>
                                            </div>
                                            {/* <Badge bg="primary" className="badge-indicator badge-indicator-nobdr" /> */}
                                        </div>
                                        <div className="media-body">
                                            <div>
                                                <div>
                                                    <div className="email-head">{`${item.user.firstName} ${item.user.lastName}`}</div>
                                                    <Badge pill bg={priorityStatus[item.priority]} className="badge-sm ">{item.priority}</Badge>
                                                    <div>
                                                        <span className="email-star marked">
                                                            <span className="feather-icon">
                                                                {/* <Star /> */}
                                                            </span>
                                                        </span>
                                                        <div className="email-time">{moment(item.createdAt).format('DD MMM  h:mm A')}</div>
                                                    </div>
                                                </div>
                                                <div className="email-subject">{item.title}</div>
                                                <div className="email-text">
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </SimpleBar>
            </div>
            {/* Compose email */}
            <ComposeEmail show={showComposePopup} setData={setData} onClose={() => setShowComposePopup(!showComposePopup)} />
            {/* /Compose email */}
        </>
    )
}

const mapStateToProps = ({ chatReducer, theme, emailReducer }) => {
    const { startChating } = chatReducer;
    const { composeEmail } = emailReducer;
    const { topNavCollapsed } = theme;
    return { startChating, composeEmail, topNavCollapsed }
};

export default connect(mapStateToProps, { StartConversation, toggleTopNav, Compose })(InboxList);
