import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Nav, Row, Tab, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useAuthContext } from '../../../auth/hooks';
import { toast } from 'react-toastify';
// Redux
import { connect } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateUser } from '../../../utils/apis';

const EditProfile = () => {
    const { user, updateAuth } = useAuthContext();

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordVal, setPasswordVal] = useState(false);
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        name: '',
        organization: '',
        address: '',
        websiteUrl: '',
        walletApiUrl: ''
    });

    const updateUserData = (data) => {
        setUserData((pre) => ({ ...pre, ...data }));
    }

    const changePasswordSubmit = async (e) => {
        try {
            e.preventDefault();
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            }
            setPasswordVal(true);

            if (password === '') return;

            setLoading(true);
            await updateUser({ password }, user.id);
            toast.success('Success');
            setLoading(false);
            setPasswordVal(false);
            setPassword('');
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }


    const onDataSubmit = async (e) => {
        try {
            e.preventDefault();
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            }

            setValidated(true);

            if (userData.firstName === '' ||
                userData.lastName === '' ||
                userData.name === '' ||
                userData.email === '' ||
                userData.organization === '' ||
                userData.address === ''
            ) {
                return;
            }

            setLoading(true);
            const updatedData = await updateUser(userData, user.id);
            await updateAuth({
                ...user,
                firstName: updatedData.firstName,
                lastName: updatedData.lastName,
                email: updatedData.email,
                name: updatedData.name,
                organization: updatedData.organization,
                address: updatedData.address,
                websiteUrl: updatedData.websiteUrl,
                walletApiUrl: updatedData.walletApiUrl,
            });
            toast.success('Success');
            setLoading(false);
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        setUserData({
            name: user.name,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            organization: user.organization,
            address: user.address,
            websiteUrl: user.websiteUrl,
            walletApiUrl: user.walletApiUrl
        });
    }, [user]);

    if (!user) return null;

    return (
        <>
            <Helmet>
                <title> Edit Profile | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <Container>
                <div className="hk-pg-header pt-7 pb-4">
                    <h1 className="pg-title">Edit Profile</h1>
                </div>
                {/* Page Body */}
                <div className="hk-pg-body">
                    <Tab.Container defaultActiveKey="account-setting">
                        <Row className="edit-profile-wrap">
                            <Col xs={4} sm={3} lg={2}>
                                <div className="nav-profile mt-4">
                                    <div className="nav-header">
                                        <span>Account</span>
                                    </div>
                                    <Nav as="ul" variant="tabs" className="nav-light nav-vertical">
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="account-setting">
                                                <span className="nav-link-text">Account Settings</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="change-password">
                                                <span className="nav-link-text">Change Password</span>
                                            </Nav.Link>
                                        </Nav.Item>

                                    </Nav>
                                </div>
                            </Col>
                            <Col lg={10} sm={9} xs={8}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="account-setting">
                                        <div className="title-lg fs-4"><span>Account Settings</span></div>
                                        <Form noValidate validated={validated} onSubmit={onDataSubmit}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridFirstName">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" placeholder="First Name" required value={userData.firstName} onChange={(e) => updateUserData({ firstName: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        First Name is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridLastName">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Last Name" required value={userData.lastName} onChange={(e) => updateUserData({ lastName: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Last Name is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridName">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Name" required value={userData.name} onChange={(e) => updateUserData({ name: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Name is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" placeholder="Email" required value={userData.email} onChange={(e) => updateUserData({ email: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Email is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridOrganization">
                                                    <Form.Label>Organization</Form.Label>
                                                    <Form.Control type="text" placeholder="Organiztion" required value={userData.organization} onChange={(e) => updateUserData({ organization: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Organization is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridAddress">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" required value={userData.address} onChange={(e) => updateUserData({ address: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Address is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridWebsite">
                                                    <Form.Label>Website Url</Form.Label>
                                                    <Form.Control type="text" placeholder="https://xxxx.xxx" required value={userData.websiteUrl} onChange={(e) => updateUserData({ websiteUrl: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Website Url is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridWalletApi">
                                                    <Form.Label>Wallet API Url</Form.Label>
                                                    <Form.Control type="text" placeholder="https://api.xxxx.xxx" required value={userData.walletApiUrl} onChange={(e) => updateUserData({ walletApiUrl: e.target.value })} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Wallet API Url is required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            {
                                                loading ?
                                                    <Button variant="primary" className='btn btn-primary' disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        Loading...
                                                    </Button>
                                                    : <Button type='submit' submit="true">Save Changes</Button>
                                            }
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="change-password">
                                        <div className="title-lg fs-4"><span>Login &amp; Security</span></div>
                                        <div className="title title-xs title-wth-divider text-primary text-uppercase my-4"><span>Password Settings</span></div>
                                        <Row className="gx-3">
                                            <Form noValidate validated={passwordVal} onSubmit={changePasswordSubmit}>
                                                <Col sm={12}>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Label>Password</Form.Label>
                                                        <Form.Control type="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                                                        <Form.Control.Feedback type="invalid">
                                                            Password is required
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    {
                                                        loading ?
                                                            <Button variant="primary" className='btn btn-primary' disabled>
                                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                Loading...
                                                            </Button>
                                                            : <Button type='submit' submit="true">Changes password</Button>
                                                    }
                                                </Col>
                                            </Form>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
                {/* /Page Body */}
            </Container>
        </>

    )
}

const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(EditProfile);