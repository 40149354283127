/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useMemo } from 'react';
import { Nav } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { NavLink, useRouteMatch } from 'react-router-dom';
import SidebarHeader from './SidebarHeader';
import { SidebarMenu } from './SidebarMenu';
import classNames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size';
import { useAuthContext } from '../../auth/hooks';

const Sidebar = ({ navCollapsed, toggleCollapsedNav }) => {

    const { user, permissions } = useAuthContext();

    const [activeMenu, setActiveMenu] = useState();
    const [activeSubMenu, setActiveSubMenu] = useState();

    const windowWidth = useWindowWidth();

    const handleClick = (menuName) => {
        setActiveMenu(menuName);
        if (activeMenu !== 'Dashboard' && windowWidth >= 1200) {
            // toggleCollapsedNav(true);
        }
        else if (windowWidth <= 1199) {
            toggleCollapsedNav(false);
        };

        if (menuName === 'Dashboard') {
            toggleCollapsedNav(false);
        }
    }

    const backDropToggle = () => {
        toggleCollapsedNav(!navCollapsed);
    }

    const SidebarMenuList = useMemo(() => {
        if (user && permissions) {
            const finalMenu = [];
            for (const group of SidebarMenu) {

                if (group.isFree) {
                    finalMenu.push(group);
                    continue;
                }

                if (group.group === 'Support') {
                    if (user.isSuperAdmin) {
                        finalMenu.push(group);
                        continue;
                    } else {
                        continue;
                    }
                }

                const contents = [];
                for (const menuItem of group.contents) {
                    if (!menuItem.id) {
                        contents.push(menuItem);
                    } else {
                        if (Object.keys(permissions).findIndex((permission) => permission === menuItem.id) > -1) {
                            if (!menuItem.childrens) {
                                contents.push(menuItem);
                            } else {
                                menuItem.childrens.filter((child) => permissions[menuItem.id][child.permission]);
                                if (menuItem.childrens.length) {
                                    contents.push(menuItem);
                                }
                            }
                        }
                    }
                }

                finalMenu.push({
                    group: group.group,
                    contents
                })
            }
            return finalMenu
        } else {
            return [];
        }
    }, [user, permissions]);

    return (
        <>
            <div className="hk-menu">
                {/* Brand */}
                <SidebarHeader />
                {/* Main Menu */}
                <SimpleBar className="nicescroll-bar">
                    <div className="menu-content-wrap">
                        {SidebarMenuList.map((routes, index) => {
                            if (!routes) return null;
                            return (
                                <React.Fragment key={index}>
                                    <div className="menu-group" >
                                        {routes.group && <div className="nav-header" >
                                            <span>{routes.group}</span>
                                        </div>}
                                        {routes.contents.map((menus, idx) => (
                                            <Nav bsPrefix="navbar-nav" className="flex-column" key={idx}>
                                                <Nav.Item className={classNames({ "active": useRouteMatch(menus.path) })}  >
                                                    {
                                                        menus.childrens
                                                            ?
                                                            <>
                                                                <Nav.Link data-bs-toggle="collapse" data-bs-target={`#${menus.id}`} aria-expanded={activeMenu === menus.name ? "true" : "false"} onClick={() => setActiveMenu(menus.name)} >
                                                                    <span className={classNames("nav-icon-wrap", { "position-relative": menus.iconBadge })}>
                                                                        {menus.iconBadge && menus.iconBadge}
                                                                        <span className="svg-icon">
                                                                            {menus.icon}
                                                                        </span>
                                                                    </span>
                                                                    <span className={classNames("nav-link-text", { "position-relative": menus.badgeIndicator })} >
                                                                        {menus.name}
                                                                        {menus.badgeIndicator && menus.badgeIndicator}
                                                                    </span>
                                                                    {menus.badge && menus.badge}
                                                                </Nav.Link>

                                                                {/* <Collapse in={open}> */}
                                                                <ul id={menus.id} className={classNames("nav flex-column nav-children", { "collapse": activeMenu !== menus.name })}>
                                                                    <li className="nav-item">
                                                                        <ul className="nav flex-column">
                                                                            {menus.childrens.map((subMenu, indx) => (
                                                                                subMenu.childrens
                                                                                    ?
                                                                                    <li className="nav-item" key={indx} >
                                                                                        <Nav.Link as={NavLink} to={subMenu.path} className="nav-link" data-bs-toggle="collapse" data-bs-target={`#${subMenu.id}`} aria-expanded={activeSubMenu === subMenu.name ? "true" : "false"} onClick={() => setActiveSubMenu(subMenu.name)}>
                                                                                            <span className="nav-link-text">
                                                                                                {subMenu.name}
                                                                                            </span>
                                                                                        </Nav.Link>

                                                                                        {subMenu.childrens.map((childrenPath, i) => (
                                                                                            <ul id={subMenu.id} className={classNames("nav flex-column nav-children", { "collapse": activeSubMenu !== subMenu.name })} key={i}>
                                                                                                <li className="nav-item">
                                                                                                    <ul className="nav flex-column">
                                                                                                        <li className="nav-item">
                                                                                                            <Nav.Link as={NavLink} to={childrenPath.path} onClick={handleClick}>
                                                                                                                <span className="nav-link-text">
                                                                                                                    {childrenPath.name}
                                                                                                                </span>
                                                                                                            </Nav.Link>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </ul>
                                                                                        ))}

                                                                                    </li>
                                                                                    :
                                                                                    <li className="nav-item" key={indx}>
                                                                                        <Nav.Link as={NavLink} to={subMenu.path} onClick={handleClick}>
                                                                                            <span className="nav-link-text">
                                                                                                {subMenu.name}
                                                                                            </span>
                                                                                        </Nav.Link>
                                                                                    </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                                {/* </Collapse> */}

                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    (routes.group === "Documentation")
                                                                        ?
                                                                        <a className="nav-link" href={menus.path} target="_blank" rel="noreferrer" >
                                                                            <span className="nav-icon-wrap">
                                                                                <span className="svg-icon">
                                                                                    {menus.icon}
                                                                                </span>
                                                                            </span>
                                                                            <span className="nav-link-text">{menus.name}</span>
                                                                            {menus.badge && menus.badge}
                                                                        </a>
                                                                        :
                                                                        <Nav.Link as={NavLink} exact={true} activeClassName="active" to={menus.path} onClick={() => handleClick(menus.name)} >
                                                                            <span className="nav-icon-wrap">
                                                                                <span className="svg-icon">
                                                                                    {menus.icon}
                                                                                </span>
                                                                            </span>
                                                                            <span className="nav-link-text">{menus.name}</span>
                                                                            {menus.badge && menus.badge}
                                                                        </Nav.Link>
                                                                }
                                                            </>
                                                    }
                                                </Nav.Item>
                                            </Nav>
                                        ))}
                                    </div>
                                    <div className="menu-gap" />
                                </React.Fragment>
                            )
                        })}

                        {/* <Card bg="orange-light-5" className="callout card-flush  text-center w-220p mx-auto">
                            <Card.Body>
                                <h5 className="h5">Quickly Build Applications</h5>
                                <Card.Text className="p-sm">Exclusively for webapps Based on Bootstrap</Card.Text>
                                <Button variant="primary" href="https://nubra-ui-react.netlify.app/" target="_blank" rel="noreferrer" className="btn-block">Go Nubra-UI</Button>
                            </Card.Body>
                        </Card> */}
                    </div>
                </SimpleBar>
                {/* /Main Menu */}
            </div >
            <div onClick={backDropToggle} className="hk-menu-backdrop" />
        </>
    )
}

const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(Sidebar);