import React from 'react';
import { Helmet } from 'react-helmet-async';

import GroupsHeader from './list/GroupsHeader';
import GroupsBody from './list/GroupsBody';

const Users = () => {
    return (
        <>
            <Helmet>
                <title> Group List | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <GroupsHeader />
                        <GroupsBody/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users;
