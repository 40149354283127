import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleHeader from '../../SimpleHeader';
import PageFooter from '../../../../layout/Footer/PageFooter';
import Body from './Body';
import { useAuthContext } from '../../../../auth/hooks';

const LoginSimple = (props) => {
    const { authenticated } = useAuthContext();
    const history = useHistory();

    useEffect(() => {
        if (authenticated) {
            history.push('/dashboard');
        }
    }, [authenticated, history]);

    return (
        <div>
            <SimpleHeader />
            <div className="hk-pg-wrapper">
                <Body {...props} />
                <PageFooter />
            </div>
        </div>

    )
}

export default LoginSimple
