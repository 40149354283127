import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { Edit, Trash } from 'react-feather';
import HkBadge from '../../components/@hk-badge/@hk-badge';
import moment from 'moment';

const statusType = {
    active: 'success',
    inactive: 'purple',
    banned: 'danger',
    suspend: 'secondary',
    pending: 'warning'
}

//Custom Action Container
const actionFormater = (cell, permission) => {
    return (
        <div className="d-flex align-items-center" >
            <div className="d-flex">
                {
                    permission.PUT &&
                    <Button variant="flush-dark" as={Link} to={`/user/${cell.id}/edit`} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                        <span className="icon">
                            <span className="feather-icon">
                                <Edit />
                            </span>
                        </span>
                    </Button>
                }
                {
                    permission.DELETE &&
                    <Button onClick={() => cell.handleDelete({ id: cell.id, name: cell.name })} variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete">
                        <span className="icon">
                            <span className="feather-icon">
                                <Trash />
                            </span>
                        </span>
                    </Button>
                }
            </div>
        </div>
    )
}

// Group formater
const MyGroup = (cell) => {
    if (!cell.length) return null;
    return (
        <>
            {
                cell.slice(0, 3).map((permission, index) => (
                    <HkBadge bg="light" size="md" text="dark" className="ms-1" key={index}>{permission.groupName}</HkBadge>
                ))
            }
            {
                (cell.length > 3) &&
                <HkBadge bg="light" size="md" text="dark" className="ms-1">+{cell.length - 3}</HkBadge>
            }
        </>
    )
}


// provier enabled
const EnabledFormater = (cell) => {
    return <Form.Check type="checkbox" checked={cell.checked} onChange={(e) => cell.handleCheck(e.target.checked, cell.id, cell.percentCategories)} />
}

const provierActionFormater = (cell) => {
    return (
        <div className="d-flex align-items-center" >
            <div className="d-flex">
                {
                    cell.enabled ?
                        <Button variant="flush-dark" onClick={() => cell.editHandle(cell.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                            <span className="icon">
                                <span className="feather-icon">
                                    <Edit />
                                </span>
                            </span>
                        </Button> :
                        <Button variant="flush-dark" disabled className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                            <span className="icon">
                                <span className="feather-icon">
                                    <Edit />
                                </span>
                            </span>
                        </Button>
                }
            </div>
        </div>
    )
}

const GGRFormater = (cell) => {
    return (
        <Form.Control value={cell} onChange={e => false} />
    )
}

const dateFormater = (cell) => {
    return moment(cell).format('MM/DD/yyyy hh:mm:ss')
}

const statusBadge = (cell) => {
    if (!cell) return null;
    return (
        <HkBadge bg={statusType[cell]} className='text-capitalize'>{cell}</HkBadge>
    )
}

export const columns = (permission) => {
    const data = [
        {
            accessor: "id",
            title: "Id"
        },
        {
            accessor: "name",
            title: "Name",
            sort: true,
        },
        {
            accessor: "firstName",
            title: "First Name",
            sort: true,
        },
        {
            accessor: "lastName",
            title: "Last Name",
            sort: true,
        },
        {
            accessor: "email",
            title: "Email",
            sort: true,
        },
        {
            accessor: "status",
            title: "Status",
            cellFormatter: statusBadge,
            sort: true,
        },
        {
            accessor: "operatorId",
            title: "Operator Id",
            sort: true,
        },
        {
            accessor: "organization",
            title: "Organization",
            sort: true,
        },
        {
            accessor: "address",
            title: "Address",
            sort: true,
        },
        {
            accessor: "websiteUrl",
            title: "Website Url",
            sort: true,
        },
        {
            accessor: "walletApiUrl",
            title: "Wallet ApiUrl",
            sort: true,
        },
        {
            accessor: "memberOf",
            title: "Gorups",
            cellFormatter: MyGroup,
        },
        {
            accessor: "createdAt",
            title: "Created Date",
            sort: true,
            cellFormatter: dateFormater,
        },
        {
            accessor: "updatedAt",
            title: "Last Edited",
            sort: true,
            cellFormatter: dateFormater,
        }
    ];

    if (permission.PUT || permission.DELETE) {
        data.push(
            {
                accessor: "actions",
                title: "Actions",
                cellFormatter: (cell) => actionFormater(cell, permission),
            },
        )
        if (permission.PUT) {
            data[0].cellFormatter = (cell) => <Link to={`/user/${cell}/edit`}>{cell}</Link>
        }
    }
    return data;
}


export const providerColumns = [
    {
        accessor: "id",
        title: "Id",
    },
    {
        accessor: "name",
        title: "Name",
        sort: true,
    },
    {
        accessor: "checkBox",
        title: "Enabled",
        cellFormatter: EnabledFormater,
    },
    {
        accessor: "actions",
        title: "Actions",
        cellFormatter: provierActionFormater,
    },
]

export const modalColumns = [
    {
        accessor: "categoryName",
        title: "Category Name",
    },
    {
        accessor: "GGRCategory",
        title: "GGR Category",
    },
    {
        accessor: "baseGGR",
        title: "Base GGR",
    },
    {
        accessor: "GGRMarkup",
        title: "BBR Markup",
        cellFormatter: GGRFormater,
    },
]

export const providerData = [
    {
        id: '6505a62bb23c8b1baecc6143',
        name: 'Evolution Live Casino',
        enabled: true
    },
    {
        id: '6505a62bb23c8b1baecc6144',
        name: 'Bsoft',
        enabled: false
    },
    {
        id: '6505a62bb23c8b1baecc6145',
        name: 'BGaming',
        enabled: true
    },
    {
        id: '6505a62bb23c8b1baecc6146',
        name: 'TV Games',
        enabled: false
    }
]

export const modalData = [
    {
        categoryName: '6505a62bb23c8b1baecc6147',
        GGRCategory: 'Basic',
        baseGGR: '10%',
        GGRMarkup: 1
    },
    {
        categoryName: '6505a62bb23c8b1baecc6148',
        GGRCategory: 'Premium Blackjeck',
        baseGGR: '12%',
        GGRMarkup: 2
    },
    {
        categoryName: '6505a62bb23c8b1baecc6149',
        GGRCategory: 'V.I.P Roulette',
        baseGGR: '11%',
        GGRMarkup: 4
    },
]