import React from 'react'

const FallbackLoading = () => {
    return (
        <>

            <div className='fallback-spinner'>
                <div className="fallback-main" style={{ transition: ".6s transform ease" }}>
                    <div className='loading'>
                        <div className='effect-1 effects'></div>
                        <div className='effect-2 effects'></div>
                        <div className='effect-3 effects'></div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FallbackLoading
