import { Button, Modal, Spinner } from 'react-bootstrap';

const DeleteModal = ({ show, close, isLoading, deleteGroupData, deletedCount, selectedIds, deleteAction }) => {
    return (
        <Modal centered show={show} onHide={close}>
            <div className='position-relative'>
                {
                    isLoading &&
                    (<div className='position-absolute w-100 h-100 top-0 right-0 d-flex align-items-center justify-content-center flex-column' style={{ left: 0, backgroundColor: '#0000004a' }}>
                        <Spinner as="span" animation="border" size="lg" role="status" variant="primary" />
                        {
                            deleteGroupData.isMultiple &&
                            <div className='mt-1' style={{ color: '#000' }}>{`(${deletedCount}/${selectedIds.length})`}</div>
                        }
                    </div>)
                }
                <Modal.Header>
                    <Modal.Title>Delete Group</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ minHeight: 100 }}>
                    {
                        deleteGroupData.isMultiple ?
                            `Are you sure you want to delete ${selectedIds.length} Groups?` :
                            `Are you sure you want to delete ${deleteGroupData.name} Group?`
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" disabled={isLoading} onClick={close}>
                        No
                    </Button>
                    <Button variant="primary" disabled={isLoading} onClick={() => deleteAction(deleteGroupData.isMultiple)}>
                        Yes
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    )
}

export default DeleteModal;