import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ClassicRoutes from './routes/Classic';
import AuthRoutes from './routes/AuthRoutes';
import ScrollToTop from './utils/ScrollToTop';
import "bootstrap/js/src/collapse";
import 'react-toastify/dist/ReactToastify.css';

import PriacyPolicy from './views/Privacy/PrivacyPolicy';

// auth
import { AuthProvider, AuthConsumer } from './auth/context';
import { AuthGuard } from './auth/guard';
import TermsOfService from './views/Privacy/TermsOfService';

function App() {
  return (
    <>
      <AuthProvider >
        <AuthConsumer>
          <BrowserRouter>
            <ScrollToTop>
              <Switch>
                {/* Auth */}
                <Route path="/priacy-policy" exact render={PriacyPolicy} />
                <Route path="/terms-of-service" exact render={TermsOfService} />
                <Route path="/auth" render={(props) => <AuthRoutes {...props} />} />
                {/* Layouts */}
                <AuthGuard>
                  <Route path="/" render={(props) => <ClassicRoutes {...props} />} />
                </AuthGuard>
              </Switch>
            </ScrollToTop>
          </BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </AuthConsumer>
      </AuthProvider>
    </>
  );
}

export default App;
