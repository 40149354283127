import { Button } from 'react-bootstrap';
import { Edit, Trash } from 'react-feather';
import HkBadge from '../../components/@hk-badge/@hk-badge';
import moment from 'moment';

//Custom Action Container
const actionFormater = (cell, permission) => {
    return (
        <div className="d-flex align-items-center" >
            <div className="d-flex">
                {
                    permission.PUT &&
                    <Button variant="flush-dark" onClick={() => cell.handleUpdate(cell.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                        <span className="icon">
                            <span className="feather-icon">
                                <Edit />
                            </span>
                        </span>
                    </Button>
                }
                {
                    permission.DELETE &&
                    <Button onClick={() => cell.handleDelete({ id: cell.id, name: cell.name })} variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete">
                        <span className="icon">
                            <span className="feather-icon">
                                <Trash />
                            </span>
                        </span>
                    </Button>
                }
            </div>
        </div>
    )
}

const dateFormater = (cell) => {
    return moment(cell).format('MM/DD/yyyy hh:mm:ss')
}

export const columns = (permission) => {
    const data = [
        {
            accessor: "id",
            title: "Id",
        },
        {
            accessor: "title",
            title: "Title",
            sort: true,
        },
        {
            accessor: "message",
            title: "Message",
            sort: true,
        },
        {
            accessor: "meta",
            title: "Meta",
            sort: true,
        },
        {
            accessor: "label",
            title: "Label",
            sort: true,
        },
        {
            accessor: "level",
            title: "Level",
            cellFormatter: (cell) => <HkBadge bg={cell} size='sm'>{cell}</HkBadge>,
        },
        {
            accessor: "user",
            title: "User",
            cellFormatter: (cell) => cell.name,
        },
        {
            accessor: "createdAt",
            title: "Created Date",
            sort: true,
            cellFormatter: dateFormater,
        },
        {
            accessor: "updatedAt",
            title: "Last Edited",
            sort: true,
            cellFormatter: dateFormater,
        }
    ];

    if (permission.PUT || permission.DELETE) {
        data.push(
            {
                accessor: "actions",
                title: "Actions",
                cellFormatter: (cell) => actionFormater(cell, permission),
            },
        )
    }
    return data;
}