import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, } from 'react-bootstrap';
import HkBadge from '../../components/@hk-badge/@hk-badge';
import HkDataTable from '../../components/@hk-data-table';
import { columns } from './TableData';

import { getNotifications } from '../../utils/apis';
import { toast } from 'react-toastify';

const CustomerTable = ({ parentRef }) => {
    const [data, setdata] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    const loadData = async () => {
        try {
            setLoading(true);
            const alerts = await getNotifications('where={"type":"alert"}');
            setCount(alerts.length)
            setdata(alerts)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    useEffect(() => {
        loadData();
        parentRef.current = loadData;
        // eslint-disable-next-line
    }, []);


    return (
        <Card className="card-border mb-0 h-100">
            <Card.Header className="card-header-action">
                <h6>Last System Alerts
                    {
                        count ?
                            <HkBadge bg="light" size="sm" text="dark" className="ms-1">{count}</HkBadge>
                            : null
                    }
                </h6>
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col sm={9} >
                        {/* <div className="d-xxl-flex d-none align-items-center">
                            <ButtonGroup size="sm">
                                <Button variant="outline-light">View all</Button>
                                <Button variant="outline-light">Rollbacks</Button>
                                <Button variant="outline-light">Errored</Button>
                                <Button variant="outline-light">Service Error</Button>
                            </ButtonGroup>
                        </div> */}
                    </Col>
                    <Col sm={3} className="text-right d-flex justify-content-end">
                        <Form.Control
                            size='sm'
                            type='search'
                            placeholder='Search'
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                        <Button variant="outline-light" size="sm" className="ms-3">
                            <span><span className="icon">
                                <i className="bi bi-filter"></i>
                            </span>
                                <span className="btn-text">Filters</span></span>
                        </Button>
                    </Col>
                </Row>
                <div className="contact-list-view">
                    <HkDataTable
                        column={columns}
                        rowData={data}
                        rowsPerPage={4}
                        isLoading={loading}
                        searchQuery={searchTerm}
                        paginatorSize="sm"
                        classes="display pb-30"
                        responsive
                    />
                </div>
            </Card.Body>
        </Card>
    )
}

export default CustomerTable
