import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Nav, Tab, Row, Container, Card, Spinner, TabContainer, Table } from 'react-bootstrap';
import { Edit } from 'react-feather';
import HkBadge from '../../../components/@hk-badge/@hk-badge';
import GroupsModal from './GroupsModal';
import ProviderModal from './ProviderModal';

import { createUser, getGroups, getMarkup, getProviders, getUser, updateUser, createMarkup, deleteMarkup } from '../../../utils/apis';

const EditUserBody = ({ id }) => {

    const history = useHistory();

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        name: '',
        password: '',
        organization: '',
        address: '',
        operatorId: '',
        status: 'active',
        websiteUrl: '',
        walletApiUrl: '',
        isSuperAdmin: false,
        isOperator: false,
        memberOf: []
    });
    const [confirm, setConfirm] = useState('');
    const [groups, setGroups] = useState([]);
    const [groupModal, setGroupModal] = useState(false);
    const [providerModal, setProviderModal] = useState(false);
    const [memberError, setMemberError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [providers, setProviders] = useState([]);
    const [providerLoading, setProviderLoading] = useState(false);
    const [markups, setMarkups] = useState([]);
    const [percentData, setPercentData] = useState([]);

    const groupList = useMemo(() => {
        return groups.map((e) => {
            if (user.memberOf.findIndex((id) => id === e.id) !== -1) {
                return { ...e, check: true }
            }
            return { ...e, check: false };
        })
    }, [user.memberOf, groups]);

    const updateUserData = (data) => {
        setUser((pre) => ({ ...pre, ...data }));
    }

    const initialUserData = () => {
        setUser({
            firstName: '',
            lastName: '',
            email: '',
            name: '',
            password: '',
            organization: '',
            address: '',
            operatorId: '',
            status: 'active',
            websiteUrl: '',
            walletApiUrl: '',
            isSuperAdmin: false,
            isOperator: false,
            memberOf: []
        });
        setConfirm('');
        setValidated(false);
    };

    const editProvider = async (providerId) => {
        try {
            const markupData = markups.filter((e) => e.provider.id === providerId);
            setPercentData(markupData);
            setProviderModal(true);
        } catch (error) {
            toast.error(error.message);
        }
    }

    const getGroupsAction = async (id) => {
        try {
            const groupData = await getGroups();
            setGroups(groupData)
        } catch (error) {
            toast.error(error.message);
        }
    }

    const getUserActioin = async (id) => {
        try {
            const userData = await getUser(id);
            setUser((pre) => ({ ...pre, ...userData, memberOf: userData.memberOf.map((e) => e.id) }));
        } catch (error) {
            toast.error(error.message);
        }
    }

    const onDataSubmit = async (e) => {
        try {
            e.preventDefault();

            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            }

            if (!user.memberOf.length) {
                setMemberError(true);
            } else {
                setMemberError(false);
            }

            setValidated(true);

            if (user.password !== confirm && !id) {
                return;
            }

            if (user.firstName === '' ||
                user.lastName === '' ||
                user.name === '' ||
                user.email === '' ||
                user.organization === '' ||
                user.address === '' ||
                user.operatorId === '' ||
                user.status === '' ||
                user.memberOf.length === 0
            ) {
                return;
            }

            if (!id && (
                user.password === '' ||
                confirm === ''
            )) {
                return;
            }

            setLoading(true);
            if (id) {
                delete user.password;
                await updateUser(user, id);
            } else {
                await createUser(user);
                initialUserData();
            }
            toast.success('Success');
            setLoading(false);
            history.push('/user/list');
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    const handleMarkup = async (checked, providerId, percentCategories) => {
        try {
            if (checked) {
                if (percentCategories.length) {
                    setProviderLoading(true);
                    for (const percent of percentCategories) {
                        const newMarkup = await createMarkup({
                            user: id,
                            provider: providerId,
                            percentCategory: percent.id,
                            percent: 10
                        });
                        setMarkups((pre) => [...pre, newMarkup]);
                    }
                    setProviders((pre) => pre.map((e) => {
                        if (e.id === providerId) e.checked = true;
                        return e;
                    }));
                    setProviderLoading(false)
                } else {
                    return toast.info("The provider doesn't have percent categories.")
                }
            } else {
                const removeItems = [];
                for (const markup of markups) {
                    if (markup.provider.id === providerId) {
                        removeItems.push(markup.id)
                    }
                }

                setProviderLoading(true);
                for (const id of removeItems) {
                    await deleteMarkup(id);
                }
                setMarkups((pre) => pre.filter((markup) => markup.provider.id !== providerId));
                setProviders((pre) => pre.filter((e) => {
                    if (e.id === providerId) e.checked = false;
                    return e;
                }));
                setProviderLoading(false);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    const loadMarkupAction = async (userId) => {
        try {
            setProviderLoading(true)
            const provierData = await getProviders();
            const markupData = await getMarkup(`where={"user": "${userId}"}`);
            setMarkups(markupData);
            const providers = provierData.map((provider) => {
                const index = markupData.findIndex((mark) => mark.provider.id === provider.id);
                return {
                    id: provider.id,
                    name: provider.name,
                    checked: index > -1,
                    percentCategories: provider.percentCategories,
                }
            })
            setProviders(providers);
            setProviderLoading(false);
        } catch (error) {
            toast.error(error.message);
            setProviderLoading(false);
        }
    }


    useEffect(() => {
        getGroupsAction();
        if (id) {
            getUserActioin(id);
            loadMarkupAction(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            <div className="contact-body">
                <TabContainer defaultActiveKey="link-1">
                    <Nav variant="tabs" className="nav-light nav-line page-tab-header">
                        <Nav.Item>
                            <Nav.Link eventKey="link-1">
                                <span className="nav-link-text">{`${id ? 'Edit' : 'Create'} A User`}</span>
                            </Nav.Link>
                        </Nav.Item>
                        {
                            id &&
                            <Nav.Item>
                                <Nav.Link eventKey="link-2">
                                    <span className="nav-link-text">Manage Providers</span>
                                </Nav.Link>
                            </Nav.Item>
                        }
                    </Nav>
                    <Tab.Content className='page-tab-body'>
                        <Tab.Pane eventKey="link-1" className='h-100'>
                            <SimpleBar className="nicescroll-bar">
                                <div className="contact-list-view">
                                    <Container>
                                        <Card>
                                            <Card.Body>
                                                <Form noValidate validated={validated} onSubmit={onDataSubmit}>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} controlId="formGridFirstName">
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control type="text" placeholder="First Name" required value={user.firstName} onChange={(e) => updateUserData({ firstName: e.target.value })} />
                                                            <Form.Control.Feedback type="invalid">
                                                                First Name is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group as={Col} controlId="formGridLastName">
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Last Name" required value={user.lastName} onChange={(e) => updateUserData({ lastName: e.target.value })} />
                                                            <Form.Control.Feedback type="invalid">
                                                                Last Name is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} controlId="formGridName">
                                                            <Form.Label>Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Name" required value={user.name} onChange={(e) => updateUserData({ name: e.target.value })} />
                                                            <Form.Control.Feedback type="invalid">
                                                                Name is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group as={Col} controlId="formGridEmail">
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control type="email" placeholder="Email" required value={user.email} onChange={(e) => updateUserData({ email: e.target.value })} />
                                                            <Form.Control.Feedback type="invalid">
                                                                Email is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                    {
                                                        !id &&
                                                        <Row className="mb-3">
                                                            <Form.Group as={Col} controlId="formGridPassword">
                                                                <Form.Label>Password</Form.Label>
                                                                <Form.Control type="password" placeholder="Password" value={user.password} required onChange={(e) => updateUserData({ password: e.target.value })} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Password is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} controlId="formGridPasswordConfirm">
                                                                <Form.Label>Confirm</Form.Label>
                                                                <Form.Control type="password" placeholder="Password Confirm" value={confirm} required onChange={(e) => setConfirm(e.target.value)} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Confirm password is required
                                                                </Form.Control.Feedback>
                                                                {
                                                                    user.password !== confirm &&
                                                                    <div className="invalid-feedback d-block">Password doesn't match</div>
                                                                }
                                                            </Form.Group>
                                                        </Row>
                                                    }

                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} controlId="formGridOperatorId">
                                                            <Form.Label>Operation Id</Form.Label>
                                                            <Form.Control type="text" placeholder="Operator Id" required value={user.operatorId} onChange={(e) => updateUserData({ operatorId: e.target.value })} />
                                                            <Form.Control.Feedback type="invalid">
                                                                Operation Id is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group as={Col}>
                                                            <Form.Label>Status</Form.Label>
                                                            <Form.Select value={user.status} onChange={(e) => updateUserData({ status: e.target.value })}>
                                                                <option disabled>Select</option>
                                                                <option value={'active'}>Active</option>
                                                                <option value={'inactive'}>Inactive</option>
                                                                <option value={'banned'}>Banned</option>
                                                                <option value={'suspend'}>Suspend</option>
                                                                <option value={'pending'}>Pending</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} controlId="formGridOrganization">
                                                            <Form.Label>Organization</Form.Label>
                                                            <Form.Control type="text" placeholder="Organiztion" required value={user.organization} onChange={(e) => updateUserData({ organization: e.target.value })} />
                                                            <Form.Control.Feedback type="invalid">
                                                                Organization is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group as={Col} controlId="formGridAddress">
                                                            <Form.Label>Address</Form.Label>
                                                            <Form.Control type="text" placeholder="Address" required value={user.address} onChange={(e) => updateUserData({ address: e.target.value })} />
                                                            <Form.Control.Feedback type="invalid">
                                                                Address is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} controlId="formGridWebsite">
                                                            <Form.Label>Website Url</Form.Label>
                                                            <Form.Control type="text" placeholder="https://xxxx.xxx" required value={user.websiteUrl} onChange={(e) => updateUserData({ websiteUrl: e.target.value })} />
                                                            <Form.Control.Feedback type="invalid">
                                                                Website Url is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group as={Col} controlId="formGridWalletApi">
                                                            <Form.Label>Wallet API Url</Form.Label>
                                                            <Form.Control type="text" placeholder="https://api.xxxx.xxx" required value={user.walletApiUrl} onChange={(e) => updateUserData({ walletApiUrl: e.target.value })} />
                                                            <Form.Control.Feedback type="invalid">
                                                                Wallet API Url is required
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} controlId="formGridWalletApi">
                                                            <Form.Label>Groups</Form.Label>
                                                            <div onClick={() => setGroupModal(true)} className='form-control pointer' style={{ minHeight: 38, borderColor: memberError ? '#FF0000' : '#d8d8d8' }}>
                                                                {
                                                                    user.memberOf.map((id) => {
                                                                        const idx = groups.findIndex((e) => e.id === id);
                                                                        if (idx !== -1) {
                                                                            return <HkBadge key={id} bg="light" size="md" text="dark" className="me-1">{groups[idx].groupName}</HkBadge>
                                                                        }
                                                                        return null;
                                                                    })
                                                                }
                                                            </div>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} id="formGridCheckbox">
                                                            <Form.Check type="checkbox" label="Is SuperAdmin ?" checked={user.isSuperAdmin} onChange={(e) => updateUserData({ isSuperAdmin: e.target.checked })} />
                                                        </Form.Group>
                                                        <Form.Group as={Col} id="formGridCheckbox1">
                                                            <Form.Check type="checkbox" label="Is Operator ?" checked={user.isOperator} onChange={(e) => updateUserData({ isOperator: e.target.checked })} />
                                                        </Form.Group>
                                                    </Row>
                                                    {
                                                        loading ?
                                                            <Button variant="primary" className='btn btn-primary' disabled>
                                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                Loading...
                                                            </Button>
                                                            : <Button type='submit' submit="true">{id ? 'Update' : 'Create'}</Button>
                                                    }
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Container>
                                </div>
                            </SimpleBar >
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-2" className='h-100'>
                            {
                                id &&
                                <SimpleBar className="nicescroll-bar">
                                    <div className="contact-list-view">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Name</th>
                                                    <th>Enabled</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (() => {
                                                        if (providerLoading) {
                                                            return (
                                                                <tr>
                                                                    <td colSpan={4}>
                                                                        <p className='text-center'>Loading...</p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        } else {
                                                            if (!providers.length) {
                                                                return (
                                                                    <tr>
                                                                        <td colSpan={4}>
                                                                            <p className='text-center'>No data</p>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            } else {
                                                                return providers.map((row) => (
                                                                    <tr key={row.id}>
                                                                        <td>{row.id}</td>
                                                                        <td>{row.name}</td>
                                                                        <td>
                                                                            <Form.Check type="checkbox" checked={row.checked} onChange={(e) => handleMarkup(e.target.checked, row.id, row.percentCategories)} />
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-center" >
                                                                                <div className="d-flex">
                                                                                    {
                                                                                        row.checked ?
                                                                                            <Button variant="flush-dark" onClick={() => editProvider(row.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                                                                                                <span className="icon">
                                                                                                    <span className="feather-icon">
                                                                                                        <Edit />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </Button> :
                                                                                            <Button variant="flush-dark" disabled className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                                                                                                <span className="icon">
                                                                                                    <span className="feather-icon">
                                                                                                        <Edit />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </Button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        }
                                                    })()

                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </SimpleBar>
                            }
                        </Tab.Pane>
                    </Tab.Content>
                </TabContainer>
            </div>
            <GroupsModal show={groupModal} onClose={() => setGroupModal(!groupModal)} data={groupList} setData={updateUserData} />
            {
                providerModal &&
                <ProviderModal show={providerModal} onClose={() => setProviderModal(!providerModal)} data={percentData} />
            }
        </>
    )
}

export default EditUserBody;