import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Nav, Row, Container, Card, Spinner } from 'react-bootstrap';

import { createProvider, getProvider, updateProvider } from '../../../utils/apis';

const EditProviderBody = ({ id }) => {

    const history = useHistory();

    const [provider, setProvider] = useState({
        name: '',
        username: '',
        password: '',
        apiKey: ''
    });
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const updateProviderData = (data) => {
        setProvider((pre) => ({ ...pre, ...data }));
    }

    const initialProviderData = () => {
        setProvider({
            name: '',
            username: '',
            password: '',
            apiKey: ''
        });
        setValidated(false);
    };

    const getProviderActioin = async (id) => {
        try {
            const providerData = await getProvider(id);
            setProvider(providerData);
        } catch (error) {
            toast.error(error.message);
        }
    }

    const onDataSubmit = async (e) => {
        try {
            e.preventDefault();

            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            }

            setValidated(true);

            if (provider.name === '' ||
                provider.username === '' ||
                provider.password === '' ||
                provider.apiKey === ''
            ) {
                return;
            }

            setLoading(true);
            if (id) {
                await updateProvider(provider, id);
            } else {
                await createProvider(provider);
                initialProviderData();
            }
            toast.success('Success');
            setLoading(false);
            history.push('/provider/list');
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (id) {
            getProviderActioin(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div className="contact-body">
            <Nav variant="tabs" className="nav-light nav-line page-tab-header">
                <Nav.Item>
                    <Nav.Link eventKey="-" className='active' >
                        <span className="nav-link-text">{`${id ? 'Edit' : 'Create'} A Provider`}</span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <div className='page-tab-body mt-1'>
                <SimpleBar className="nicescroll-bar">
                    <div className="contact-list-view">
                        <Container>
                            <Card>
                                <Card.Body>
                                    <Form noValidate validated={validated} onSubmit={onDataSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridName">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" placeholder="Name" required value={provider.name} onChange={(e) => updateProviderData({ name: e.target.value })} />
                                                <Form.Control.Feedback type="invalid">
                                                    Name is required
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridUsername">
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control type="text" placeholder="Username" required value={provider.username} onChange={(e) => updateProviderData({ username: e.target.value })} />
                                                <Form.Control.Feedback type="invalid">
                                                    Username is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridApiKey">
                                                <Form.Label>API Key</Form.Label>
                                                <Form.Control type="text" placeholder="API Key" required value={provider.apiKey} onChange={(e) => updateProviderData({ apiKey: e.target.value })} />
                                                <Form.Control.Feedback type="invalid">
                                                    API Key is required
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="text" placeholder="Password" value={provider.password} required onChange={(e) => updateProviderData({ password: e.target.value })} />
                                                <Form.Control.Feedback type="invalid">
                                                    Password is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        {
                                            loading ?
                                                <Button variant="primary" className='btn btn-primary' disabled>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    Loading...
                                                </Button>
                                                : <Button type='submit' submit="true">{id ? 'Update' : 'Create'}</Button>
                                        }
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Container>
                    </div>
                </SimpleBar >
            </div>
        </div>
    )
}

export default EditProviderBody;