import React from 'react';
import { Helmet } from 'react-helmet-async';

import LogsHeader from './list/LogsHeader';
import LogsBody from './list/LogsBody';

const Users = () => {
    return (
        <>
            <Helmet>
                <title> Logs | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <LogsHeader />
                        <LogsBody/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users;
