import { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { Nav } from 'react-bootstrap';

import { getTransactions } from '../../../utils/apis';
import { roundGroupBy } from '../../../utils/utils';
import { toast } from 'react-toastify';
import HkBadge from '../../../components/@hk-badge/@hk-badge';
import HkDataTable from '../../../components/@hk-data-table';
import { otherRoundColumns } from '../TableData';

const rowsPerPage = 50;

const RoundsOtherBody = ({ filter }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const loadData = async () => {
        try {
            setLoading(true);
            const transactions = await getTransactions(`where={"status":"${filter === 'open'}"}`);
            const rounds = roundGroupBy(transactions, 'round', 'id');
            setData(Object.keys(rounds).map((k) => rounds[k][0]));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="contact-body">
            <Nav variant="tabs" className="nav-light nav-line">
                <Nav.Item>
                    <Nav.Link eventKey="overview" className='active' >
                        <span className="nav-link-text text-capitalize">
                            {`${filter} Rounds`}
                            {
                                !!data.length &&
                                <HkBadge bg="light" size="sm" text="dark" className="ms-1">{data.length}</HkBadge>
                            }
                        </span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <div className='page-tab-body mt-1'>
                <SimpleBar className="nicescroll-bar">
                    <div className="contact-list-view">
                        <HkDataTable
                            column={otherRoundColumns}
                            rowData={data}
                            isLoading={loading}
                            rowsPerPage={rowsPerPage}
                            classes="nowrap w-100 mb-5"
                            responsive
                        />
                    </div>
                </SimpleBar>
            </div>
        </div>
    )
}

export default RoundsOtherBody;