import React, { useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Eye, EyeOff } from 'react-feather';
import { Link } from 'react-router-dom';
// auth
import { useAuthContext } from '../../../../auth/hooks';
//Image
import logoutImg from '../../../../assets/dist/img/pot-2.svg';

const Body = (props) => {
    const { login } = useAuthContext();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true)
            await login(email, password);
            setLoading(false)
            props.history.push("/");
        } catch (error) {
            setError(error.message)
            setLoading(false)
        }
    }

    return (
        <div className="hk-pg-body">
            <Container>
                <Row>
                    <Col xl={7} lg={6} className="d-lg-block d-none v-separator separator-sm">
                        <div className="auth-content py-md-0 py-8">
                            <Row>
                                <Col xxl={9} xl={8} lg={11} className="text-center mx-auto">
                                    <img src={logoutImg} className="img-fluid w-sm-50 w-70 mb-3" alt="login" />
                                    <h3 className="mb-2">Instantly connect to over <br /> 100 game studios</h3>
                                    <p className="w-xxl-85 w-100 mx-auto">All-in-one API covering access to casino games, live tables, bingo and lottery compatible products.</p>
                                    <Button size="sm" variant="primary" className="btn-uppercase mt-4">Join Now</Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xl={5} lg={6} md={7} sm={10} className="position-relative mx-auto">
                        <div className="auth-content py-md-0 py-8">
                            <Form className="w-100" onSubmit={e => handleSubmit(e)} >
                                <Row>
                                    <Col lg={10} className="mx-auto">
                                        <h4 className="mb-4">Sign in to your account</h4>
                                        <Row className="gx-3">
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <div className="form-label-group">
                                                    <Form.Label>Email Address</Form.Label>
                                                </div>
                                                <Form.Control placeholder="Enter email address" type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                                            </Col>
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <div className="form-label-group">
                                                    <Form.Label>Password</Form.Label>
                                                    <Link to="/auth/forgot-password" className="fs-7 fw-medium">Forgot Password ?</Link>
                                                </div>
                                                <InputGroup className="password-check">
                                                    <span className="input-affix-wrapper">
                                                        <Form.Control placeholder="Enter your password" value={password} onChange={e => setPassword(e.target.value)} required type={showPassword ? "text" : "password"} />
                                                        <Link to="#" className="input-suffix text-muted" onClick={() => setShowPassword(!showPassword)} >
                                                            <span className="feather-icon">
                                                                {
                                                                    showPassword
                                                                        ?
                                                                        <EyeOff className="form-icon" />
                                                                        :
                                                                        <Eye className="form-icon" />
                                                                }

                                                            </span>
                                                        </Link>
                                                    </span>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-center">
                                            <Form.Check id="logged_in" className="form-check-sm mb-3" >
                                                <Form.Check.Input type="checkbox" defaultChecked />
                                                <Form.Check.Label className="text-muted fs-7">Keep me logged in</Form.Check.Label>
                                            </Form.Check>
                                        </div>
                                        {
                                            loading ?
                                                <Button variant="primary" className='btn-uppercase btn-block btn btn-primary btn-uppercase btn-block' disabled>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    Loading...
                                                </Button> :
                                                <Button variant="primary" type="submit" className="btn-uppercase btn-block btn btn-primary btn-uppercase btn-block">Login</Button>
                                        }
                                        {error && <div className='error-box mt-4 text-center'>
                                            <div className="alert alert-danger title-sm" role="alert">
                                                {error}
                                            </div>
                                        </div>}
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Body;
