import React from 'react';
import { Nav } from 'react-bootstrap';
import { Inbox } from 'react-feather';
import SimpleBar from 'simplebar-react';

import ComposeEmail from './ComposeEmail';

//Redux
import { connect } from 'react-redux';
import { Compose } from '../../redux/action/Email';

const EmailSidebar = ({ Compose, composeEmail, setActive, active }) => {

    return (
        <>
            <Nav className="emailapp-sidebar">
                <SimpleBar className="nicescroll-bar">
                    <div className="menu-content-wrap">
                        <div className="menu-group">
                            <Nav className="nav-light navbar-nav flex-column" activeKey={active} >
                                <Nav.Item >
                                    <Nav.Link eventKey="open" onClick={() => setActive('open')}>
                                        <span className="nav-icon-wrap">
                                            <span className="feather-icon">
                                                <Inbox />
                                            </span>
                                        </span>
                                        <span className="nav-link-text">Opened</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="closed" onClick={() => setActive('closed')}>
                                        <span className="nav-icon-wrap">
                                            <span className="feather-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                            </span>
                                        </span>
                                        <span className="nav-link-text">Closed</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                </SimpleBar>
            </Nav>

            {/* Compose email */}
            <ComposeEmail show={composeEmail} onClose={() => Compose(false)} />
        </>
    )
}

const mapStateToProps = ({ emailReducer }) => {
    const { composeEmail } = emailReducer;
    return { composeEmail, }
};

export default connect(mapStateToProps, { Compose })(EmailSidebar);