import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createNotification, getUsers, updateNotification } from '../../utils/apis';

const EditNotification = ({ show, hide, all, editData, updateTable }) => {
    const [users, setUsers] = useState([]);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        title: '',
        message: '',
        meta: '',
        user: '',
        label: '',
        level: 'success',
        type: 'notification'
    });

    const updateData = (data) => {
        setData((pre) => ({ ...pre, ...data }));
    }

    const getUsersAction = async () => {
        try {
            const where = { isSuperAdmin: false };
            const userData = await getUsers(`where=${JSON.stringify(where)}`);
            setUsers(userData);
            if (!editData) {
                updateData({ user: userData[0].id });
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    const onDataSubmit = async (e) => {
        try {
            e.preventDefault();

            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            }
            setValidated(true);
            const check = Object.values(data).findIndex((e) => e === "");

            if (check !== -1) {
                return;
            }
            setLoading(true);
            if (editData) {
                const updatedNotification = await updateNotification(data, editData);
                updateTable(updatedNotification, false);
            } else {
                const newNotification = await createNotification(data);
                updateTable(newNotification);
            }
            setLoading(false);
            hide();
            toast.success('Success');
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        getUsersAction();
        if (editData) {
            const index = all.findIndex((e) => e.id === editData);
            setData(
                {
                    title: all[index].title,
                    message: all[index].message,
                    meta: all[index].meta,
                    label: all[index].label,
                    user: all[index].user.id,
                    level: 'success',
                    type: 'notification'
                }
            );
        }
        // eslint-disable-next-line
    }, [editData]);

    return (
        <Modal show={show} onHide={hide} size="lg" centered >
            <Modal.Header>
                <Modal.Title>{`${editData ? "Edit" : "New"} Notification`}</Modal.Title>
                <Button bsPrefix="btn-close" onClick={hide} >
                    <span aria-hidden="true">×</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={onDataSubmit}>
                    <Row className="mb-3">
                        <Col sm={12}>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="notificationTitle">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" placeholder="Title" required value={data.title} onChange={(e) => updateData({ title: e.target.value })} />
                                    <Form.Control.Feedback type="invalid">
                                        Title is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="notificationMessage">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control type="text" as="textarea" rows={3} placeholder="Message" required value={data.message} onChange={(e) => updateData({ message: e.target.value })} />
                                    <Form.Control.Feedback type="invalid">
                                        Message is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="notificationMeta">
                                    <Form.Label>Meta</Form.Label>
                                    <Form.Control type="text" placeholder="Meta" required value={data.meta} onChange={(e) => updateData({ meta: e.target.value })} />
                                    <Form.Control.Feedback type="invalid">
                                        Meta is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="notificationLabel">
                                    <Form.Label>Label</Form.Label>
                                    <Form.Control type="text" placeholder="Label" required value={data.label} onChange={(e) => updateData({ label: e.target.value })} />
                                    <Form.Control.Feedback type="invalid">
                                        Label is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md={6} controlId="notificationType">
                                    <Form.Label>Level</Form.Label>
                                    <Form.Select value={data.level} onChange={(e) => updateData({ level: e.target.value })}>
                                        <option value='success' >Success</option>
                                        <option value='info' >Info</option>
                                        <option value='warning' >Warning</option>
                                        <option value='danger' >Danger</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md={6} controlId="notificationUser">
                                    <Form.Label>User</Form.Label>
                                    {
                                        !!users.length &&
                                        <Form.Select value={data.user} onChange={(e) => updateData({ user: e.target.value })}>
                                            {
                                                users.map((user) => (
                                                    <option value={user.id} key={user.id} >{user.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    }
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                    {
                        loading ?
                            <Button variant="primary" className='btn btn-primary float-end' disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Loading...
                            </Button>
                            : <Button type='submit' submit="true" className="float-end">{editData ? 'Update' : 'Create'}</Button>
                    }
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default EditNotification
