import React from 'react';
import PropTypes from 'prop-types';
import useTablePageSize from './hooks/useTablePageSize';
import classNames from 'classnames';
import { Form, Table } from 'react-bootstrap';
import { Star } from 'react-feather';
import { ArrowsSort, SortAscending, SortDescending } from 'tabler-icons-react';
import TableFooter from './TableFooter';
import useRowSelect from './hooks/useRowSelect';
import useStarred from './hooks/useStarred';

const UserTable = ({
    column,
    rowData,
    bsPrefix,
    classes,
    striped,
    bordered,
    borderless,
    hover,
    size,
    variant,
    responsive,
    rowsPerPage,
    paginatorSize,
    rowSelection,
    searchBar,
    searchQuery,
    searchClasses,
    markStarred,
    isLoading,
    totoalCount,
    page,
    setPage,
    selectedCb,
    sortCb,
    sortConfig,
    ...rest
}) => {

    const [data, setData] = React.useState(rowData);
    // custom hooks for data table
    const { range } = useTablePageSize(totoalCount, page, rowsPerPage);
    const { selectAll, selectedRows, handleRowSelection, handleSelectAll, isRowSelected } = useRowSelect(data, data);
    const { handleStared } = useStarred(rowData);

    const makeSort = (sort, accessor) => {
        if (sortCb) {
            sortCb(sort, accessor);
        }
    }

    React.useEffect(() => {
        if (selectedCb) {
            selectedCb(selectedRows)
        }
        // eslint-disable-next-line
    }, [selectedRows])

    React.useEffect(() => {
        setData(rowData)
    }, [rowData])

    return (
        <>
            <Table
                bsPrefix={bsPrefix}
                className={classNames("hk-data-table", classes)}
                striped={striped}
                bordered={bordered}
                borderless={borderless}
                hover={hover}
                size={size}
                variant={variant}
                responsive={responsive}
                {...rest}
            >
                <thead>
                    <tr>
                        {(rowSelection || markStarred) && <th>
                            {rowSelection ? <Form.Check
                                type="checkbox"
                                className="fs-6 mb-0"
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                                :
                                <></>
                            }

                        </th>}
                        {column.map((cols, index) => (
                            <th
                                key={index}
                                onClick={() => makeSort(cols.sort, cols.accessor)}
                                className={classNames({ 'd-none': cols.hidden }, { "text-primary": sortConfig !== null && sortConfig.key === cols.accessor }, cols.className)}
                                rowSpan={cols.rowSpan}
                            >
                                <span className="d-flex">
                                    <span className="flex-grow-1">
                                        {cols.title}
                                    </span>
                                    {
                                        cols.sort &&
                                        <span>
                                            {(sortConfig !== null && cols.accessor === sortConfig.key)
                                                ?
                                                <>
                                                    {
                                                        (sortConfig.direction === 'ASC')
                                                            ?
                                                            <font color="#007D88">
                                                                <SortAscending size={14} strokeWidth={2.5} />
                                                            </font>
                                                            :
                                                            <font color="#007D88">
                                                                <SortDescending size={14} strokeWidth={2.5} />
                                                            </font>
                                                    }
                                                </>
                                                :
                                                <span><ArrowsSort size={14} strokeWidth={2.5} /> </span>}
                                        </span>
                                    }
                                </span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        (() => {
                            if (isLoading) {
                                return (
                                    <tr>
                                        <td colSpan={(rowSelection || markStarred) ? column.length + 1 : column.length}>
                                            <p className='text-center'>Loading...</p>
                                        </td>
                                    </tr>
                                )
                            } else {
                                if (!rowData.length) {
                                    return (
                                        <tr>
                                            <td colSpan={(rowSelection || markStarred) ? column.length + 1 : column.length}>
                                                <p className='text-center'>No data</p>
                                            </td>
                                        </tr>
                                    )
                                } else {
                                    return data.map((row, index) => (
                                        <tr key={index} className={classNames({ "selected": isRowSelected(index) })}>
                                            {(rowSelection || markStarred) && <td>
                                                <div className="d-flex align-items-center">
                                                    {rowSelection && <Form.Check
                                                        type="checkbox"
                                                        className="form-check fs-6 mb-0"
                                                        checked={isRowSelected(row.id ? row.id : index)}
                                                        onChange={() => handleRowSelection(row.id ? row.id : index)}
                                                    />}
                                                    {markStarred && <span className={classNames("fav-star", { "marked": row.starred })} onClick={() => handleStared(index)} >
                                                        <span className="feather-icon">
                                                            <Star />
                                                        </span>
                                                    </span>}
                                                </div>
                                            </td>}
                                            {column.map((cols, index) => (
                                                <td
                                                    key={index}
                                                    className={classNames({ 'd-none': cols.hidden }, cols.tdClasses)}
                                                >
                                                    {
                                                        cols.cellFormatter
                                                            ?
                                                            cols.cellFormatter(row[cols.accessor])
                                                            :
                                                            row[cols.accessor]
                                                    }
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                }
                            }
                        })()
                    }
                </tbody>
            </Table >
            <TableFooter
                classname='mt-5'
                range={range}
                slice={data.length}
                setPage={setPage}
                page={page}
                totalRows={totoalCount}
                paginatorSize={paginatorSize}
            />
        </>
    )
}

UserTable.propTypes = {
    column: PropTypes.array.isRequired,
    rowData: PropTypes.array.isRequired,
    bsPrefix: PropTypes.string,
    classes: PropTypes.string,
    striped: PropTypes.bool,
    bordered: PropTypes.bool,
    borderless: PropTypes.bool,
    hover: PropTypes.bool,
    size: PropTypes.string,
    variant: PropTypes.string,
    responsive: PropTypes.bool,
    rowsPerPage: PropTypes.number,
    paginatorSize: PropTypes.string,
    rowSelection: PropTypes.bool,
    searchBar: PropTypes.bool,
    searchQuery: PropTypes.string,
    searchClasses: PropTypes.string,
    markStarred: PropTypes.bool,
}

export default UserTable
