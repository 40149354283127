import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
// import moment from 'moment';
// import { Calendar } from 'react-feather';
// import HkBadge from '../../components/@hk-badge/@hk-badge';
import AudienceReviewChart from './ChartData/AudienceReviewChart';

const AudienceReviewCard = ({ parentRef }) => {
    const [statistic, setStatistic] = useState({});
    const [data, setData] = useState({});
    const [allData, setAllData] = useState({});
    const [acitveIndex, setAcitveIndex] = useState('');

    const selectStatistic = (data) => {
        setData(data)
    }

    useEffect(() => {
        if (acitveIndex) {
            setStatistic(data)
        } else {
            setStatistic(allData)
        }
    }, [acitveIndex, data, allData])

    useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [])

    return (
        <Card className="card-border mb-0 h-100">
            <Card.Header className="card-header-action">
                <h6>Month GGR</h6>
            </Card.Header>
            <Card.Body>
                <AudienceReviewChart statistic={statistic} selectStatistic={selectStatistic} acitveIndex={acitveIndex} setAllData={setAllData} setAcitveIndex={setAcitveIndex} parentRef={parentRef} />
                <div className="separator-full mt-5" />
                <div className="flex-grow-1 ms-lg-3">
                    <Row>
                        <Col xxl={3} sm={6} className="mb-3">
                            <span className="d-block fw-medium fs-7">Players</span>
                            <div className="d-flex align-items-center">
                                <span className="d-block fs-4 fw-medium text-dark mb-0">
                                    {(() => {
                                        if (statistic.uniquePlayers) {
                                            return statistic.uniquePlayers.toLocaleString()
                                        }
                                        return '-';
                                    })()}
                                </span>
                                {/* <HkBadge bg="success" size="sm" soft className="ms-1" >
                                    <i className="bi bi-arrow-up" /> 7.5%
                                </HkBadge> */}
                            </div>
                        </Col>
                        <Col xxl={3} sm={6} className="mb-3">
                            <span className="d-block fw-medium fs-7">Rounds</span>
                            <div className="d-flex align-items-center">
                                <span className="d-block fs-4 fw-medium text-dark mb-0">
                                    {(() => {
                                        if (statistic.totalRounds) {
                                            return statistic.totalRounds.toLocaleString()
                                        }
                                        return '-';
                                    })()}
                                </span>
                                {/* <HkBadge bg="success" size="sm" soft className="ms-1" >
                                    <i className="bi bi-arrow-up" /> 7.2%
                                </HkBadge> */}
                            </div>
                        </Col>
                        <Col xxl={3} sm={6} className="mb-3">
                            <span className="d-block fw-medium fs-7">Turnover</span>
                            <div className="d-flex align-items-center">
                                <span className="d-block fs-4 fw-medium text-dark mb-0">
                                    {(() => {
                                        if (statistic.totalTurnover) {
                                            return `€${Number(statistic.totalTurnover?.toFixed(2)).toLocaleString()}`
                                        }
                                        return '-';
                                    })()}
                                </span>
                                {/* <HkBadge bg="danger" size="sm" soft className="ms-1" >
                                    <i className="bi bi-arrow-down" /> 0.2%
                                </HkBadge> */}
                            </div>
                        </Col>
                        <Col xxl={3} sm={6}>
                            <span className="d-block fw-medium fs-7">GGR</span>
                            <div className="d-flex align-items-center">
                                <span className="d-block fs-4 fw-medium text-dark mb-0">
                                    {(() => {
                                        if (statistic.totalGGR) {
                                            return `€${Number(statistic.totalGGR?.toFixed(2)).toLocaleString()}`
                                        }
                                        return '-';
                                    })()}
                                </span>
                                {/* <HkBadge bg="success" size="sm" soft className="ms-1" >
                                    <i className="bi bi-arrow-up" /> 10.8%
                                </HkBadge> */}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className='text-center'>{statistic.providerName ?? '--'}</div>
                    </Row>
                </div>
            </Card.Body>
        </Card>
    )
}

export default AudienceReviewCard
