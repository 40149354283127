import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from '../../utils/axios';
//
import { AuthContext } from './auth-context';
import { setSession, setUserSession } from './utils';
import axiosInstance from '../../utils/axios';
import { groupBy } from '../../utils/utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const Types = {
    INITIAL: 'INITIAL',
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    LOGOUT: 'LOGOUT',
}

// ----------------------------------------------------------------------

const initialState = {
    user: null,
    permissions: [],
    loading: true
};

const reducer = (state, action) => {
    if (action.type === Types.INITIAL) {
        return {
            loading: false,
            user: action.payload.user,
            permissions: action.payload.permissions
        };
    }
    if (action.type === Types.LOGIN) {
        return {
            ...state,
            user: action.payload.user,
            permissions: action.payload.permissions
        };
    }
    if (action.type === Types.REGISTER) {
        return {
            ...state,
            user: action.payload.user
        };
    }
    if (action.type === Types.LOGOUT) {
        return {
            ...state,
            user: null
        };
    }
    return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const initialize = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem(STORAGE_KEY);
            const user = localStorage.getItem('user');

            if (accessToken && user) {
                setSession(accessToken);
                const isValid = await axiosInstance.post(endpoints.auth.ping);
                if (isValid.data.status === 'ok') {
                    const userJson = JSON.parse(user);

                    const permissionsGrouped = groupBy(userJson.permissions, 'permissionModel');
                    const permissions = {};
                    for (const model in permissionsGrouped) {
                        permissions[model] = {};
                        for (const idx in permissionsGrouped[model]) {
                            permissions[model][permissionsGrouped[model][idx].permissionType] = true;
                        }
                    }

                    dispatch({
                        type: Types.INITIAL,
                        payload: {
                            user: userJson,
                            permissions
                        }
                    });
                } else {
                    setSession();
                    dispatch({
                        type: Types.INITIAL,
                        payload: {
                            user: null
                        }
                    });
                }
            } else {
                dispatch({
                    type: Types.INITIAL,
                    payload: {
                        user: null
                    }
                });
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: Types.INITIAL,
                payload: {
                    user: null
                }
            });
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    // LOGIN
    const login = useCallback(async (email, password) => {
        const data = {
            email,
            password
        };

        const response = await axios.post(endpoints.auth.login, data);

        const { token, user } = response.data;

        const permissionsGrouped = groupBy(user.permissions, 'permissionModel');
        const permissions = {};
        for (const model in permissionsGrouped) {
            permissions[model] = {};
            for (const idx in permissionsGrouped[model]) {
                permissions[model][permissionsGrouped[model][idx].permissionType] = true;
            }
        }

        setSession(token);
        setUserSession(user);

        dispatch({
            type: Types.LOGIN,
            payload: {
                user,
                permissions
            }
        });
    }, []);

    // REGISTER
    const register = useCallback(async (email, password, firstName, lastName) => {
        const data = {
            email,
            password,
            firstName,
            lastName
        };

        const response = await axios.post(endpoints.auth.register, data);

        const { accessToken, user } = response.data;

        localStorage.setItem(STORAGE_KEY, accessToken);

        dispatch({
            type: Types.REGISTER,
            payload: {
                user
            }
        });
    }, []);

    // LOGOUT
    const logout = useCallback(async () => {
        setSession(null);
        setUserSession(null);
        dispatch({
            type: Types.LOGOUT
        });
    }, []);

    // UPDATE USER
    const updateAuth = async (user) => {
        setUserSession(user);
        dispatch({
            type: Types.LOGIN,
            payload: {
                user
            }
        });
    }

    // ----------------------------------------------------------------------

    const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

    const status = state.loading ? 'loading' : checkAuthenticated;

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            permissions: state.permissions,
            method: 'jwt',
            loading: status === 'loading',
            authenticated: status === 'authenticated',
            unauthenticated: status === 'unauthenticated',
            //
            login,
            register,
            logout,
            updateAuth
        }),
        [login, logout, register, state.user, state.permissions, status]
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
