import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const FinanceBarChart = ({ data, isLoading }) => {

    const [series, setSeries] = useState([]);

    const optionsTemplate = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Total Bets', 'Total Revenue', 'Total Rounds', 'Total Wins'],
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function (value, e) {
                    if (e.dataPointIndex === 2) {
                        return value.toLocaleString();
                    }
                    return `€ ${value.toLocaleString()}`
                },
                title: {
                    formatter: (seriesName) => seriesName,
                },
            },
        }
    };

    useEffect(() => {
        if (data && !isLoading) {
            setSeries(data.map((one) => ({ name: one.providerName, data: [Number(one.totalBets.toFixed(2)), Number(one.totalRevenue.toFixed(2)), Number(one.totalRounds.toFixed(2)), Number(one.totalWins.toFixed(2))] })))
        }
    }, [data, isLoading])

    if (isLoading) {
        return <div style={{ height: 270 }} className='w-100 d-flex align-items-center justify-content-center'>Loading...</div>
    } else {
        if (!data.length) {
            return <div style={{ height: 270 }} className='w-100 d-flex align-items-center justify-content-center'>No Data</div>
        }
        return <ReactApexChart options={optionsTemplate} series={series} height={270} />
    }
}

export default FinanceBarChart;
