
import { useMemo } from 'react';
import { Page, View, Text, Document, StyleSheet } from '@react-pdf/renderer';


const useStyles = () =>
    useMemo(
        () =>
            StyleSheet.create({
                col4: { width: '25%' },
                col8: { width: '75%' },
                col6: { width: '50%' },
                mb4: { marginBottom: 4 },
                mb8: { marginBottom: 8 },
                mb40: { marginBottom: 40 },
                h3: { fontSize: 16, fontWeight: 700 },
                h4: { fontSize: 13, fontWeight: 700 },
                body1: { fontSize: 10 },
                body2: { fontSize: 9 },
                subtitle1: { fontSize: 10, fontWeight: 700 },
                subtitle2: { fontSize: 9, fontWeight: 700 },
                alignRight: { textAlign: 'right' },
                page: {
                    fontSize: 9,
                    lineHeight: 1.6,
                    // fontFamily: 'Roboto',
                    backgroundColor: '#FFFFFF',
                    textTransform: 'capitalize',
                    padding: '40px 24px 120px 24px'
                },
                footer: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    padding: 24,
                    margin: 'auto',
                    borderTopWidth: 1,
                    borderStyle: 'solid',
                    position: 'absolute',
                    borderColor: '#DFE3E8'
                },
                gridContainer: {
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                },
                table: {
                    display: 'flex',
                    width: 'auto'
                },
                tableRow: {
                    padding: '8px 0',
                    flexDirection: 'row',
                    borderBottomWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#DFE3E8'
                },
                noBorder: {
                    paddingTop: 8,
                    paddingBottom: 0,
                    borderBottomWidth: 0
                },
                tableCell_1: {
                    width: '5%'
                },
                tableCell_2: {
                    width: '50%',
                    paddingRight: 16
                },
                tableCell_3: {
                    width: '15%'
                }
            }),
        []
    );

const PDFFile = ({ data }) => {
    const styles = useStyles();
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.table}>
                    <View>
                        <View style={styles.tableRow}>
                            {
                                Object.keys(data[0]).map((th, i) => (
                                    <View key={i} style={{ width: `${100 / Object.keys(data[0]).length}%`, }}>
                                        <Text style={styles.subtitle2}>{th}</Text>
                                    </View>
                                ))
                            }
                        </View>
                    </View>

                    <View>
                        {data.map((item, index) => (
                            <View style={styles.tableRow} key={index}>
                                {
                                    Object.keys(item).map((td, j) => (
                                        <View key={j} style={{ width: `${100 / Object.keys(item).length}%`, }}>
                                            <Text>{item[td]}</Text>
                                        </View>
                                    ))
                                }
                            </View>
                        ))}
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default PDFFile;