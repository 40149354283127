import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PageFooter = () => {
    return (
        <div className="hk-footer">
            <Container as="footer" className="footer">
                <Row>
                    <Col xl={8}>
                        <p className="footer-text">
                            <span className="copy-text">Slotserv © {new Date().getFullYear()} All rights reserved.</span> <Link to="/priacy-policy">Privacy Policy</Link><span className="footer-link-sep">|</span><Link to="/terms-of-service">Terms Of Service</Link><span className="footer-link-sep">|</span><a href="https://slotserv.statuspage.io" rel="noreferrer" target="_blank">System Status</a></p>
                    </Col>
                    <Col xl={4}>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PageFooter
