import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SimpleBar from 'simplebar-react';

import { updateMarkup } from '../../../utils/apis';


const ProviderModal = ({ show, onClose, data }) => {
    const [markups, setMarkups] = useState([]);
    const [loading, setLoading] = useState(false);

    const changeValue = (value, id) => {
        setMarkups((pre) => pre.map((one) => {
            if (one.id === id) one.percent = value;
            return one;
        }))
    }

    const submitUpdate = async () => {
        try {
            setLoading(true);
            for (const mark of markups) {
                await updateMarkup({ percent: mark.percent }, mark.id);
            }
            setLoading(false);
            onClose();
            toast.success('Updated Successfully!')
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }


    useEffect(() => {
        setMarkups(data);
    }, [data]);

    return (
        <Modal show={show} onHide={onClose} centered dialogClassName="mw-800p" >
            <Modal.Header className="header-wth-bg-inv">
                <Modal.Title as="h5">{`${data[0].provider.name} | Game Categories (GGR)`}</Modal.Title>
                <Button bsPrefix="btn-close" className="text-white" onClick={onClose} >
                    <span aria-hidden="true">×</span>
                </Button>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="h-350p">
                    <SimpleBar className="nicescroll-bar">
                        <Table>
                            <thead>
                                <tr>
                                    <th>Category Name</th>
                                    <th>GGR Category</th>
                                    <th>Base GGR</th>
                                    <th>GGR Markup</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    markups.map((row, i) => (
                                        <tr key={row.id}>
                                            <td>{row.percentCategory.id}</td>
                                            <td>{row.percentCategory.name}</td>
                                            <td>{`${row.percentCategory.percent}%`}</td>
                                            <td>
                                                <Form.Control type='number' value={row.percent} onChange={(e) => changeValue(e.target.value, row.id)} />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </SimpleBar>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button variant='light' className="flex-fill flex-1" onClick={onClose} >Close</Button>
                <Button variant="primary" className="flex-fill flex-1" disabled={loading} onClick={submitUpdate}>Done</Button>
            </Modal.Footer>
        </Modal>

    )
}

export default ProviderModal;
