import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Edit, Trash } from 'react-feather';
import moment from 'moment';

//Custom Action Container
const actionFormater = (cell, permission) => {
    return (
        <div className="d-flex align-items-center" >
            <div className="d-flex">
                {
                    permission.PUT &&
                    <Button variant="flush-dark" as={Link} to={`/game/${cell.id}/edit`} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                        <span className="icon">
                            <span className="feather-icon">
                                <Edit />
                            </span>
                        </span>
                    </Button>
                }
                {
                    permission.DELETE &&
                    <Button onClick={() => cell.handleDelete({ id: cell.id, name: cell.name })} variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete">
                        <span className="icon">
                            <span className="feather-icon">
                                <Trash />
                            </span>
                        </span>
                    </Button>
                }
            </div>
        </div>
    )
}

const ImageFormater = (cell) => {
    return (
        <div className='position-relative'>
            <img src={cell} alt='img' style={{ height: 50 }} />
        </div>
    )
}


const dateFormater = (cell) => {
    return moment(cell).format('MM/DD/yyyy hh:mm:ss')
}

export const columns = (permission) => {
    const data = [
        {
            accessor: "id",
            title: "Id",
        },
        {
            accessor: "name",
            title: "Name",
            sort: true,
        },
        {
            accessor: "displayName",
            title: "Display Name",
            sort: true,
        },
        {
            accessor: "image",
            title: "Image",
            cellFormatter: ImageFormater,
        },
        {
            accessor: "type",
            title: "Type",
            sort: true,
        },
        {
            accessor: "minBet",
            title: "Min Bet",
            sort: true,
        },
        {
            accessor: "maxBet",
            title: "Max Bet",
            sort: true,
        },
        {
            accessor: "maxExposure",
            title: "Max Exposure",
            sort: true,
        },
        {
            accessor: "provider",
            title: "provider",
            cellFormatter: (cell) => cell.name
        },
        {
            accessor: "status",
            title: "Status",
            sort: true,
        },
        {
            accessor: "createdAt",
            title: "Created Date",
            sort: true,
            cellFormatter: dateFormater,
        },
        {
            accessor: "updatedAt",
            title: "Last Edited",
            sort: true,
            cellFormatter: dateFormater,
        },
    ];

    if (permission.PUT || permission.DELETE) {
        data.push(
            {
                accessor: "actions",
                title: "Actions",
                cellFormatter: (cell) => actionFormater(cell, permission),
            },
        )
        if (permission.PUT) {
            data[0].cellFormatter = (cell) => <Link to={`/game/${cell}/edit`}>{cell}</Link>
        }
    }
    return data;
}