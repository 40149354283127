import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { Pagination, InputGroup, Button, Col, Form, Nav, Row, Table, Card } from 'react-bootstrap';
import { Calendar } from 'react-feather';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import { ArrowsSort, SortAscending, SortDescending } from 'tabler-icons-react';
import { getRounds } from '../../../utils/apis';
import { toast } from 'react-toastify';
import { badTransactionColumn, goodTransactionColumn, roundColumns } from '../TableData';

import HkDataTable from '../../../components/@hk-data-table';
import classNames from 'classnames';
import ErrorModal from './ErrorModal';
import moment from 'moment';

const rowsPerPage = 50;

const RoundsBody = () => {

    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({});
    const [activeRow, setActiveRow] = useState(-1);
    const [modalData, setModalData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    const [dateRange, setRange] = useState({
        start: moment().startOf('hour').toDate(),
        end: moment().startOf('hour').add(32, 'hour').toDate()
    });
    const [filterData, setFilterData] = useState({
        roundId: '',
        playerId: '',
        bet: 0,
        win: 0,
        search: ''
    });
    const [loading, setLoading] = useState({
        getRound: true,
        getTransaction: false
    });

    const updateLoading = (data) => {
        setLoading((pre) => ({ ...pre, ...data }));
    }

    const updateFilter = (data) => {
        setFilterData((pre) => ({ ...pre, ...data }));
    }


    const requestSort = (sort, key) => {
        let direction = 'ASC';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ASC'
        ) {
            direction = 'DESC';
        };
        if (sort) {
            setSortConfig({ key, direction });
        }
    };

    const selectRow = (index) => {
        setActiveRow((pre) => pre !== index ? index : -1);
    }

    const openErrorModal = (data) => {
        setModalData(data);
        setShowModal(true)
    }

    const closeErrorModal = (data) => {
        setModalData({});
        setShowModal(false)
    }


    const getRoundsAction = async (query, newPage) => {
        try {
            updateLoading({ getRound: true });
            const rounds = await getRounds(query);
            if (rounds.length) {
                setData(rounds.map((e, i) => ({ ...e, action: { handle: selectRow, id: e.roundId, index: i } })));
                setDisableNext(false);
                if (newPage > 1) {
                    setPage(newPage);
                }
            } else {
                setDisableNext(true);
            }
            updateLoading({ getRound: false });
        } catch (error) {
            updateLoading({ getRound: false });
            toast.error(error.message);
        }
    }

    const loadData = (newPage) => {
        const where = {
            createdAt: { '>': new Date(dateRange.start).valueOf() },
            createdAt1: { '<': new Date(dateRange.end).valueOf() }
        };

        if (filterData.roundId) {
            where.roundId = filterData.roundId;
        }
        if (filterData.playerId) {
            where.playerId = filterData.playerId;
        }
        if (filterData.bet) {
            where.realBet = filterData.bet;
        }
        if (filterData.win) {
            where.realBet = filterData.win;
        }

        let strWhere = JSON.stringify(where);
        strWhere = strWhere.replaceAll('createdAt1', 'createdAt');

        let condition = `where=${strWhere}`;
        const skip = newPage ? newPage - 1 : page - 1;

        if (sortConfig.key) {
            condition += `&sort=${sortConfig.key}%20${sortConfig.direction}`;
        } else {
            condition += `&sort=createdAt%20DESC`;
        }
        getRoundsAction(condition + `&skip=${rowsPerPage * skip}&limit=${rowsPerPage}`, newPage);
    }

    const handleNext = () => {
        loadData(page + 1);
    }

    const handlePrev = () => {
        if (page > 1) {
            loadData(page - 1);
        }
    }

    const changeDate = (start, end) => {
        setRange({ start: start.toDate(), end: end.toDate() });
    }

    useEffect(() => {
        loadData(1);
        // eslint-disable-next-line
    }, [sortConfig]);

    useEffect(() => {
        loadData(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="contact-body">
                <Nav variant="tabs" className="nav-light nav-line">
                    <Nav.Item>
                        <Nav.Link eventKey="overview" className='active' >
                            <span className="nav-link-text text-capitalize">
                                Rounds
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <div className='page-tab-body mt-1'>
                    <SimpleBar className="nicescroll-bar">
                        <div className="contact-list-view">
                            <Card className='p-3'>
                                <Row >
                                    <Col xs={12}>
                                        <Row>
                                            <Form.Group as={Col} md={2} sm={6}>
                                                <Form.Label>RoundID</Form.Label>
                                                <Form.Control type="text" placeholder="RoundID" value={filterData.roundId} onChange={(e) => updateFilter({ roundId: e.target.value })} />
                                            </Form.Group>
                                            <Form.Group as={Col} md={2} sm={6}>
                                                <Form.Label>PlayerID</Form.Label>
                                                <Form.Control type="text" placeholder="PlayerID" value={filterData.playerId} onChange={(e) => updateFilter({ playerId: e.target.value })} />
                                            </Form.Group>
                                            <Form.Group as={Col} md={1} sm={6}>
                                                <Form.Label>Bet</Form.Label>
                                                <Form.Control type="number" placeholder="Bet" value={filterData.bet} onChange={(e) => updateFilter({ bet: e.target.value })} />
                                            </Form.Group>
                                            <Form.Group as={Col} md={1} sm={6}>
                                                <Form.Label>Win</Form.Label>
                                                <Form.Control type="number" placeholder="Win" value={filterData.win} onChange={(e) => updateFilter({ win: e.target.value })} />
                                            </Form.Group>
                                            <Col md={4} sm={6}>
                                                <Form.Label>Date</Form.Label>
                                                <InputGroup>
                                                    <span className="input-affix-wrapper">
                                                        <span className="input-prefix">
                                                            <span className="feather-icon">
                                                                <Calendar />
                                                            </span>
                                                        </span>
                                                        <DateRangePicker
                                                            initialSettings={{
                                                                timePicker: true,
                                                                startDate: dateRange.start,
                                                                endDate: dateRange.end,
                                                                locale: {
                                                                    format: 'M/DD hh:mm A',
                                                                },
                                                            }}
                                                            onCallback={changeDate}
                                                        >
                                                            <Form.Control type="text" name="datetimes" />
                                                        </DateRangePicker>
                                                    </span>
                                                </InputGroup>
                                            </Col>
                                            <Col md={2} sm={6} className='d-flex align-items-end'>
                                                <Button disabled={loading.getRound} className='mt-2' onClick={() => loadData(1)}>Search</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>

                            <Table
                                className="hk-data-table"
                            >
                                <thead>
                                    <tr>
                                        {roundColumns.map((cols, index) => (
                                            <th
                                                key={index}
                                                onClick={() => requestSort(cols.sort, cols.accessor)}
                                                className={classNames({ 'd-none': cols.hidden }, { "text-primary": sortConfig !== null && sortConfig.key === cols.accessor }, cols.className)}
                                                rowSpan={cols.rowSpan}
                                            >
                                                <span className="d-flex">
                                                    <span className="flex-grow-1">
                                                        {cols.title}
                                                    </span>
                                                    {
                                                        cols.sort &&
                                                        <span>
                                                            {(sortConfig !== null && cols.accessor === sortConfig.key)
                                                                ?
                                                                <>
                                                                    {
                                                                        (sortConfig.direction === 'ASC')
                                                                            ?
                                                                            <font color="#007D88">
                                                                                <SortAscending size={14} strokeWidth={2.5} />
                                                                            </font>
                                                                            :
                                                                            <font color="#007D88">
                                                                                <SortDescending size={14} strokeWidth={2.5} />
                                                                            </font>
                                                                    }
                                                                </>
                                                                :
                                                                <span><ArrowsSort size={14} strokeWidth={2.5} /> </span>}
                                                        </span>
                                                    }
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (() => {
                                            if (loading.getRound) {
                                                return (
                                                    <tr>
                                                        <td colSpan={roundColumns.length}>
                                                            <p className='text-center'>Loading...</p>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                if (!data.length) {
                                                    return (
                                                        <tr>
                                                            <td colSpan={roundColumns.length}>
                                                                <p className='text-center'>No data</p>
                                                            </td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return data.map((row, index) => (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                {roundColumns.map((cols, index) => (
                                                                    <td
                                                                        key={index}
                                                                        className={classNames({ 'd-none': cols.hidden }, cols.tdClasses)}
                                                                    >
                                                                        {
                                                                            cols.cellFormatter
                                                                                ?
                                                                                cols.cellFormatter(row[cols.accessor])
                                                                                :
                                                                                row[cols.accessor]
                                                                        }
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            {
                                                                index === activeRow &&
                                                                <tr>
                                                                    <td colSpan={roundColumns.length} className='px-2 py-3' style={{ backgroundColor: '#eaeaea2b' }} >
                                                                        <div>
                                                                            <p>Good Transactions</p>
                                                                            <div style={{ border: '1px solid #eaeaea', borderStyle: 'solid solid none solid', backgroundColor: '#fff' }}>
                                                                                <HkDataTable
                                                                                    column={goodTransactionColumn}
                                                                                    rowData={row.transactions.filter((e) => e.status)}
                                                                                    classes="nowrap w-100 mb-0"
                                                                                    responsive
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='mt-3'>
                                                                            <p>Bad Transactions</p>
                                                                            <div style={{ border: '1px solid #eaeaea', borderStyle: 'solid solid none solid', backgroundColor: '#fff' }}>
                                                                                <HkDataTable
                                                                                    column={badTransactionColumn}
                                                                                    rowData={row.transactions.filter((e) => !e.status).map((e) => ({ ...e, error: { data: e, handle: openErrorModal } }))}
                                                                                    classes="nowrap w-100 mb-0"
                                                                                    responsive
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            }
                                        })()
                                    }
                                </tbody>
                            </Table>

                            <Row className="mt-3">
                                <Col />
                                <Col>
                                    <Pagination className='custom-pagination pagination-simple d-flex justify-content-end'>
                                        <Pagination.Prev onClick={handlePrev} disabled={page === 1} >
                                            <i className="ri-arrow-left-s-line" />
                                        </Pagination.Prev>
                                        <Pagination.Item active>{page}</Pagination.Item>
                                        <Pagination.Next disabled={disableNext} onClick={handleNext}  >
                                            <i className="ri-arrow-right-s-line" />
                                        </Pagination.Next>
                                    </Pagination>
                                </Col>
                            </Row>
                        </div>
                    </SimpleBar >
                </div>
            </div>
            <ErrorModal show={showModal} close={closeErrorModal} data={modalData} />
        </>
    )
}

export default RoundsBody;
