import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { Table, Button, Col, Form, Nav, Row, Spinner } from 'react-bootstrap';
import { ArrowsSort, SortAscending, SortDescending } from 'tabler-icons-react';
import { toast } from 'react-toastify';
import { groupBy } from '../../../utils/utils';
import { getAllPermissions, getGroup, createGroup, updateGroup } from '../../../utils/apis';

const PermissionCheckBox = ({ data, setData, permission }) => {
    if (!permission) return null;

    const idx = data.findIndex((e) => (e.id === permission.id))
    const updatePermissions = (e) => {
        if (e.target.checked) {
            setData((pre) => [...pre, permission])
        } else {
            setData((pre) => pre.filter((e) => (e.id !== permission.id)))
        }
    }

    return <Form.Check type="checkbox" checked={idx !== -1} onChange={updatePermissions} />
}

const EditGroupBody = ({ id }) => {

    const history = useHistory();

    const [groupName, setGroupName] = useState('');
    const [sortable, setSortable] = useState('');
    const [data, setData] = useState([]);
    const [permissionData, setPermissionData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [createLoading, setCreateLoading] = useState(false);

    const getGroupAction = async (id) => {
        try {
            const groupData = await getGroup(id);
            setGroupName(groupData.groupName);
            setData(groupData.permissions)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const getPermissionData = async () => {
        try {
            setIsLoading(true);
            const permissions = await getAllPermissions();
            const permissionList = groupBy(permissions, 'permissionModel');
            let dataArray = []
            for (const key in permissionList) {
                dataArray.push({
                    name: key,
                    view: permissionList[key].filter((e) => e.permissionType === 'GET')[0],
                    create: permissionList[key].filter((e) => e.permissionType === 'POST')[0],
                    edit: permissionList[key].filter((e) => e.permissionType === 'PUT')[0],
                    delete: permissionList[key].filter((e) => e.permissionType === 'DELETE')[0],
                })
            }
            setPermissionData([...dataArray]);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    }

    const onGroupSubmit = async (e) => {
        try {
            e.preventDefault();
            setCreateLoading(true);
            if (id) {
                await updateGroup({ groupName, permissions: data.map((e) => e.id) }, id);
            } else {
                await createGroup({ groupName, permissions: data.map((e) => e.id) });
                setGroupName('');
                setData([]);
            }
            setCreateLoading(false);
            toast.success("Success");
            history.push('/group/list');
        } catch (error) {
            setCreateLoading(false);
            toast.error(error.message);
        }
    }

    const sortedData = useMemo(() => {
        if (sortable) {
            permissionData.sort((a, b) => {
                if (a.name < b.name) {
                    return sortable === 'ascending' ? -1 : 1;
                }
                else if (a.name > b.name) {
                    return sortable === 'ascending' ? 1 : -1;
                }
                return sortable === 'descending' ? 1 : -1;
            });
        }
        return permissionData;
    }, [permissionData, sortable])

    useEffect(() => {
        if (id) {
            getGroupAction(id);
        }
        getPermissionData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div className="contact-body">
            <Nav variant="tabs" className="nav-light nav-line page-tab-header">
                <Nav.Item>
                    <Nav.Link eventKey="overview" className='active' >
                        <span className="nav-link-text">{`${id ? 'Edit' : 'Create'} A Group`}</span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <div className='page-tab-body mt-1'>
                <SimpleBar className="nicescroll-bar">
                    <div className="contact-list-view">
                        <Form onSubmit={onGroupSubmit}>
                            <Row className="mb-3" >
                                <Col xs={7} mb={3}>
                                    <div className="contact-toolbar-left">
                                        <div className="dataTables_filter">
                                            <Form.Label>
                                                Group Name
                                                <Form.Control
                                                    size="md"
                                                    required
                                                    placeholder="admin"
                                                    value={groupName}
                                                    onChange={e => setGroupName(e.target.value)}
                                                />
                                            </Form.Label>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={5} mb={3}>
                                    <div className="contact-toolbar-right">
                                        <div className="dataTables_filter">
                                            <Form.Label>
                                                {
                                                    createLoading ?
                                                        <Button variant="primary" className='btn-uppercase btn-block btn btn-primary btn-uppercase btn-block' disabled>
                                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                            Loading...
                                                        </Button>
                                                        : <Button type='submit' submit="true">{id ? 'Update' : 'Create'}</Button>
                                                }
                                            </Form.Label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        <Table>
                            <thead>
                                <tr>
                                    <td
                                        onClick={() => setSortable((pre) => !pre ? 'ascending' : pre === 'ascending' ? 'descending' : 'ascending')}
                                    >
                                        <span className="d-flex">
                                            <span className="flex-grow-1">
                                                Name
                                            </span>
                                            <span>
                                                {
                                                    sortable ?
                                                        (
                                                            (sortable === 'ascending')
                                                                ?
                                                                <font color="#007D88">
                                                                    <SortAscending size={14} strokeWidth={2.5} />
                                                                </font>
                                                                :
                                                                <font color="#007D88">
                                                                    <SortDescending size={14} strokeWidth={2.5} />
                                                                </font>
                                                        )
                                                        : <span><ArrowsSort size={14} strokeWidth={2.5} /></span>
                                                }
                                            </span>
                                        </span>
                                    </td>
                                    <td>View</td>
                                    <td>Create</td>
                                    <td>Edit</td>
                                    <td>Delete</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (() => {
                                        if (isLoading) {
                                            return (
                                                <tr>
                                                    <td colSpan={5}>
                                                        <p className='text-center'>Loading...</p>
                                                    </td>
                                                </tr>
                                            )
                                        } else {
                                            if (!permissionData.length) {
                                                return (
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <p className='text-center'>No data</p>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return sortedData.map((row, index) =>
                                                    <tr key={index}>
                                                        <td><span className="text-capitalize">{row.name}</span></td>
                                                        <td><PermissionCheckBox data={data} permission={row.view} setData={setData} /></td>
                                                        <td><PermissionCheckBox data={data} permission={row.create} setData={setData} /></td>
                                                        <td><PermissionCheckBox data={data} permission={row.edit} setData={setData} /></td>
                                                        <td><PermissionCheckBox data={data} permission={row.delete} setData={setData} /></td>
                                                    </tr>
                                                )
                                            }
                                        }
                                    })()
                                }
                            </tbody>
                        </Table>
                    </div>
                </SimpleBar >
            </div>
        </div>
    )
}

export default EditGroupBody;