import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Paperclip, Maximize2, Minimize2, Minus, X } from 'react-feather';
//Redux
import { connect } from 'react-redux';
import { Maximize, Minimize, Compose } from '../../redux/action/Email';
import { toast } from 'react-toastify';
import { createTicket, fileUpload } from '../../utils/apis';
import { useAuthContext } from '../../auth/hooks';
import HkTooltip from '../../components/@hk-tooltip/HkTooltip';

const ComposeEmail = ({ setData, Maximize, Minimize, maximize, minimize, composeEmail, Compose }) => {
    const { user } = useAuthContext();
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [value, setValue] = useState({
        title: "",
        description: "",
        priority: "medium"
    });

    const handleValue = (data) => setValue((pre) => ({ ...pre, ...data }));

    const handleCreate = async () => {
        try {
            if (!value.title) {
                setTitleError(true);
                return;
            }
            if (!value.description) {
                setDescriptionError(true);
                return;
            }
            setTitleError(false);
            setDescriptionError(false);
            setLoading(true);
            const newTicket = await createTicket({ ...value, user: user.id });
            if (file) {
                await fileUpload({ model: 'SupportTicket', modelId: newTicket.id, file })
            }
            setData((pre) => [newTicket, ...pre])
            toast.success("Success!");
            setValue({
                title: "",
                description: "",
                priority: "medium"
            })
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    const handleMinimize = () => {
        Maximize(false);
        Minimize(!minimize);

    }

    const handleClose = () => {
        Compose(!composeEmail);
        Maximize(false);
        Minimize(false);
    }

    const addFile = (e) => {
        e.preventDefault()
        e.target.blur()

        let files = e.target.files;
        setFile(files[0]);
    }

    return (
        <div className={classNames("compose-email-popup", { "d-block": composeEmail }, { "minimize-email-popup": minimize }, { "maximize-email-popup": maximize })}  >
            <div className="d-flex flex-column h-100">
                <header className="d-flex align-items-center justify-content-between">
                    <h6 className="text-white mb-0">Create new ticket</h6>
                    <div className="d-flex">
                        <Button size="sm" variant="dark" className="btn-icon btn-rounded d-lg-block d-none" onClick={handleMinimize} >
                            <span className="icon">
                                <span className="feather-icon">
                                    <Minus />
                                </span>
                            </span>
                        </Button>
                        <Button size="sm" variant="dark" className="btn-icon btn-rounded d-lg-block d-none" onClick={() => Maximize(!maximize)}  >
                            <span className="icon">
                                <span className="feather-icon">
                                    {maximize ? <Minimize2 /> : <Maximize2 />}
                                </span>
                            </span>
                        </Button>
                        <Button size="sm" variant="dark" className="btn-icon btn-rounded d-lg-block">
                            <span className="icon" onClick={handleClose} >
                                <span className="feather-icon">
                                    <X />
                                </span>
                            </span>
                        </Button>
                    </div>
                </header>
                <Form noValidate validated={titleError || descriptionError}>
                    <Form.Group className="mb-3" >
                        <Form.Control placeholder="Title" required value={value.title} onChange={(e) => handleValue({ title: e.target.value })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control as="textarea" placeholder="Description" required value={value.description} onChange={(e) => handleValue({ description: e.target.value })} />
                    </Form.Group>
                </Form>
                <div className="compose-email-footer">
                    <div>
                        <Button variant="primary" className="me-2" disabled={loading} onClick={handleCreate}>Create</Button>
                        <Button variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover" as='label'>
                            <input hidden type="file" onChange={addFile} />
                            <HkTooltip id="addFlag" title="Add file" placement="top" >
                                <span className="icon">
                                    <span className="feather-icon">
                                        <Paperclip />
                                    </span>
                                </span>
                            </HkTooltip>
                        </Button>
                        <span>
                            {
                                file
                                && file.name
                            }
                        </span>
                    </div>
                    <div className='d-flex direction-row align-items-center'>
                        <div className='mx-1'>Priority : </div>
                        <Form.Select className="d-flex align-items-center w-130p" value={value.priority} onChange={(e) => handleValue({ priority: e.target.value })} >
                            <option value="high">High</option>
                            <option value="medium">Medium</option>
                            <option value="low">Low</option>
                        </Form.Select>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ emailReducer }) => {
    const { composeEmail, maximize, minimize } = emailReducer;
    return { composeEmail, maximize, minimize }
};

export default connect(mapStateToProps, { Maximize, Minimize, Compose })(ComposeEmail);