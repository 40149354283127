import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import GameCategoryHeader from '../list/GameCategoryHeader';
import EditGameCategoryBody from './EditGameCategoryBody';

const CreateGroup = () => {
    const { id } = useParams();

    return (
        <>
            <Helmet>
                <title> {`${id ? 'Edit' : 'Create'} Game Category | Slotserv - iGaming Content Provider`} </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <GameCategoryHeader />
                        <EditGameCategoryBody id={id} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateGroup;
