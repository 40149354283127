import axiosInstance, { endpoints } from "./axios";

// Permissions
export const getAllPermissions = async () => {
    const response = await axiosInstance.get(endpoints.allPermission);
    return response.data;
}

export const getPermissions = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.permission}?${query}`);
    return response.data;
}

export const getPermissionsCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.permission}/count?${query}`);
    return response.data;
}

// Groups
export const getGroups = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.group}?${query}`);
    return response.data;
}

export const getGroupsCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.group}/count?${query}`);
    return response.data;
}

export const getGroup = async (id) => {
    const response = await axiosInstance.get(`${endpoints.group}/${id}?populate=permissions`);
    return response.data;
}

export const createGroup = async (params) => {
    const response = await axiosInstance.post(endpoints.group, params);
    return response.data;
}

export const updateGroup = async (params, id) => {
    const response = await axiosInstance.patch(`${endpoints.group}/${id}`, params);
    return response.data;
}

export const deleteGroup = async (id) => {
    const response = await axiosInstance.delete(`${endpoints.group}/${id}`);
    return response.data;
}

// Users
export const getUsers = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.user}?${query}`);
    return response.data;
}

export const getUsersCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.user}/count?${query}`);
    return response.data;
}

export const getUser = async (id) => {
    const response = await axiosInstance.get(`${endpoints.user}/${id}`);
    return response.data;
}

export const createUser = async (params) => {
    const response = await axiosInstance.post(endpoints.user, params);
    return response.data;
}

export const updateUser = async (params, id) => {
    const response = await axiosInstance.patch(`${endpoints.user}/${id}`, params);
    return response.data;
}

export const deleteUser = async (id) => {
    const response = await axiosInstance.delete(`${endpoints.user}/${id}`);
    return response.data;
}

// Logs
export const getLogs = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.log}?${query}`);
    return response.data;
}

export const getLogsCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.log}/count?${query}`);
    return response.data;
}

// Games
export const getGamesCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.game}/count?${query}`);
    return response.data;
}

export const getGames = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.game}?${query}`);
    return response.data;
}

export const getGame = async (id) => {
    const response = await axiosInstance.get(`${endpoints.game}/${id}`);
    return response.data;
}

export const createGame = async (params) => {
    const response = await axiosInstance.post(endpoints.game, params);
    return response.data;
}

export const updateGame = async (params, id) => {
    const response = await axiosInstance.patch(`${endpoints.game}/${id}`, params);
    return response.data;
}

export const deleteGame = async (id) => {
    const response = await axiosInstance.delete(`${endpoints.game}/${id}`);
    return response.data;
}

// Rounds
export const getRounds = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.round}?${query}`);
    return response.data;
}

export const getRoundsCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.round}/count?${query}`);
    return response.data;
}

// Transactions
export const getTransactions = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.transaction}?${query}`);
    return response.data;
}

// Providers
export const getProviders = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.provider}?${query}`);
    return response.data;
}

export const getProvidersCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.provider}/count?${query}`);
    return response.data;
}

export const getProvider = async (id) => {
    const response = await axiosInstance.get(`${endpoints.provider}/${id}`);
    return response.data;
}

export const createProvider = async (params) => {
    const response = await axiosInstance.post(endpoints.provider, params);
    return response.data;
}

export const updateProvider = async (params, id) => {
    const response = await axiosInstance.patch(`${endpoints.provider}/${id}`, params);
    return response.data;
}

export const deleteProvider = async (id) => {
    const response = await axiosInstance.delete(`${endpoints.provider}/${id}`);
    return response.data;
}

// Notifications
export const getNotificationsCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.notification}/count?${query}`);
    return response.data;
}

export const getNotifications = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.notification}?${query}`);
    return response.data;
}

export const createNotification = async (params) => {
    const response = await axiosInstance.post(`${endpoints.notification}`, params);
    return response.data;
}

export const updateNotification = async (params, id) => {
    const response = await axiosInstance.patch(`${endpoints.notification}/${id}`, params);
    return response.data;
}

export const deleteNotification = async (id) => {
    const response = await axiosInstance.delete(`${endpoints.notification}/${id}`);
    return response.data;
}

// Dashboard
export const getStatistics = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.statistic}?${query}`);
    return response.data;
}

export const getReports = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.report}?${query}`);
    return response.data;
}

// Forgot password
export const forgotPassword = async (params) => {
    const response = await axiosInstance.post(`${endpoints.auth.forgotPassword}`, params);
    return response.data;
}

// Rest password
export const resetPassword = async (params) => {
    const response = await axiosInstance.post(`${endpoints.auth.resetPassword}`, params);
    return response.data;
}

// Markup
export const getMarkup = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.markup}?${query}`);
    return response.data;
}

export const createMarkup = async (params) => {
    const response = await axiosInstance.post(endpoints.markup, params);
    return response.data;
}

export const updateMarkup = async (params, id) => {
    const response = await axiosInstance.patch(`${endpoints.markup}/${id}`, params);
    return response.data;
}

export const deleteMarkup = async (id) => {
    const response = await axiosInstance.delete(`${endpoints.markup}/${id}`);
    return response.data;
}

// PercentCategory
export const getPercentCategory = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.percentCategory}?${query}`);
    return response.data;
}

// Currencies
export const getCurrencies = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.currency}?${query}`);
    return response.data;
}

export const getCurrencyCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.currency}/count?${query}`);
    return response.data;
}

export const getCurrency = async (id) => {
    const response = await axiosInstance.get(`${endpoints.currency}/${id}`);
    return response.data;
}

export const createCurrency = async (params) => {
    const response = await axiosInstance.post(endpoints.currency, params);
    return response.data;
}

export const updateCurrency = async (params, id) => {
    const response = await axiosInstance.patch(`${endpoints.currency}/${id}`, params);
    return response.data;
}

export const deleteCurrency = async (id) => {
    const response = await axiosInstance.delete(`${endpoints.currency}/${id}`);
    return response.data;
}

// Sessions
export const getSessions = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.session}?${query}`);
    return response.data;
}

export const getSessionsCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.session}/count?${query}`);
    return response.data;
}

export const getSession = async (id) => {
    const response = await axiosInstance.get(`${endpoints.session}/${id}`);
    return response.data;
}

export const createSession = async (params) => {
    const response = await axiosInstance.post(endpoints.session, params);
    return response.data;
}

export const updateSession = async (params, id) => {
    const response = await axiosInstance.patch(`${endpoints.session}/${id}`, params);
    return response.data;
}

export const deleteSession = async (id) => {
    const response = await axiosInstance.delete(`${endpoints.session}/${id}`);
    return response.data;
}

// Game Category
export const getGameCategories = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.gamecategory}?${query}`);
    return response.data;
}

export const getGameCategoryCount = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.gamecategory}/count?${query}`);
    return response.data;
}

export const getGameCategory = async (id) => {
    const response = await axiosInstance.get(`${endpoints.gamecategory}/${id}`);
    return response.data;
}

export const createGameCategory = async (params) => {
    const response = await axiosInstance.post(endpoints.gamecategory, params);
    return response.data;
}

export const updateGameCategory = async (params, id) => {
    const response = await axiosInstance.patch(`${endpoints.gamecategory}/${id}`, params);
    return response.data;
}

export const deleteGameCategory = async (id) => {
    const response = await axiosInstance.delete(`${endpoints.gamecategory}/${id}`);
    return response.data;
}

// Support Tickets
export const getTickets = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.supportticket}?${query}`);
    return response.data;
}

export const createTicket = async (params) => {
    const response = await axiosInstance.post(endpoints.supportticket, params);
    return response.data;
}

export const updateTicket = async (params, id) => {
    const response = await axiosInstance.patch(`${endpoints.supportticket}/${id}`, params);
    return response.data;
}

export const deleteTicket = async (id) => {
    const response = await axiosInstance.delete(`${endpoints.supportticket}/${id}`);
    return response.data;
}
// Support Comment 
export const getComments = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.supportcomment}?${query}`);
    return response.data;
}

export const createComment = async (params) => {
    const response = await axiosInstance.post(endpoints.supportcomment, params);
    return response.data;
}

// Finance Report
export const getFinanceDashboard = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.financeDashboard}?${query}`);
    return response.data;
}

export const getFinanceReports = async (query = '') => {
    const response = await axiosInstance.get(`${endpoints.financeReport}?${query}`);
    return response.data;
}

// file upload
export const fileUpload = async (params) => {
    const response = await axiosInstance.post(endpoints.upload, params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export const getFile = async (query) => {
    const response = await axiosInstance.get(`${endpoints.file}?${query}`);
    return response.data;
}