import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Dropdown, Form } from 'react-bootstrap';
import { ArrowDown, CornerUpLeft, MoreVertical, Paperclip, } from 'react-feather';
import SimpleBar from 'simplebar-react';
import HkTooltip from '../../components/@hk-tooltip/HkTooltip';

import moment from 'moment';
import { toast } from 'react-toastify';
import { createComment, deleteTicket, fileUpload, getComments, getFile, updateTicket } from '../../utils/apis';
import { useAuthContext } from '../../auth/hooks';
import { formatBytes } from '../../utils/utils';

const priorityStatus = {
    high: 'danger',
    medium: 'orange',
    low: 'success'
}

const CommentItem = ({ comment, model, setComments, isReply }) => {
    const { user } = useAuthContext();
    const [open, setOpen] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [file, setFile] = useState();
    const [textError, setTextError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState({
        text: "",
        author: "",
        supportTicket: "",
        type: "",
    });

    const handleValue = (data) => setValue((pre) => ({ ...pre, ...data }));

    const handleCreate = async () => {
        try {
            if (!value.text) {
                setTextError(true);
                return;
            }
            setTextError(false);
            setLoading(true);
            const newComment = await createComment({
                text: value.text,
                user: user.id,
                type: user.isOperator ? 'user' : 'admin',
                supportTicket: comment.supportTicket.id
            });
            if (file) {
                await fileUpload({ model: 'SupportComment', modelId: newComment.id, file })
            }
            setComments((pre) => [...pre, newComment]);
            toast.success("Success!");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    const loadFile = async () => {
        try {
            const where = {
                model,
                modelId: comment.id
            }
            const res = await getFile(`where=${JSON.stringify(where)}`);
            setFileData(res);
        } catch (error) {
            console.log(error)
        }
    }

    const addFile = (e) => {
        e.preventDefault()
        e.target.blur()

        let files = e.target.files;
        setFile(files[0]);
    }

    const renderCommentBox = (
        <>
            <div className="my-5">
                <Button variant="outline-light" className="me-2" data-bs-toggle="collapse" data-bs-target="#compose_email" aria-expanded="false">
                    <span>
                        <span className="icon">
                            <span className="feather-icon">
                                <CornerUpLeft />
                            </span>
                        </span>
                        <span>Reply</span>
                    </span>
                </Button>
            </div>
            <div id="compose_email" className="collapse mt-7">
                <div className="d-flex">
                    <div className="media">
                        <div className="media-head me-3">
                            <div className="avatar avatar-primary avatar-sm avatar-rounded">
                                <span className="initial-wrap">{`${user?.firstName[0]}${user?.lastName[0]}`}</span>
                            </div>
                        </div>
                    </div>
                    <Form as={Card} className="card-shadow w-100" noValidate validated={textError}>
                        <Card.Body>
                            <Form.Group>
                                <Form.Control as="textarea" rows={8} placeholder="Enter details" className="mb-3" value={value.text} onChange={(e) => handleValue({ text: e.target.value })} />
                            </Form.Group>
                            <Form.Group className="d-flex justify-content-between mb-0">
                                <div>
                                    <Button variant="primary" className="me-2" disabled={loading} onClick={handleCreate}>Send</Button>
                                    <Button variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover" as='label'>
                                        <input hidden type="file" onChange={addFile} />
                                        <HkTooltip id="addFlag" title="Add file" placement="top" >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Paperclip />
                                                </span>
                                            </span>
                                        </HkTooltip>
                                    </Button>
                                    <span>
                                        {
                                            file
                                            && file.name
                                        }
                                    </span>
                                </div>
                                {/* <div>
                                    <Button variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover">
                                        <HkTooltip id="delete" title="Delete" placement="top" >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Trash2 />
                                                </span>
                                            </span>
                                        </HkTooltip>
                                    </Button>
                                </div> */}
                            </Form.Group>
                        </Card.Body>
                    </Form>
                </div>
            </div>
        </>
    )

    const handleOpen = async () => {
        setOpen((pre) => !pre);
        if (open === false && !fileData.length) {
            await loadFile();
        }
    }

    return (
        <div className="accordion-item">
            <div className="accordion-header">
                <div>
                    <div className="email-head">
                        <div data-bs-toggle="collapse" role="button" aria-expanded="false" onClick={handleOpen} />
                        <div className="media">
                            <div className="media-head">
                                <div className="avatar avatar-primary avatar-sm avatar-rounded">
                                    <span className="initial-wrap">{`${comment.user?.firstName[0]}${comment.user?.lastName[0]}`}</span>
                                </div>
                            </div>
                            <div><b>{`${comment.user.firstName} ${comment.user.lastName}`}</b></div>
                        </div>
                        <div className="email-head-action">
                            <div className="email-time">{moment(comment.createdAt).format('DD MMM  h:mm A')}</div>
                        </div>
                    </div>
                </div>
            </div>
            {
                open &&
                <div className="collapse show">
                    <div className="accordion-body">
                        <p className='mb-2'>{comment.text}</p>
                        <div className="email-attachment-wrap">
                            {
                                fileData.map((file) => (
                                    <div className="attachment-box" key={file.id}>
                                        <div>
                                            <div className="media">
                                                <div className="avatar avatar-icon avatar-sm avatar-blue">
                                                    <span className="initial-wrap fs-3">
                                                        <i className="ri-file-download-fill" />
                                                    </span>
                                                </div>
                                                <div className="media-body">
                                                    <p className="file-name">{file.originalName}</p>
                                                    <p className="file-size">{formatBytes(file.size)}</p>
                                                </div>
                                            </div>
                                            <div className="file-overlay">
                                                <a href={file.url} target='_blank' rel="noreferrer" className="btn btn-sm btn-icon btn-rounded btn-primary"><span className="icon">
                                                    <ArrowDown />
                                                </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {isReply && renderCommentBox}
                    </div>
                </div>
            }
        </div>
    )
}


const EmailBody = ({ data, setData, setSelectedId }) => {
    const { user } = useAuthContext();

    const [comments, setComments] = useState([]);

    const handleStatus = async () => {
        try {
            await updateTicket({ status: data.status === 'open' ? 'closed' : 'open' }, data.id);
            setData((pre) => pre.map((item) => {
                if (item.id === data.id) {
                    item.status = data.status === 'open' ? 'closed' : 'open';
                }
                return item;
            }));
            setSelectedId("");
        } catch (error) {
            toast.error(error.message);
        }
    }

    const handleDelete = async () => {
        try {
            await deleteTicket(data.id);
            setData((pre) => pre.filter((item) => item.id !== data.id));
            setSelectedId("");
        } catch (error) {
            toast.error(error.message);
        }
    }


    const loadData = async () => {
        try {
            const where = { supportTicket: data.id }
            const res = await getComments(`where=${JSON.stringify(where)}&sort=createdAt%20ASC`);
            setComments(res);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (data && data.id) {
            setComments([])
            loadData();
        }
        // eslint-disable-next-line
    }, [data]);

    const renderContent = (
        <SimpleBar className="nicescroll-bar">
            <div className="single-email-subject">
                <div>
                    <h4 className="fw-light">{data.title}</h4>
                    <Badge bg={priorityStatus[data.priority]} pill className="badge-sm flex-shrink-0 ms-3">{data.priority}</Badge>
                    <Badge bg="success" pill className="badge-sm flex-shrink-0 ms-3">{data.department}</Badge>
                </div>
                {
                    user?.isSuperAdmin &&
                    <Dropdown>
                        <Dropdown.Toggle as="a" href="#" size="sm" className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret">
                            <span className="icon">
                                <span className="feather-icon">
                                    <MoreVertical />
                                </span></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            <Dropdown.Item onClick={handleStatus}>{data.status === 'open' ? 'Close' : 'Open'}</Dropdown.Item>
                            <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                }
                {/* <div className="email-options-wrap">
                    <Button variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Print">
                        <HkTooltip id="tooltip1" placement="top" title="Print" >
                            <span className="icon">
                                <span className="feather-icon">
                                    <Printer />
                                </span>
                            </span>
                        </HkTooltip>
                    </Button>
                    <Button variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover">
                        <HkTooltip id="tooltip2" placement="top" title="Reply" >
                            <span className="icon">
                                <span className="feather-icon">
                                    <CornerUpLeft />
                                </span>
                            </span>
                        </HkTooltip>
                    </Button>
                </div> */}
            </div>

            <div id="accordionSimpleExample" className="accordion accordion-simple single-email-thread">
                <CommentItem
                    comment={{
                        id: data.id,
                        text: data.description,
                        supportTicket: data,
                        user: data.user,
                        createdAt: data.createdAt,
                    }}
                    model='SupportTicket'
                    setComments={setComments}
                    isReply={!comments.length}
                />
                {
                    comments.map((comment, index) => (
                        <CommentItem comment={comment} model='SupportComment' setComments={setComments} isReply={index === (comments.length - 1)} key={comment.id} />
                    ))
                }
            </div>
        </SimpleBar >
    )

    const renderEmpty = (
        <div className='d-flex align-items-center justify-content-center h-100'>
            <h4>Empty</h4>
        </div>
    )
    return (
        <div className="email-body">
            {
                Object.keys(data).length ? renderContent : renderEmpty
            }

        </div >
    )
}

export default EmailBody
