import React from 'react';
import { Helmet } from 'react-helmet-async';

import UsersHeader from './list/UsersHeader';
import UsersBody from './list/UsersBody';

const Users = () => {
    return (
        <>
            <Helmet>
                <title> User List | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <UsersHeader />
                        <UsersBody/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users;
