import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CommanFooter1 from '../CommanFooter1';

//image
import logo from '../../../assets/dist/img/logo-light.svg';
import { resetPassword } from '../../../utils/apis';
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const token = searchParams.get('token');

    const handlePassword = async (e) => {
        try {
            e.preventDefault();
            setLoading(true)
            const res = await resetPassword({ password, token });
            toast.success(res.message);
            setLoading(false);
            setMessage("");
            setTimeout(() => {
                history.push('/auth/login');
            }, 5000);
            
        } catch (error) {
            let msg = typeof error === 'string' ? error : error.message;
            setMessage(msg)
            setLoading(false)
        }
    }

    return (
        <>
            <Helmet>
                <title> Reset Password | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-wrapper pt-0 pb-xl-0 pb-5">
                <div className="hk-pg-body pt-0 pb-xl-0">
                    <Container>
                        <Row>
                            <Col sm={10} className="position-relative mx-auto">
                                <div className="auth-content py-8">
                                    <Form className="w-100" onSubmit={handlePassword} noValidate validated>
                                        <Row>
                                            <Col lg={5} md={7} sm={10} className="mx-auto">
                                                <div className="text-center mb-7">
                                                    <Link to="/" className="navbar-brand me-0">
                                                        <img className="brand-img d-inline-block" src={logo} alt="brand" />
                                                    </Link>
                                                </div>
                                                <Card className="card-flush">
                                                    <Card.Body className="text-center">
                                                        <h4>Reset your Password</h4>
                                                        <Row className="gx-3">
                                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                                <div className="form-label-group">
                                                                    <Form.Label htmlFor="userName">New Password</Form.Label>
                                                                </div>
                                                                <Form.Control placeholder="password" type="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                                                                {
                                                                    message &&
                                                                    <div style={{ backgroundColor: 'red', color: '#fff', borderRadius: 5 }} className='mt-2 p-2'>
                                                                        {message}
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Button disabled={loading} type='submit' variant="primary" className="btn-uppercase btn-block">Rest Password</Button>
                                                        <p className="p-xs mt-2 text-center"><Link to="/auth/login"><u>Sign In</u></Link></p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* Page Footer */}
                <CommanFooter1 />
            </div>
        </>
    )
}

export default ResetPassword
