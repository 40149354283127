import { Monitor, Smartphone } from 'react-feather';
import { Link } from 'react-router-dom';
import moment from "moment";

const firstTransaction = (cell) => {
    const first = cell.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    if (first[0] && first[0].createdAt) {
        return moment(first[0].createdAt).format('yyyy-MM-DD hh:mm:ss');
    } else {
        return null;
    }
}

const dateTime = (cell) => {
    return moment(cell).format('yyyy-MM-DD hh:mm:ss');
}


const roundDetail = (cell, id) => {
    return (
        <Link to='#' onClick={() => cell.handle(cell.index)}>
            {
                id ? cell.id : 'view'
            }
        </Link>
    )
}

const showErrorModal = (cell) => {
    return <Link to='#' onClick={() => cell.handle(cell.data)}>View</Link>
}

export const roundColumns = [
    {
        accessor: "action",
        title: "Round Id",
        cellFormatter: (cell) => roundDetail(cell, 'id')
    },
    {
        accessor: "transactions",
        title: "First Transaction",
        cellFormatter: firstTransaction,
    },
    {
        accessor: "playerId",
        title: "Player Id",
    },
    {
        accessor: "gameId",
        title: "Game Id",
    },
    {
        accessor: "gameName",
        title: "Game Name",
        sort: true,
    },
    {
        accessor: "provider",
        title: "Provider",
        cellFormatter: (cell) => cell.name,
    },
    {
        accessor: "platform",
        title: "Platform",
        cellFormatter: (cell) => cell === 'desktop' ? <Monitor /> : <Smartphone />,
    },
    {
        accessor: "bet",
        title: "Bet",
        sort: true,
    },
    {
        accessor: "win",
        title: "Win",
        sort: true,
    },
    {
        accessor: "currency",
        title: "Currency",
        sort: true,
    },
    {
        accessor: "action",
        title: "Round Details",
        cellFormatter: roundDetail
    },
];

export const goodTransactionColumn = [
    {
        accessor: "createdAt",
        title: "Timestamp",
        cellFormatter: dateTime,
        sort: true,
    },
    {
        accessor: "transactionId",
        title: "Transaction ID",
        sort: true,
    },
    {
        accessor: "type",
        title: "Type",
    },
    {
        accessor: "amount",
        title: "Amount",
    },
];

export const badTransactionColumn = [
    {
        accessor: "createdAt",
        title: "Timestamp",
        cellFormatter: dateTime,
        sort: true,
    },
    {
        accessor: "transactionId",
        title: "Transaction ID",
        sort: true,
    },
    {
        accessor: "type",
        title: "Type",
    },
    {
        accessor: "amount",
        title: "Amount",
    },
    {
        accessor: "responseTime",
        title: "Response time",
    },
    {
        accessor: "httpStatus",
        title: "HTTP Status",
    },
    {
        accessor: "error",
        title: "Error",
        cellFormatter: showErrorModal
    },
];

export const otherRoundColumns = [
    {
        accessor: "roundId",
        title: "Round Id"
    },
    {
        accessor: "playerId",
        title: "Player Id",
    },
    {
        accessor: "gameId",
        title: "Game Id",
    },
    {
        accessor: "gameName",
        title: "Game Name",
        sort: true,
    },
    {
        accessor: "platform",
        title: "Platform",
        cellFormatter: (cell) => cell === 'desktop' ? <Monitor /> : <Smartphone />,
    },
    {
        accessor: "bet",
        title: "Bet",
        sort: true,
    },
    {
        accessor: "win",
        title: "Win",
        sort: true,
    },
    {
        accessor: "currency",
        title: "Currency",
        sort: true,
    }
];