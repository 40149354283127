import { Card } from 'react-bootstrap';

import FinanceBarChart from './ChartData/FinanceBarChart';

const FinanceChart = ({ data, isLoading }) => {
    return (
        
        <Card className="card-border mb-0 h-100">
            <Card.Header className="card-header-action">
                <h6>Provider Reports</h6>
            </Card.Header>
            <Card.Body>
                <FinanceBarChart data={data} isLoading={isLoading} />
            </Card.Body>
        </Card>
    )
}

export default FinanceChart
