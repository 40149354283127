import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import ReactApexChart from 'react-apexcharts';
// import HkBadge from '../../components/@hk-badge/@hk-badge';
import { getReports } from '../../utils/apis';
import { groupBy } from '../../utils/utils';
import { toast } from 'react-toastify';
import moment from 'moment';

const ReturningCustomersCard = ({ parentRef }) => {

    const [loading, setLoading] = useState(false);
    const [dayData, setDayData] = useState([]);
    const [options, setOptions] = useState({});
    const [series, setSeries] = useState([]);

    const optionsTemplate = {

        chart: {
            type: 'bar',
            height: 250,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            foreColor: "#646A71",
            fontFamily: 'DM Sans'
        },

        grid: {
            borderColor: '#F4F5F6',
        },

        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '35%',
                borderRadius: 5,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "last"
            },
        },
        xaxis: {
            type: 'string',
            categories: [],

            labels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                },
            },
            axisBorder: {
                show: false,
            },
            title: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                },
            },
            title: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                }
            },
        },
        legend: {
            show: true,
            position: 'top',
            fontSize: '15px',
            labels: {
                colors: '#6f6f6f',
            },
            markers: {
                width: 8,
                height: 8,
                radius: 15

            },
            itemMargin: {
                vertical: 5
            },
        },

        colors: ['#007D88', '#25cba1', '#ebf3fe'],
        fill: {
            opacity: 1
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function (value) {
                    return `€${value}`
                },
                title: {
                    formatter: (seriesName) => seriesName,
                },
            },
        }
    };

    useEffect(() => {
        let _series = [];
        let _categories = [];
        let _ggr = [];
        if (Object.keys(dayData).length) {
            for (const providerId in dayData) {
                const providerData = dayData[providerId].sort((a, b) => a.createdAt - b.createdAt);

                _categories.push(providerData[0].providerName);
                _ggr.push(providerData[0].totalGGR.toFixed(2))
            }
            
            _series.push({
                name: "GGR",
                data:_ggr
            });
            optionsTemplate.xaxis.categories = _categories;
            setOptions(optionsTemplate);
            setSeries(_series);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dayData]);

    const loadData = async () => {
        try {
            setLoading(true);
            const report = await getReports(`where={"date":{"contains":"${moment().format('yyyy-MM-DD')}"}, "type": "daily"}`);
            if (report.length) {
                let statistic = report.sort((a, b) => b.statistics.length - a.statistics.length);
                statistic = statistic[0].statistics;
                statistic = groupBy(statistic, 'provider');
                setDayData(statistic)
            }
            setLoading(false);
        } catch (error) {
            toast.error(error.message)
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
        parentRef.current = loadData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card className="card-border mb-0  h-100">
            <Card.Header className="card-header-action">
                <h6>Daily GGR</h6>
            </Card.Header>
            <Card.Body className="text-center">
                {
                    (() => {
                        if (loading) {
                            return <div style={{ height: 270 }} className='w-100 d-flex align-items-center justify-content-center'>Loading...</div>
                        } else {
                            if (!Object.keys(dayData).length) {
                                return <div style={{ height: 270 }} className='w-100 d-flex align-items-center justify-content-center'>No Data</div>
                            } else {
                                return <ReactApexChart options={options} series={series} type="bar" height={'100%'} />
                            }
                        }
                    })()
                }
            </Card.Body>
        </Card>
    )
}

export default ReturningCustomersCard
