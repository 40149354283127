import React from 'react';
import { Button } from 'react-bootstrap';
import { ChevronDown, ChevronUp, RefreshCw } from 'react-feather';
import { connect } from 'react-redux';
import { toggleTopNav } from '../../../redux/action/Theme';
import HkTooltip from '../../../components/@hk-tooltip/HkTooltip';

const RoundsHeader = ({ topNavCollapsed, toggleTopNav, getData, title }) => {

    return (
        <header className="contact-header">
            <div className="d-flex align-items-center">
                <span className="contactapp-title link-dark">
                    <h1 className='text-capitalize'>{title ?? 'Rounds'}</h1>
                </span>
            </div>
            <div className="contact-options-wrap">
                {
                    getData &&
                    <Button as="a" variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover no-caret d-sm-inline-block d-none" href="#" onClick={getData}>
                        <HkTooltip title="Refresh" placement="top" >
                            <span className="icon">
                                <span className="feather-icon">
                                    <RefreshCw />
                                </span>
                            </span>
                        </HkTooltip>
                    </Button>
                }
                <div className="v-separator d-lg-block d-none" />
                <Button as="a" href="#" className="btn-icon btn-flush-dark btn-rounded flush-soft-hover hk-navbar-togglable d-sm-inline-block d-none" onClick={() => toggleTopNav(!topNavCollapsed)} >
                    <HkTooltip placement={topNavCollapsed ? "bottom" : "top"} title="Collapse" >
                        <span className="icon">
                            <span className="feather-icon">
                                {
                                    topNavCollapsed ? <ChevronDown /> : <ChevronUp />
                                }
                            </span>
                        </span>
                    </HkTooltip>
                </Button>
            </div>
        </header>
    )
}

const mapStateToProps = ({ theme }) => {
    const { topNavCollapsed } = theme;
    return { topNavCollapsed }
};

export default connect(mapStateToProps, { toggleTopNav })(RoundsHeader);