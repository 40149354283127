import * as Icons from 'tabler-icons-react';

export const SidebarMenu = [
    {
        group: '',
        contents: [
            {
                name: 'Dashboard',
                icon: <Icons.Template />,
                path: '/dashboard',
            },
        ]
    },
    {
        group: 'Users Management',
        contents: [
            {
                id: 'user',
                name: 'Users',
                icon: <Icons.Users />,
                path: '/user',
                childrens: [
                    {
                        name: 'Users',
                        permission: 'GET',
                        path: '/user/list',
                        grp_name: "Users Management",
                    },
                    {
                        name: 'Create User',
                        permission: 'POST',
                        path: '/user/new',
                        grp_name: "Users Management",
                    },
                ]
            },
            {
                id: 'group',
                name: 'Groups',
                icon: <Icons.Stack3 />,
                path: '/group',
                childrens: [
                    {
                        name: 'Groups',
                        path: '/group/list',
                        grp_name: "Users Management",
                    },
                    {
                        name: 'Create Group',
                        path: '/group/new',
                        grp_name: "Users Management",
                    },
                ]
            },
            {
                id: 'permission',
                name: 'Permissions',
                icon: <Icons.ShieldCheck />,
                path: '/permission'
            },
            {
                id: 'session',
                name: 'Sessions',
                icon: <Icons.Clock />,
                path: '/session',
            },
            {
                id: 'log',
                name: 'Audit Log',
                icon: <Icons.Report />,
                path: '/log'
            },
        ]
    },
    {
        group: 'Games Management',
        contents: [
            {
                id: 'provider',
                name: 'Providers',
                icon: <Icons.HeartHandshake />,
                path: '/provider',
                childrens: [
                    {
                        name: 'Providers',
                        path: '/provider/list',
                        grp_name: "Games Management",
                    },
                    {
                        name: 'Create Provider',
                        path: '/provider/new',
                        grp_name: "Games Management",
                    },
                ]
            },
            {
                id: 'game',
                name: 'Games',
                icon: <Icons.DeviceGamepad2 />,
                path: '/game',
                childrens: [
                    {
                        name: 'Games',
                        path: '/game/list',
                        grp_name: "Games Management",
                    },
                    {
                        name: 'Create Game',
                        path: '/game/new',
                        grp_name: "Games Management",
                    },
                ]
            },
            {
                id: 'gamecategory',
                name: 'Game Category',
                icon: <Icons.Category />,
                path: '/gamecategory',
                childrens: [
                    {
                        name: 'Game Categories',
                        path: '/gamecategory/list',
                        grp_name: "Games Management",
                    },
                    {
                        name: 'Create Game Category',
                        path: '/gamecategory/new',
                        grp_name: "Games Management",
                    },
                ]
            },
            {
                id: 'round',
                name: 'Rounds',
                icon: <Icons.PlaystationCircle />,
                path: '/round',
                childrens: [
                    {
                        name: 'All Rounds',
                        path: '/round/all',
                        grp_name: "Games Management",
                    },
                    {
                        name: 'Open Rounds',
                        path: '/round/open',
                        grp_name: "Games Management",
                    },
                    {
                        name: 'Errored Rounds',
                        path: '/round/errored',
                        grp_name: "Games Management",
                    },
                ]
            }
        ]
    },
    {
        group: 'Integration',
        contents: [
            {
                id: 'documentation',
                name: 'Documentation',
                icon: <Icons.FileText />,
                path: '/documentation',
                childrens: [
                    {
                        name: 'Wallet Documentation',
                        path: '/documentation/wallet',
                        grp_name: "Integration",
                    },
                    {
                        name: 'Games Documentation',
                        path: '/documentation/games',
                        grp_name: "Integration",
                    },
                ]
            },
            {
                id: 'slotserv_sdk',
                name: 'SlotServ SDK',
                icon: <Icons.Sdk />,
                path: '/sdk',
                childrens: [
                    {
                        name: 'PHP',
                        path: '/sdk/php',
                        grp_name: "Integration",
                    },
                    {
                        name: 'NodeJS',
                        path: '/sdk/node',
                        grp_name: "Integration",
                    },
                    {
                        name: 'Python',
                        path: '/sdk/python',
                        grp_name: "Integration",
                    },
                ]
            },
        ],
        isFree: true
    },
    {
        group: 'Finance',
        contents: [
            {
                id: 'invoice',
                name: 'Invoices',
                icon: <Icons.FileInvoice />,
                path: '/invoice',
                childrens: [
                    {
                        name: 'Invoices',
                        path: '/invoice/list',
                        grp_name: "Finance",
                    },
                    {
                        name: 'Create Invoice',
                        path: '/invoice/new',
                        grp_name: "Finance",
                    },
                ]
            },
            {
                id: 'transaction',
                name: 'Finance Dashboard',
                icon: <Icons.LayoutDashboard />,
                path: '/finance-dashboard'
            },
            {
                id: 'report',
                name: 'Finance Report',
                icon: <Icons.ReportMoney />,
                path: '/finance-report'
            },
            {
                id: 'currency',
                name: 'Currencies ',
                icon: <Icons.CurrencyDollar />,
                path: '/currency',
                childrens: [
                    {
                        name: 'Currencies',
                        path: '/currency/list',
                        grp_name: "Finance",
                    },
                    {
                        name: 'Create Currency',
                        path: '/currency/new',
                        grp_name: "Finance",
                    }
                ]
            },
        ]
    },
    {
        group: 'Support',
        contents: [
            {
                id: 'supportTicket',
                name: 'Support Ticket',
                icon: <Icons.Ticket />,
                path: '/ticket'
            }
        ]
    }
]