import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CommanFooter1 from '../CommanFooter1';

//image
import logo from '../../../assets/dist/img/logo-light.svg';
import { forgotPassword } from '../../../utils/apis';
import { toast } from 'react-toastify';
import { getDuration } from '../../../utils/utils';

const ForgotPasword = () => {
    const [time, setTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendLink = async (e) => {
        try {
            e.preventDefault();
            if (time) return;
            setLoading(true);
            await forgotPassword({ email });
            toast.success('We sent email successfully!');

            localStorage.setItem('forgot', JSON.stringify(
                {
                    email,
                    time: new Date()
                }
            ));

            setLoading(false);
            setMessage("");
            countdown(120);
        } catch (error) {
            let msg = typeof error === 'string' ? error : error.message;
            setMessage(msg)
            setLoading(false)
        }
    }

    const countdown = (second) => {
        let seconds = second;
        const timer = setInterval(() => {
            const displayMinutes = Math.floor(seconds / 60);
            let displaySeconds = seconds % 60;

            // Add leading zero if seconds is less than 10
            if (displaySeconds < 10) {
                displaySeconds = `0${displaySeconds}`;
            }

            if (displayMinutes === 0 && displaySeconds === 0) {
                setTime('');
            } else {
                setTime(`${displayMinutes}:${displaySeconds}`);
            }

            if (seconds === 0) {
                clearInterval(timer);
            } else {
                seconds--;
            }
        }, 1000);
    }

    useEffect(() => {
        const limitData = localStorage.getItem('forgot');
        if (limitData) {
            const limitObj = JSON.parse(limitData);
            const second = getDuration(new Date(), new Date(limitObj.time).getTime() + 120000);
            if (second > 0) {
                countdown(second);
            } else {
                localStorage.removeItem('forgot');
            }
        }
    }, []);

    return (
        <>
            <Helmet>
                <title> Forgot Password | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-wrapper pt-0 pb-xl-0 pb-5">
                <div className="hk-pg-body pt-0 pb-xl-0">
                    <Container>
                        <Row>
                            <Col sm={10} className="position-relative mx-auto">
                                <div className="auth-content py-8">
                                    <Form className="w-100" onSubmit={sendLink} noValidate validated>
                                        <Row>
                                            <Col lg={5} md={7} sm={10} className="mx-auto">
                                                <div className="text-center mb-7">
                                                    <Link to="/" className="navbar-brand me-0">
                                                        <img className="brand-img d-inline-block" src={logo} alt="brand" />
                                                    </Link>
                                                </div>
                                                <Card className="card-flush">
                                                    <Card.Body className="text-center">
                                                        <h4>Forgot your Password</h4>
                                                        <p className="mb-4">No worries we will mail you link to your email address to reset your password</p>
                                                        <Row className="gx-3">
                                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                                <div className="form-label-group">
                                                                    <Form.Label htmlFor="userName">Email</Form.Label>
                                                                </div>
                                                                <Form.Control placeholder="Your email" type="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                                                {
                                                                    message &&
                                                                    <div style={{ backgroundColor: 'red', color: '#fff', borderRadius: 5 }} className='mt-2 p-2'>
                                                                        {message}
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        {
                                                            time &&
                                                            <p className="p-lg my-2 text-center">{`Please try after ${time}`}</p>
                                                        }
                                                        <Button disabled={loading || time} type='submit' variant="primary" className="btn-uppercase btn-block">Send Link</Button>
                                                        <p className="p-xs mt-2 text-center"><Link to="/auth/login"><u>Sign In</u></Link></p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* Page Footer */}
                <CommanFooter1 />
            </div>
        </>
    )
}

export default ForgotPasword
