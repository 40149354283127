import React from 'react';
import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import GroupsHeader from '../list/GroupsHeader';
import EditGroupBody from './EditGroupBody';

const CreateGroup = () => {
    const { id } = useParams();
    return (
        <>
            <Helmet>
                <title> {`${id ? 'Edit' : 'Create'} Group | Slotserv - iGaming Content Provider`} </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <GroupsHeader />
                        <EditGroupBody id={id} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateGroup;
