import React from 'react';
import { Helmet } from 'react-helmet-async';

import FianceReportHeader from './list/FianceReportHeader';
import FianceReportBody from './list/FianceReportBody';

const Rounds = () => {
    return (
        <>
            <Helmet>
                <title> Finance Report | Slotserv - iGaming Content Provider </title>
            </Helmet>
            <div className="hk-pg-body py-0">
                <div className="contactapp-wrap contactapp-sidebar-toggle" >
                    <div className="contactapp-detail-wrap">
                        <FianceReportHeader />
                        <FianceReportBody />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Rounds;
